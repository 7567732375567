import { Link } from 'found';
import PropTypes from 'prop-types';
import React from 'react';
import {
  Card, CardFooter, CardHeader,
} from 'reactstrap';
import styled from 'styled-components';

import ListCardMetadata from './ListCardMetadata';
import ListCardTitle from './ListCardTitle';

const Metadata = styled.div.attrs({
  className: 'float-end',
})``;

const PropertyDetails = styled.h3`
  font-size: 1rem;
  margin: 0;
`;

const MapContainer = styled.div.attrs({
  className: 'card-img-top',
})``;

const Footer = styled(CardFooter).attrs({
  className: 'text-center',
})``;

/**
 * Renders a card component for a list item, displaying metadata,
 * property/portfolio details, and a map. (currently used for property and portfolio listing)
 * @param {object} props - The component props.
 * @param {string} props.alias - The alias or name of the list item.
 * @param {boolean} props.consumer - Whether the list item is a consumer.
 * @param {string} props.label - The alias or name of the list item.
 * @param {React.ReactNode} props.map - The map component to be displayed.
 * @param {string} props.path - The path to the list item's details page.
 * @param {boolean} props.solar - Whether the list item has solar energy.
 * @param {string | object} props.subheading - The subheading or address of the list item.
 * @param {boolean} props.wind - Whether the list item has wind energy.
 * @returns {React.ReactComponentElement} - The rendered ListCard component.
 */
function ListCard(props) {
  const {
    alias,
    consumer,
    label,
    map,
    path,
    solar,
    subheading,
    wind,

  } = props;
  return (
    <Card>
      <CardHeader>
        <Metadata>
          <ListCardMetadata consumer={consumer} solar={solar} wind={wind} />
        </Metadata>
        <PropertyDetails>
          <ListCardTitle title={alias} subTitle={subheading} />
        </PropertyDetails>
      </CardHeader>
      <MapContainer>
        {map}
      </MapContainer>
      <Footer>
        <Link to={path} className="btn btn-primary">
          {label}
        </Link>
      </Footer>
    </Card>
  );
}

ListCard.propTypes = {
  alias: PropTypes.string.isRequired,
  consumer: PropTypes.bool,
  label: PropTypes.string.isRequired,
  map: PropTypes.element,
  path: PropTypes.string,
  solar: PropTypes.bool,
  subheading: PropTypes.oneOfType([
    PropTypes.object, // eslint-disable-line react/forbid-prop-types
    PropTypes.string,
  ]).isRequired,
  wind: PropTypes.bool,
};

ListCard.defaultProps = {
  consumer: false,
  map: null,
  path: null,
  solar: false,
  wind: false,

};

export default ListCard;
