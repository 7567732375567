/**
 * @generated SignedSource<<cd3b8eabde897e92f5d5fac43d805585>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CustomerPortfolioLayout_portfolio",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CustomerPortfolioNavigation_portfolio"
    }
  ],
  "type": "Portfolio",
  "abstractKey": null
};

node.hash = "adce4b32cee43b9155e2a67226f00132";

module.exports = node;
