import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';
import { graphql, useFragment } from 'react-relay';
import { Col, Row } from 'reactstrap';

import Loading from 'src/components/Loading';
import Breadcrumbs from 'src/enosikit/components/Breadcrumbs';
import username from 'src/util/decorators/username';

import UserMemberDetailsCard from './UserMemberDetailsCard';
import UserMemberPortfolioCard from './UserMemberPortfolioCard';
import UserMemberUserCard from './UserMemberUserCard';

const AdminPortfolioUserMemberShowFragment = graphql`
  fragment AdminPortfolioUserMemberShow_portfolioUserMember on PortfolioUserMember
  {
    uuid
    externalIdentifier
    active { start finish }
    portfolio {
      uuid
      externalIdentifier
      title
      active {
        start
        finish
      }
    }
    user {
      uuid
      externalIdentifier
      email
      givenName
      familyName
      active {
        start
        finish
      }
    }
    role
    createdAt
    updatedAt
  }
`;

/**
 * Description
 * @param {object} props
 * @returns {React.ReactElement} - AdminPortfolioUserMemberShow component
 */
function AdminPortfolioUserMemberShow(props) {
  if (!props) {
    return <Loading />;
  }

  const portfolioUserMember = useFragment(
    AdminPortfolioUserMemberShowFragment,
    props.portfolioUserMember, // eslint-disable-line react/destructuring-assignment
  );
  const { role } = portfolioUserMember || {};
  const { user, portfolio } = portfolioUserMember || {};

  const intl = useIntl();
  const userMemberUsername = username(user);
  const pageTitle = intl.formatMessage({ id: 'admin.admin_portfolio_user_member.admin_portfolio_user_member_show.page.title', defaultMessage: 'Enosi - Admin: Portfolio User Member: {userMemberUsername}' }, { userMemberUsername });
  const breadcrumbsAdmin = intl.formatMessage({ id: 'admin.admin_portfolio_user_member.admin_portfolio_user_member_show.breadcrumbs.admin', defaultMessage: 'Admin' });
  const breadcrumbsPortfolioUserMembers = intl.formatMessage({ id: 'admin.admin_portfolio_user_member.admin_portfolio_user_member_show.breadcrumbs.portfolio_user_members', defaultMessage: 'Portfolio User Members' });
  const breadcrumbsPortfolioUserMember = intl.formatMessage({ id: 'admin.admin_portfolio_user_member.admin_portfolio_user_member_show.breadcrumbs.portfolio_user_member', defaultMessage: 'Portfolio User Member: {userMemberUsername}' }, { userMemberUsername });

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{pageTitle}</title>
      </Helmet>

      <Breadcrumbs
        breadcrumbs={[
          { name: breadcrumbsAdmin, path: '/admin' },
          { name: breadcrumbsPortfolioUserMembers, path: '/admin/portfolio-user-members' },
          { name: breadcrumbsPortfolioUserMember },
        ]}
      />

      <h1>
        {userMemberUsername}
      </h1>

      <Row className="clear-both">
        <Col xs={12} sm={6} md={4}>
          <UserMemberDetailsCard portfolioUserMember={portfolioUserMember} />
        </Col>

        <Col xs={12} sm={6} md={8}>
          <UserMemberPortfolioCard portfolio={portfolio} />
          <UserMemberUserCard user={user} role={role} />
        </Col>
      </Row>
    </>
  );
}

AdminPortfolioUserMemberShow.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  portfolioUserMember: PropTypes.shape({
    id: PropTypes.string.isRequired,
    externalIdentifier: PropTypes.string.isRequired,
    active: PropTypes.shape({
      start: PropTypes.number.isRequired,
      finish: PropTypes.number,
    }),
    portfolio: PropTypes.shape({
      id: PropTypes.string.isRequired,
      externalIdentifier: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      active: PropTypes.shape({
        start: PropTypes.number.isRequired,
        finish: PropTypes.number,
      }),
    }),
    user: PropTypes.shape({
      id: PropTypes.string.isRequired,
      externalIdentifier: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      givenName: PropTypes.string.isRequired,
      familyName: PropTypes.string.isRequired,
      active: PropTypes.shape({
        start: PropTypes.number.isRequired,
        finish: PropTypes.number,
      }),
    }),
    role: PropTypes.string.isRequired,
    createdAt: PropTypes.number.isRequired,
    updatedAt: PropTypes.number.isRequired,
  }),
};
AdminPortfolioUserMemberShow.defaultProps = {
  portfolioUserMember: null,
};

export default AdminPortfolioUserMemberShow;
