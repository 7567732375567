import { Link } from 'found';
import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { FormattedMessage, injectIntl } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';
import { ButtonGroup, ButtonToolbar } from 'reactstrap';

import Address from 'src/components/Address';
import Loading from 'src/components/Loading';
import Breadcrumbs from 'src/enosikit/components/Breadcrumbs';
import Heading from 'src/enosikit/components/Heading';

import TradeRuleSetCommunityTimeOfUseForm from './TradeRuleSetCommunityTimeOfUseForm';

class TradeRuleSetCommunityTimeOfUse extends React.Component {
  renderPropertyControls = () => {
    const { property } = this.props;
    return (
      <ButtonToolbar>
        <ButtonGroup className="ms-2">
          <Link to={`/properties/${property.uuid}/trade-rules`} className="btn btn-darken">
            <FormattedMessage id="trade_rule.trade_rule_set_community_time_of_use.property_controls.all_trade_rules.label" defaultMessage="All trade rules" />
          </Link>
        </ButtonGroup>

        <ButtonGroup className="ms-2">
          <Link to={`/properties/${property.uuid}/settings`} className="btn btn-darken">
            <FormattedMessage id="trade_rule.trade_rule_set_community_time_of_use.property_controls.property_settings.label" defaultMessage="Property Settings" />
          </Link>
        </ButtonGroup>
      </ButtonToolbar>
    );
  };

  render() {
    if (this.error) {
      return <div><FormattedMessage id="error.title" defaultMessage="Error!" /></div>;
    }
    if (!this.props) {
      return <Loading />;
    }
    const {
      intl, property, meter, router,
    } = this.props;
    const { address, uuid, title } = property;
    const addr = new Address(address);
    const pageTitle = intl.formatMessage({
      id: 'trade_rule.trade_rule_set_community_time_of_use.page_title',
      defaultMessage: 'Enosi - Property: {title} - Set community trade rules for {meterTitle}',
    }, { title, meterTitle: meter.title });

    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{pageTitle}</title>
        </Helmet>

        <Breadcrumbs
          breadcrumbs={[
            {
              name: <FormattedMessage id="trade_rule.trade_rule_set_community_time_of_use.breadcrumbs.properties.label" defaultMessage=" Properties" />, path: '/properties',
            },
            { name: title, path: `/properties/${uuid}` },
            { name: <FormattedMessage id="trade_rule.trade_rule_set_community_time_of_use.breadcrumbs.trade_rules.label" defaultMessage="Trade Rules" />, path: `/properties/${uuid}/trade-rules/active` },
            {
              name: intl.formatMessage({
                id: 'trade_rule.trade_rule_set_community_time_of_use.breadcrumbs.set_community_trade_rules.label',
                defaultMessage: 'Set community trade rules for {meterTitle}',
              }, { meterTitle: meter.title }),
            },
          ]}
        />
        <Heading
          title={title}
          subtitle={addr.simpleString()}
          context={<FormattedMessage id="trade_rule.trade_rule_set_community_time_of_use.header.set_community_trade_rules.label" defaultMessage="Set community trade rules" />}
          controls={this.renderPropertyControls()}
        />
        <TradeRuleSetCommunityTimeOfUseForm property={property} meter={meter} router={router} />
      </>
    );
  }
}

TradeRuleSetCommunityTimeOfUse.propTypes = {
  property: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  meter: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  intl: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  relay: PropTypes.shape({
    refetch: PropTypes.func,
  }).isRequired,
  router: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types

};

TradeRuleSetCommunityTimeOfUse.defaultProps = {
  property: null,
  meter: null,
};

export default injectIntl(createFragmentContainer(
  TradeRuleSetCommunityTimeOfUse,
  {
    property: graphql`
      fragment TradeRuleSetCommunityTimeOfUse_property on Property {
        uuid
        title
        address {
          line1
          line2
          city
          state
          postcode
          gps {
            latitude
            longitude
          }
        }
        ...TradeRuleSetCommunityTimeOfUseForm_property
      }
    `,
    meter: graphql`
      fragment TradeRuleSetCommunityTimeOfUse_meter on Meter {
        uuid
        identifier
        title
        tradePointId
        ...TradeRuleSetCommunityTimeOfUseForm_meter
      }
    `,
  },
));
