import {
  faCaretDown,
  faCheck,
  faCity,
  faHome,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, withRouter } from 'found';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';
import {
  Collapse,
  Container,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem,
  Navbar,
  NavbarToggler,
} from 'reactstrap';
import styled from 'styled-components';

import AnonymousNavigation from 'src/components/Navigation/AnonymousNavigation';
import NavigationLogo from 'src/components/Navigation/NavigationLogo';
import UserSettingsNavigation from 'src/components/Navigation/UserSettingsNavigation';
import { APIConfig } from 'src/config';

const RightNav = styled(Nav).attrs({
  className: 'ms-auto',
})``;

const LeftNav = styled(Nav).attrs({
  className: 'me-auto',
})``;

const portfolioDropdown = (portfolioList, selected, dropdownOpen, togglePortfolio) => {
  const portfolios = portfolioList?.edges?.map((edge) => edge?.node) || [];

  return (
    <Dropdown
      nav
      inNavbar
      isOpen={dropdownOpen}
      toggle={togglePortfolio}
    >
      <Link
        to={`/portfolios/${selected.uuid}`}
        className="nav-link"
        style={{ display: 'inline-block', paddingRight: 0 }}
      >
        <FontAwesomeIcon icon={faCity} size="1x" className="me-2" />
        {selected.title}
      </Link>
      <DropdownToggle
        nav
        className="dropdown-toggle-split"
        style={{ display: 'inline-block', paddingLeft: 0 }}
      >
        <FontAwesomeIcon
          icon={faCaretDown}
          size="1x"
          className="ms-2"
        />
      </DropdownToggle>
      <DropdownMenu>
        {portfolios.map((portfolio) => (
          <Link
            to={`/portfolios/${portfolio.uuid}`}
            className={`dropdown-item ${portfolio.uuid === selected.uuid ? 'active' : ''}`}
            role="menuitem"
            key={portfolio.uuid}
          >
            {portfolio.title}
            {portfolio.uuid === selected.uuid ? (
              <>
                {' '}
                <FontAwesomeIcon icon={faCheck} size="1x" className="me-2" />
              </>
            ) : null}
          </Link>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};

/**
 *
 * @param {object} props
 * @returns {React.ReactComponentElement} - CustomerPortfolioNavigation component
 */
function CustomerPortfolioNavigation(props) {
  const [navBarOpen, setNavBarOpen] = useState(false);
  const [dropdownOpen, SetDropdownOpen] = useState(false);

  const toggleNavbar = () => {
    setNavBarOpen(!navBarOpen);
  };

  const togglePortfolio = () => {
    SetDropdownOpen(!dropdownOpen);
  };

  const { viewer, portfolios, portfolio } = props;

  const { viewerUser } = viewer;
  const { email, givenName, familyName } = viewerUser;
  let name = [givenName, familyName].filter(Boolean).join(' ');
  if (name === '') {
    name = email;
  }

  if (!viewer) {
    return <AnonymousNavigation />;
  }

  const primaryBrand = APIConfig().primaryBrand();

  return (
    <Navbar light expand="lg">
      <Container className="d-flex flex-wrap justify-content-between px-0 px-sm-3">
        <Link to="/" className="navbar-brand">
          <NavigationLogo primaryBrand={primaryBrand} />
        </Link>
        <NavbarToggler onClick={toggleNavbar} />
        <Collapse isOpen={navBarOpen} navbar>
          <LeftNav navbar>
            {portfolioDropdown(portfolios, portfolio, dropdownOpen, togglePortfolio)}
            <NavItem>
              <Link to="/properties" className="nav-link">
                <FontAwesomeIcon icon={faHome} size="1x" className="me-2" />
                <FormattedMessage id="navigation.customer_portfolio_navigation.properties.link.label" defaultMessage="Properties" />
              </Link>
            </NavItem>
          </LeftNav>
          <RightNav navbar>
            <UserSettingsNavigation user={viewerUser} />
          </RightNav>
        </Collapse>
      </Container>
    </Navbar>
  );
}

CustomerPortfolioNavigation.propTypes = {
  viewer: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  portfolio: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
  portfolios: PropTypes.shape({
    edges: PropTypes.arrayOf(
      PropTypes.shape({
        node: PropTypes.shape({
          uuid: PropTypes.string.isRequired,
          title: PropTypes.string.isRequired,
        }),
      }),
    ),
  }).isRequired,
};

CustomerPortfolioNavigation.defaultProps = {
  viewer: null,
};

export default withRouter(
  createFragmentContainer(
    CustomerPortfolioNavigation,
    {
      viewer: graphql`
        fragment CustomerPortfolioNavigation_viewer on Viewer {
          id
          viewerUser {
            email
            givenName
            familyName
            type
          }
        }
      `,
      portfolio: graphql`
        fragment CustomerPortfolioNavigation_portfolio on Portfolio {
          uuid
          title
        }
      `,
      portfolios: graphql`
        fragment CustomerPortfolioNavigation_portfolios on PortfolioConnection {
          edges {
            node {
              uuid
              title
            }
          }
        }
      `,
    },
  ),
);
