import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const Div = styled.div.attrs({
  className: 'd-flex flex-wrap header mt-4 mb-4',
})``;

const Separator = styled.span.attrs({
  className: 'ms-3 me-3',
})`
  font-weight: lighter;
`;

const Controls = styled.div.attrs({
  className: 'ms-auto',
})``;

/**
 * Description
 * @param {any} props
 * @returns {React.ReactComponentElement} - Heading component
 */
function Heading(props) {
  const {
    title,
    subtitle,
    context,
    separator,
    controls,
  } = props;

  return (
    <Div>
      <div className="mb-2">
        <h1>
          {title}
          {title && <Separator>{separator}</Separator>}
          {context}
        </h1>
        <div>
          {subtitle}
        </div>
      </div>
      {controls && (
        <Controls>
          {controls}
        </Controls>
      )}
    </Div>
  );
}

Heading.propTypes = {
  context: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
  ]).isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  controls: PropTypes.element,
  separator: PropTypes.string,
};

Heading.defaultProps = {
  title: null,
  subtitle: null,
  controls: null,
  separator: '/',
};

export default Heading;
