import Link from 'found/Link';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';
import { graphql, usePaginationFragment } from 'react-relay';
import { Table } from 'reactstrap';

import BadgeActive from 'src/components/Badge/BadgeActive';
import LoadMoreButton from 'src/components/LoadMoreButton';
import Loading from 'src/components/Loading';
import UUID from 'src/components/UUID';
import Breadcrumbs from 'src/enosikit/components/Breadcrumbs';
import getTimeRangeMsg from 'src/util/timeRange';

const AdminPortfolioListFragment = graphql`
fragment AdminPortfolioList_viewer on Viewer
@refetchable(queryName: "AdminPortfolioListRefetchQuery")
@argumentDefinitions(
  cursor: { type: "String" }
  count: { type: "Int" }
) {
  id
  portfolios(first: $count, after: $cursor)
    @connection(key: "AdminPortfolioList_portfolios") {
    edges {
      cursor
      node {
        uuid
        externalIdentifier
        title
        active {
          start
          finish
        }
      }
    }
    count
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
  }
}
`;

/**
 * Description
 * @param {any} props
 * @returns {React.ReactComponentElement} - AdminPortfolioList component
 */
function AdminPortfolioList(props) {
  const { viewer } = props || {};
  if (!viewer) {
    return <Loading />;
  }

  const {
    data, loadNext, hasNext, isLoadingNext,
  } = usePaginationFragment(
    AdminPortfolioListFragment,
    viewer,
  );
  const { portfolios } = data || {};
  const portfolioList = portfolios?.edges?.map((edge) => edge.node);
  const intl = useIntl();

  /**
   * Loads the next page of data for the portfolio list.
   * @returns {void}
   */
  const loadMore = () => {
    if (!hasNext || isLoadingNext) {
      return;
    }

    loadNext(
      50,
      { onComplete: (error) => { console.log({ error }); } },
    );
  };

  const pageTitle = intl.formatMessage({
    id: 'admin.admin_portfolio.admin_portfolio_list.page.portfolios.title', defaultMessage: 'Enosi Admin - Properties',
  });
  const breadcrumbsAdmin = intl.formatMessage({
    id: 'admin.admin_portfolio.admin_portfolio_list.page.breadcrumbs.admin.label', defaultMessage: 'Admin',
  });
  const portfoliosLabel = intl.formatMessage({
    id: 'admin.admin_portfolio.admin_portfolio_list.page.portfolios.label', defaultMessage: 'Portfolios',
  });
  const copyLoadMore = intl.formatMessage({ id: 'admin.admin_portfolio_list.load_more_button.state_load_more.label', defaultMessage: 'Load more...' });
  const copyNoMore = intl.formatMessage({ id: 'admin.admin_portfolio_list.load_more_button.state_no_more.label', defaultMessage: 'No more portfolios to load' });

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{pageTitle}</title>
      </Helmet>

      <Breadcrumbs
        breadcrumbs={[
          { name: breadcrumbsAdmin, path: '/admin' },
          { name: portfoliosLabel },
        ]}
      />
      <div className="clearfix">
        <h1 className="float-start">{portfoliosLabel}</h1>
      </div>
      <div className="mt-4 mb-4 card">
        <div className="table-responsive">
          <Table className="table">
            <thead>
              <tr>
                <th scope="col">
                  {intl.formatMessage({
                    id: 'admin.admin_portfolio.admin_portfolio_list.table.head.status.label', defaultMessage: 'Status',
                  })}

                </th>
                <th scope="col">
                  {intl.formatMessage({
                    id: 'admin.admin_portfolio.admin_portfolio_list.table.head.title.label', defaultMessage: 'Title',
                  })}
                </th>
                <th scope="col">
                  {intl.formatMessage({
                    id: 'admin.admin_portfolio.admin_portfolio_list.table.head.id.label', defaultMessage: 'ID',
                  })}
                </th>
                <th scope="col">
                  <abbr title="External identifier">
                    {intl.formatMessage({
                      id: 'admin.admin_portfolio.admin_portfolio_list.table.ext_id.label', defaultMessage: 'Ext ID',
                    })}
                  </abbr>
                </th>
              </tr>
            </thead>
            <tbody>
              {
                portfolioList.map((portfolio) => {
                  const {
                    active, externalIdentifier, uuid, title,
                  } = portfolio || {};
                  const { start, finish } = active;

                  return (
                    <tr key={`admin-property-${uuid}`}>
                      <td>
                        <BadgeActive start={start} finish={finish} />
                        <br />
                        {getTimeRangeMsg(active, intl)}
                      </td>
                      <th scope="row">
                        <Link to={`/admin/portfolios/${uuid}`}>
                          {title}
                        </Link>
                      </th>
                      <td>
                        <UUID uuid={uuid} />
                      </td>
                      <td>
                        {externalIdentifier}
                      </td>
                    </tr>
                  );
                })
              }
            </tbody>
          </Table>
        </div>
        <div className="card-footer">
          <LoadMoreButton
            copyLoadMore={copyLoadMore}
            copyNoMore={copyNoMore}
            onClick={loadMore}
            hasNext={hasNext}
          />
        </div>
      </div>
    </>
  );
}

export default AdminPortfolioList;
