/**
 * @generated SignedSource<<b466227cedb20880adb86fbf874c7c52>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CustomerNavigation_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "viewerUser",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "email",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "givenName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "familyName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": "proposals",
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 10
        },
        {
          "kind": "Literal",
          "name": "state",
          "value": "TRADE_RULE_STATE_PROPOSED"
        },
        {
          "kind": "Literal",
          "name": "type",
          "value": "TRADE_TYPE_NOMINATED"
        }
      ],
      "concreteType": "TradeRuleConnection",
      "kind": "LinkedField",
      "name": "rules",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "TradeRuleEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "TradeRule",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "uuid",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "rules(first:10,state:\"TRADE_RULE_STATE_PROPOSED\",type:\"TRADE_TYPE_NOMINATED\")"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

node.hash = "f8e34cf7c3d7426437bc10c0d5467778";

module.exports = node;
