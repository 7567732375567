import { Link } from 'found';
import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { FormattedMessage, injectIntl } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';
import { ButtonGroup, ButtonToolbar } from 'reactstrap';

import Address from 'src/components/Address';
import Loading from 'src/components/Loading';
import Breadcrumbs from 'src/enosikit/components/Breadcrumbs';
import Heading from 'src/enosikit/components/Heading';

import TradeRulePrioritizeNominatedList from './TradeRulePrioritizeNominatedList';

class TradeRulePrioritizeNominated extends React.Component {
  renderPropertyControls = () => {
    const { property } = this.props;
    return (
      <ButtonToolbar>
        <ButtonGroup className="ms-2">
          <Link to={`/properties/${property.uuid}/trade-rules`} className="btn btn-darken">
            <FormattedMessage id="trade_rule.trade_rule_prioritize_nominated.all_trade_rules.label" defaultMessage="All trade rules" />
          </Link>
        </ButtonGroup>

        <ButtonGroup className="ms-2">
          <Link to={`/properties/${property.uuid}/settings`} className="btn btn-darken">
            <FormattedMessage id="trade_rule.trade_rule_prioritize_nominated.property_settings.label" defaultMessage="Property Settings" />
          </Link>
        </ButtonGroup>
      </ButtonToolbar>
    );
  };

  render() {
    if (this.error) {
      return <div><FormattedMessage id="error.title" defaultMessage="Error!" /></div>;
    }
    if (!this.props) {
      return <Loading />;
    }

    const {
      intl, property, meter, router,
    } = this.props;
    const { address, uuid, title: propertyTitle } = property;

    const addr = new Address(address);
    const meterTitle = meter.title;

    const pageTitle = intl.formatMessage({ id: 'trade_rule.trade_rule_prioritize_nominated.page.title', defaultMessage: 'Enosi - Property {propertyTitle} - Prioritise Trades for {meterTitle}' }, { propertyTitle, meterTitle });

    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{pageTitle}</title>
        </Helmet>

        <Breadcrumbs
          breadcrumbs={[
            {
              name: <FormattedMessage id="trade_rule.trade_rule_prioritize_nominated.breadcrumbs.properties.label" defaultMessage=" Properties" />, path: '/properties',
            },
            { name: propertyTitle, path: `/properties/${uuid}` },
            { name: <FormattedMessage id="trade_rule.trade_rule_prioritize_nominated.breadcrumbs.trade_rules.label" defaultMessage="Trade Rules" />, path: `/properties/${uuid}/trade-rules/active` },
            {
              name: <FormattedMessage
                id="trade_rule.trade_rule_prioritize_nominated.breadcrumbs.prioritize_trade_for_meter_title.label"
                defaultMessage="Prioritise Trades for {meterTitle}"
                values={{
                  meterTitle,
                }}
              />,
            },
          ]}
        />
        <Heading
          title={propertyTitle}
          subtitle={addr.simpleString()}
          context={<FormattedMessage id="trade_rule_priority.manage.label" defaultMessage="Trade Rules - Manage Priority" />}
          controls={this.renderPropertyControls()}
        />
        <TradeRulePrioritizeNominatedList property={property} meter={meter} router={router} />
      </>
    );
  }
}

TradeRulePrioritizeNominated.propTypes = {
  property: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  meter: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  intl: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  relay: PropTypes.shape({
    refetch: PropTypes.func,
  }).isRequired,
  router: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types

};

TradeRulePrioritizeNominated.defaultProps = {
  property: null,
  meter: null,
};

export default injectIntl(createFragmentContainer(
  TradeRulePrioritizeNominated,
  {
    property: graphql`
      fragment TradeRulePrioritizeNominated_property on Property {
        uuid
        title
        address {
          line1
          line2
          city
          state
          postcode
          gps {
            latitude
            longitude
          }
        }
        ...TradeRulePrioritizeNominatedList_property
      }
    `,
    meter: graphql`
      fragment TradeRulePrioritizeNominated_meter on Meter {
        uuid
        identifier
        title
        tradePointId
        ...TradeRulePrioritizeNominatedList_meter
      }
    `,
  },
));
