/**
 * Removes duplicate elements from the provided array.
 * @param {Array} arr - The array to remove duplicates from.
 * @returns {Array} - A new array with duplicate elements removed.
 */
const removeDuplicates = (arr) => {
  if (!arr) {
    return [];
  }

  return (arr.filter((item, index) => arr.indexOf(item) === index));
};

export default removeDuplicates;
