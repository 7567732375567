import { getStringAndNumericFormattedDate } from 'src/util/i18n/helpers';

/**
 * Takes in the start and finish timestamp (active object and
 * returns the text that needs to be displayed)
 * @param {import('react-intl').IntlShape} intl - i18n
 * @param {object} active - start and finish timestamp
 * @returns {string} - i18n string
 */
const activePeriodText = (intl, active) => {
  const { start, finish } = active || {};
  const startOnly = start && !finish;
  const finishOnly = !start && finish;
  const startAndFinish = start && finish;

  if (startOnly) {
    const startDate = getStringAndNumericFormattedDate(intl, start);
    return intl.formatMessage({ id: 'util.i18n.active_start_only.text', defaultMessage: 'Active from {startDate}' }, { startDate });
  }
  if (finishOnly) {
    const finishDate = getStringAndNumericFormattedDate(intl, finish);
    return intl.formatMessage({ id: 'util.i18n.active_finish_only.text', defaultMessage: 'Active until {finishDate}' }, { finishDate });
  }
  if (startAndFinish) {
    const startDate = getStringAndNumericFormattedDate(intl, start);
    const finishDate = getStringAndNumericFormattedDate(intl, finish);

    return intl.formatMessage({ id: 'util.i18n.active_start_finish.text', defaultMessage: 'Active from {startDate} until {finishDate}' }, { startDate, finishDate });
  }
  return intl.formatMessage({ id: 'util.i18n.active_always.text', defaultMessage: 'Active always' });
};

export default activePeriodText;
