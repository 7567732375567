import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const Div = styled.div.attrs({
  className: 'd-flex align-items-center',
})``;

const TextContainer = styled.div.attrs({
  className: 'ms-2',
})``;

const Placeholder = styled.div`
  background-color: ${(props) => props.color};
`;

const ImagePlaceholder = styled(Placeholder).attrs({
  className: 'rounded-circle',
})`
  width: ${(props) => props.size};
  height: ${(props) => props.size};
`;

const TextPlaceholder = styled(Placeholder).attrs({
  className: 'rounded',
})`
  width: 200px;
`;

const PrimaryTextPlaceholder = styled(TextPlaceholder)`
  height: 1.2rem;
`;

const SecondaryTextPlaceholder = styled(TextPlaceholder).attrs({
  className: 'mt-1',
})`
  height: 1rem;
`;

/**
 * Description
 * @param {any} props
 * @returns {React.ReactComponentElement} - AvatarSkeleton component
 */
function AvatarSkeleton(props) {
  const { size, color } = props;

  return (
    <Div>
      <div>
        <ImagePlaceholder size={size} color={color} />
      </div>
      <TextContainer>
        <PrimaryTextPlaceholder color={color} />
        <SecondaryTextPlaceholder color={color} />
      </TextContainer>
    </Div>
  );
}

AvatarSkeleton.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string,
};

AvatarSkeleton.defaultProps = {
  size: '50px',
  color: '#d3d3d3',
};

export default AvatarSkeleton;
