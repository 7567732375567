import {
  DAY_MONDAY,
  DAY_TUESDAY,
  DAY_WEDNESDAY,
  DAY_THURSDAY, // eslint-disable-line sort-imports
  DAY_FRIDAY,
  DAY_SATURDAY,
  DAY_SUNDAY,
  DAY_PUBLIC_HOLIDAY,
  METER,
  Months,
  TRADE,
} from './constants';

/**
 * @param {import('react-intl').IntlShape} intl
 * @returns {Array<string>} The base CSV file headers for property meter data.
 */
const propertyMeterDataCSVFileHeaders = (intl) => {
  const propertyTitleHeader = intl.formatMessage({ id: 'util.property_meter_data_CSV_file_headers.property_title.header', defaultMessage: 'Property title' });

  const primaryBillingPointIdentifierHeader = intl.formatMessage({ id: 'util.property_meter_data_CSV_file_headers.primary_billing_point_identifier.header', defaultMessage: 'Primary billing point identifier' });

  const meterTitleHeader = intl.formatMessage({ id: 'util.property_meter_data_CSV_file_headers.meter_title.header', defaultMessage: 'Meter title' });

  const meterIdentifier = intl.formatMessage({ id: 'util.property_meter_data_CSV_file_headers.meter_identifier.header', defaultMessage: 'Meter identifier' });

  const metricHeader = intl.formatMessage({ id: 'util.property_meter_data_CSV_file_headers.metric.header', defaultMessage: 'Metric' });

  const startHeader = intl.formatMessage({ id: 'util.property_meter_data_CSV_file_headers.start.header', defaultMessage: 'Start' });

  const finishHeader = intl.formatMessage({ id: 'util.property_meter_data_CSV_file_headers.finish.header', defaultMessage: 'Finish' });

  const valueHeader = intl.formatMessage({ id: 'util.property_meter_data_CSV_file_headers.value.header', defaultMessage: 'Value' });

  const unitsHeader = intl.formatMessage({ id: 'util.property_meter_data_CSV_file_headers.units.header', defaultMessage: 'Units' });

  const flagsHeader = intl.formatMessage({ id: 'util.property_meter_data_CSV_file_headers.flags.header', defaultMessage: 'Flags' });

  const carbonEmissionalityHeader = intl.formatMessage({ id: 'util.property_meter_data_CSV_file_headers.carbon_emissionality.header', defaultMessage: 'Carbon emissionality' });

  const carbonEmissionsHeader = intl.formatMessage({ id: 'util.property_meter_data_CSV_file_headers.carbon_emissions.header', defaultMessage: 'Carbon emissions' });

  const carbonEmissionUnitsHeader = intl.formatMessage({ id: 'util.property_meter_data_CSV_file_headers.carbon_emission_unit.header', defaultMessage: 'Carbon emissions units' });

  return [
    propertyTitleHeader, primaryBillingPointIdentifierHeader, meterTitleHeader,
    meterIdentifier, metricHeader, startHeader, finishHeader, valueHeader,
    unitsHeader, flagsHeader, carbonEmissionalityHeader, carbonEmissionsHeader,
    carbonEmissionUnitsHeader,
  ];
};

/**
 * @param {import('react-intl').IntlShape} intl
 * @returns {Array<string>} The additional carbon data CSV file headers for property meter data.
 */

const propertyMeterDataCSVFileHeadersCarbonData = (intl) => {
  const carbonEmissionalityHeader = intl.formatMessage({ id: 'util.property_meter_data_CSV_file_headers_carbon_data.carbon_emissionality.header', defaultMessage: 'Carbon emissionality' });

  const carbonEmissionsHeader = intl.formatMessage({ id: 'util.property_meter_data_CSV_file_headers_carbon_data.carbon_emissions.header', defaultMessage: 'Carbon emissions' });

  const carbonEmissionUnitsHeader = intl.formatMessage({ id: 'util.property_meter_data_CSV_file_headers_carbon_data.carbon_emission_unit.header', defaultMessage: 'Carbon emissions units' });

  return [
    carbonEmissionalityHeader, carbonEmissionsHeader, carbonEmissionUnitsHeader,
  ];
};

/**
 * @param {import('react-intl').IntlShape} intl
 * @returns {Array<string>} The CSV file headers for property meter data without carbon data.
 */
const propertyMeterDataCSVFileHeadersNoCarbonData = (intl) => propertyMeterDataCSVFileHeaders(intl)
  .filter((el) => (propertyMeterDataCSVFileHeadersCarbonData(intl).indexOf(el) === -1));

/**
 * @param {import('react-intl').IntlShape} intl
 * @returns {Array<string>} The CSV file headers for property trade data.
 */
const propertyTradeDataCSVFileHeaders = (intl) => {
  const tradeRuleIdHeader = intl.formatMessage({ id: 'util.property_trade_data_CSV_file_headers.trade_rule_id.header', defaultMessage: 'Trade rule ID' });

  const tradeTypeHeader = intl.formatMessage({ id: 'util.property_trade_data_CSV_file_headers.trade_type.header', defaultMessage: 'Trade type' });

  const buyerTraderHeader = intl.formatMessage({ id: 'util.property_trade_data_CSV_file_headers.buyer_trader.header', defaultMessage: 'Buyer trader' });

  const buyerPropertyTitleHeader = intl.formatMessage({ id: 'util.property_trade_data_CSV_file_headers.buyer_property_title.header', defaultMessage: 'Buyer property title' });

  const buyerMeterTitleHeader = intl.formatMessage({ id: 'util.property_trade_data_CSV_file_headers.buyer_meter_title.header', defaultMessage: 'Buyer meter title' });

  const buyerMeterIdentifierHeader = intl.formatMessage({ id: 'util.property_trade_data_CSV_file_headers.buyer_meter_identifier.header', defaultMessage: 'Buyer meter identifier' });

  const sellerTradeHeader = intl.formatMessage({ id: 'util.property_trade_data_CSV_file_headers.seller_trader.header', defaultMessage: 'Seller trader' });

  const sellerPropertyTitle = intl.formatMessage({ id: 'util.property_trade_data_CSV_file_headers.seller_property_title.header', defaultMessage: 'Seller property title' });

  const sellerMeterTitle = intl.formatMessage({ id: 'util.property_trade_data_CSV_file_headers.seller_meter_title.header', defaultMessage: 'Seller meter title' });

  const sellerMeterIdentifier = intl.formatMessage({ id: 'util.property_trade_data_CSV_file_headers.seller_meter_identifier.header', defaultMessage: 'Seller meter identifier' });

  const startHeader = intl.formatMessage({ id: 'util.property_trade_data_CSV_file_headers.start.header', defaultMessage: 'Start' });

  const finishHeader = intl.formatMessage({ id: 'util.property_trade_data_CSV_file_headers.finish.header', defaultMessage: 'Finish' });

  const priceHeader = intl.formatMessage({ id: 'util.property_trade_data_CSV_file_headers.price.header', defaultMessage: 'Price' });

  const volumeHeader = intl.formatMessage({ id: 'util.property_trade_data_CSV_file_headers.volume.header', defaultMessage: 'Volume' });

  const valueHeader = intl.formatMessage({ id: 'util.property_trade_data_CSV_file_headers.value.header', defaultMessage: 'Value' });

  const carbonEmissionalityHeader = intl.formatMessage({ id: 'util.property_trade_data_CSV_file_headers.carbon_emissionality.header', defaultMessage: 'Carbon emissionality' });

  const carbonEmissionsHeader = intl.formatMessage({ id: 'util.property_trade_data_CSV_file_headers.carbon_emissions.header', defaultMessage: 'Carbon emissions' });

  const carbonEmissionUnitsHeader = intl.formatMessage({ id: 'util.property_trade_data_CSV_file_headers.carbon_emission_unit.header', defaultMessage: 'Carbon emissions units' });

  return [tradeRuleIdHeader,
    tradeTypeHeader, buyerTraderHeader, buyerPropertyTitleHeader, buyerMeterTitleHeader,
    buyerMeterIdentifierHeader, sellerTradeHeader, sellerPropertyTitle, sellerMeterTitle,
    sellerMeterIdentifier, startHeader, finishHeader, priceHeader,
    volumeHeader, valueHeader, carbonEmissionalityHeader,
    carbonEmissionsHeader, carbonEmissionUnitsHeader,
  ];
};

/**
 * @param {import('react-intl').IntlShape} intl
 * @returns {Array<string>} The carbon data CSV file headers for property trade data.
 */
const propertyTradeDataCSVFileHeadersCarbonData = (intl) => {
  const carbonEmissionalityHeader = intl.formatMessage({ id: 'util.property_trade_data_CSV_file_headers_carbon_data.carbon_emissionality.header', defaultMessage: 'Carbon emissionality' });

  const carbonEmissionsHeader = intl.formatMessage({ id: 'util.property_trade_data_CSV_file_headers_carbon_data.carbon_emissions.header', defaultMessage: 'Carbon emissions' });

  const carbonEmissionUnitsHeader = intl.formatMessage({ id: 'util.property_trade_data_CSV_file_headers_carbon_data.carbon_emission_unit.header', defaultMessage: 'Carbon emissions units' });

  return [
    carbonEmissionalityHeader, carbonEmissionsHeader, carbonEmissionUnitsHeader,
  ];
};

/**
 * @param {import('react-intl').IntlShape} intl
 * @returns {Array<string>} The CSV file headers for property trade data without carbon data.
 */
const propertyTradeDataCSVFileHeadersNoCarbonData = (intl) => propertyTradeDataCSVFileHeaders(intl)
  .filter((el) => (propertyTradeDataCSVFileHeadersCarbonData(intl).indexOf(el) === -1));

/**
 * Returns the list of headers for the CSV files
 * @param {import('react-intl').IntlShape} intl - i18n react-intl
 * @param {string} type - meter, trade or data pack
 * @param {boolean} withoutCarbonData - whether we want carbon data or not
 * @returns {string[]} - list of headers
 */
const csvFileHeaders = (intl, type, withoutCarbonData) => {
  switch (type) {
    case METER:
      return withoutCarbonData
        ? propertyMeterDataCSVFileHeadersNoCarbonData(intl) : propertyMeterDataCSVFileHeaders(intl);
    case TRADE:
      return withoutCarbonData
        ? propertyTradeDataCSVFileHeadersNoCarbonData(intl) : propertyTradeDataCSVFileHeaders(intl);
    default:
      return null;
  }
};

export default csvFileHeaders;

/**
 * Checks whether a trade rule is flat.
 * To extend: validate a structured trade rule is flat by way of full coverage and single price
 * (some partners do weird things with their tariffs).
 * Note: This currently assumes there is a single clause.
 * Further refactor required to support fragmented trade rule (multiple clauses still flat price).
 * @param {object} rule
 * @returns {boolean} flag true or false if the trade rule is flat.
 */
export const isTradeRuleFlat = (rule) => {
  const { clauses: clausesEdges } = rule;
  const clauses = clausesEdges.edges.map((el) => el.node);

  const isSingle = clauses.length === 1;
  const isAllTime = clauses.map((el) => {
    const isAllMonths = Months.every((v) => el.monthsOfYear.includes(v));
    const isAllDaysOfWeek = [
      DAY_MONDAY, DAY_TUESDAY, DAY_WEDNESDAY, DAY_THURSDAY, DAY_FRIDAY, DAY_SATURDAY, DAY_SUNDAY,
    ].every((v) => (el.daysOfWeek.includes(v)));
    const isPublicHolidayIfNeeded = el.ignorePublicHolidays
      || el.daysOfWeek.includes(DAY_PUBLIC_HOLIDAY);
    const isAllTimesOfDay = el.timesOfDay.length === 1 && (
      !el.timesOfDay[0]?.start?.hours && el.timesOfDay[0]?.finish?.hours === 24
    );

    return isAllMonths && isAllDaysOfWeek && isPublicHolidayIfNeeded && isAllTimesOfDay;
  }).reduce((res, cur) => res && cur, true);

  return isSingle && isAllTime;
};
