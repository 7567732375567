import {
  faCaretDown, faCog, faSignOutAlt, faUserCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'found';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Dropdown, DropdownItem, DropdownMenu, DropdownToggle,
} from 'reactstrap';

import { AvatarItem } from 'src/enosikit/components/Avatar';

/**
 * Returns usersettings navigation component
 * @param {object} root
 * @param {object} root.user
 * @returns {React.ReactComponentElement} - UserSettingsNavigation component.
 */
function UserSettingsNavigation({ user }) {
  const [dropDownOpen, setDropdownOpen] = useState(false);

  const { email, givenName, familyName } = user;
  const name = [givenName, familyName].filter(Boolean).join(' ') || email;

  const toggleDropdown = () => {
    setDropdownOpen(!dropDownOpen);
  };

  return (
    <Dropdown
      nav
      inNavbar
      isOpen={dropDownOpen}
      toggle={toggleDropdown}
    >
      <DropdownToggle nav>
        <AvatarItem
          primaryText={name}
          secondaryText={email}
          avatarImage={
            <FontAwesomeIcon icon={faUserCircle} size="3x" />
          }
          className="d-inline-flex"
        />
        <FontAwesomeIcon
          icon={faCaretDown}
          size="1x"
          className="ms-2"
        />
      </DropdownToggle>
      <DropdownMenu end>
        <Link
          to="/profile/settings"
          activeClassName="active"
          className="dropdown-item"
          role="menuitem"
        >
          <FontAwesomeIcon icon={faCog} className="me-2" />
          <FormattedMessage id="navigation.user_settings_navigation.current_user_settings.link.label" defaultMessage="Profile settings" />
        </Link>
        <DropdownItem divider />
        <Link
          to="/logout"
          activeClassName="active"
          className="dropdown-item"
          role="menuitem"
        >
          <FontAwesomeIcon icon={faSignOutAlt} className="me-2" />
          <FormattedMessage id="navigation.user_settings_navigation.log_out.link.label" defaultMessage="Log out" />
        </Link>
      </DropdownMenu>
    </Dropdown>
  );
}
export default UserSettingsNavigation;

UserSettingsNavigation.propTypes = {
  user: PropTypes.shape({
    email: PropTypes.string.isRequired,
    givenName: PropTypes.string.isRequired,
    familyName: PropTypes.string.isRequired,
  }).isRequired,
};
