import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { FilterBar } from 'src/components/Filter';
import {
  ACTIVE_STATE, ACTIVE_STATE_ACTIVE, ACTIVE_STATE_INACTIVE,
  ALL_PORTFOLIO_USERS, LIST_FILTER_ALL, PORTFOLIO_USER_ROLE_ADMIN,
  PORTFOLIO_USER_ROLE_OWNER, PORTFOLIO_USER_ROLE_STANDARD,
  PORTFOLIO_USER_ROLE_VIEWER,
} from 'src/util/constants';

/**
 * @param {object} props
 * @param {ACTIVE_STATE_ACTIVE | ACTIVE_STATE_INACTIVE | LIST_FILTER_ALL} props.activeState
 * Whether the element is active or inactive.
 * @param {Function} props.setFilterFunc - Function that sets the active state of the
 * element.
 * @param {ALL_PORTFOLIO_USERS | PORTFOLIO_USER_ROLE_OWNER |
 * PORTFOLIO_USER_ROLE_ADMIN | PORTFOLIO_USER_ROLE_STANDARD |
 * PORTFOLIO_USER_ROLE_VIEWER} props.roleType - Selected user role or the default
 * All portfolio users.
 * @returns {React.ReactComponentElement} PortfolioActiveStatusFilter component.
 */
function PortfolioActiveStatusFilter(props) {
  const { activeState, setFilterFunc, roleType } = props;

  /**
   * Generates an array of filter bar buttons for a list. All | Active | Inactive
   * @returns {Array<object>} An array of filter bar button objects.
   */
  const filterBarButtons = () => [
    {
      group: LIST_FILTER_ALL,
      items: [
        { value: LIST_FILTER_ALL, label: <FormattedMessage id="portfolio.portfolio_show.filter.all.label" defaultMessage="All" />, active: activeState === LIST_FILTER_ALL },
      ],
    },
    {
      group: ACTIVE_STATE,
      items: [
        { value: ACTIVE_STATE_ACTIVE, label: <FormattedMessage id="portfolio.portfolio_show.filter.active_status.active.label" defaultMessage="Active" />, active: activeState === ACTIVE_STATE_ACTIVE },
        { value: ACTIVE_STATE_INACTIVE, label: <FormattedMessage id="portfolio.portfolio_show.filter.active_status.inactive.label" defaultMessage="Inactive" />, active: activeState === ACTIVE_STATE_INACTIVE },
      ],
    },
  ];

  /**
   * Updates the filter state based on the provided group and value.
   * @param {object} param0 - An object containing the group and value to update the filter with.
   * @param {string} param0.group - The group to update the filter for.
   * @param {string} param0.value - The value to update the filter to.
   * @returns {void}
   */
  const updateFilter = ({ group, value }) => {
    if (value === LIST_FILTER_ALL) {
      setFilterFunc({ roleType: ALL_PORTFOLIO_USERS, activeState: LIST_FILTER_ALL });
    }
    if (group === 'active') {
      setFilterFunc({ activeState: value, roleType });
    }
  };

  return (
    <FilterBar buttons={filterBarButtons()} onButtonClick={(e) => updateFilter(e)} />
  );
}

PortfolioActiveStatusFilter.propTypes = {
  activeState: PropTypes.string.isRequired,
  setFilterFunc: PropTypes.func.isRequired,
  roleType: PropTypes.string,
};

PortfolioActiveStatusFilter.defaultProps = {
  roleType: null,
};

export default PortfolioActiveStatusFilter;
