/**
 * @generated SignedSource<<3e964840d2425c661a4df50f5c2adf55>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uuid",
  "storageKey": null
},
v2 = [
  (v1/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "type",
    "storageKey": null
  },
  (v0/*: any*/)
],
v3 = {
  "kind": "Literal",
  "name": "first",
  "value": 500
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "TimeRange",
  "kind": "LinkedField",
  "name": "active",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "start",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "finish",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = [
  (v1/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "email",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "givenName",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "familyName",
    "storageKey": null
  },
  (v0/*: any*/)
],
v7 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "user",
    "plural": false,
    "selections": (v6/*: any*/),
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "TradePoint",
    "kind": "LinkedField",
    "name": "tradePoint",
    "plural": false,
    "selections": (v2/*: any*/),
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "routes_TradeRuleCustomerProposals_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "TradeRuleCustomerProposals_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "routes_TradeRuleCustomerProposals_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "viewerUser",
            "plural": false,
            "selections": (v2/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": [
              (v3/*: any*/)
            ],
            "concreteType": "MeterConnection",
            "kind": "LinkedField",
            "name": "meters",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "MeterEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Meter",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "identifier",
                        "storageKey": null
                      },
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "tradePointId",
                        "storageKey": null
                      },
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Property",
                        "kind": "LinkedField",
                        "name": "property",
                        "plural": false,
                        "selections": [
                          (v1/*: any*/),
                          (v4/*: any*/),
                          (v5/*: any*/),
                          (v0/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v0/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "meters(first:500)"
          },
          {
            "alias": "proposals",
            "args": [
              (v3/*: any*/),
              {
                "kind": "Literal",
                "name": "state",
                "value": "TRADE_RULE_STATE_PROPOSED"
              },
              {
                "kind": "Literal",
                "name": "type",
                "value": "TRADE_TYPE_NOMINATED"
              }
            ],
            "concreteType": "TradeRuleConnection",
            "kind": "LinkedField",
            "name": "rules",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "TradeRuleEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TradeRule",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ClauseConnection",
                        "kind": "LinkedField",
                        "name": "clauses",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ClauseEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Clause",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "price",
                                    "storageKey": null
                                  },
                                  (v0/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "tradeType",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "state",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Trader",
                        "kind": "LinkedField",
                        "name": "buyer",
                        "plural": false,
                        "selections": (v7/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Trader",
                        "kind": "LinkedField",
                        "name": "seller",
                        "plural": false,
                        "selections": (v7/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "proposedAt",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "User",
                        "kind": "LinkedField",
                        "name": "proposedBy",
                        "plural": false,
                        "selections": (v6/*: any*/),
                        "storageKey": null
                      },
                      (v0/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "rules(first:500,state:\"TRADE_RULE_STATE_PROPOSED\",type:\"TRADE_TYPE_NOMINATED\")"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6f5257a0221927fa8d89e167dd64d4ab",
    "id": null,
    "metadata": {},
    "name": "routes_TradeRuleCustomerProposals_Query",
    "operationKind": "query",
    "text": "query routes_TradeRuleCustomerProposals_Query {\n  viewer {\n    ...TradeRuleCustomerProposals_viewer\n    id\n  }\n}\n\nfragment TradeRuleCustomerProposals_viewer on Viewer {\n  id\n  viewerUser {\n    uuid\n    type\n    id\n  }\n  meters(first: 500) {\n    edges {\n      node {\n        uuid\n        identifier\n        title\n        tradePointId\n        active {\n          start\n          finish\n        }\n        property {\n          uuid\n          title\n          active {\n            start\n            finish\n          }\n          id\n        }\n        id\n      }\n    }\n  }\n  proposals: rules(first: 500, type: TRADE_TYPE_NOMINATED, state: TRADE_RULE_STATE_PROPOSED) {\n    edges {\n      node {\n        uuid\n        clauses {\n          edges {\n            node {\n              price\n              id\n            }\n          }\n        }\n        tradeType\n        state\n        buyer {\n          user {\n            uuid\n            email\n            givenName\n            familyName\n            id\n          }\n          tradePoint {\n            uuid\n            type\n            id\n          }\n        }\n        seller {\n          user {\n            uuid\n            email\n            givenName\n            familyName\n            id\n          }\n          tradePoint {\n            uuid\n            type\n            id\n          }\n        }\n        proposedAt\n        proposedBy {\n          uuid\n          email\n          givenName\n          familyName\n          id\n        }\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

node.hash = "114ef0f49b2a3cfd640556f3ce99ec45";

module.exports = node;
