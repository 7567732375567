import Link from 'found/Link';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { graphql, usePaginationFragment } from 'react-relay';
import {
  Card, CardFooter, CardHeader, Table,
} from 'reactstrap';

import BadgeActive from 'src/components/Badge/BadgeActive';
import BadgePortfolioUserRole from 'src/components/Badge/BadgePortfolioUserRole';
import LoadMoreButton from 'src/components/LoadMoreButton';
import UUID from 'src/components/UUID';
import username from 'src/util/decorators/username';
import getTimeRangeMsg from 'src/util/timeRange';

const AdminPortfolioUserMembersFragment = graphql`
fragment AdminPortfolioUserMembers_portfolio on Portfolio
@refetchable(queryName: "PortfolioUserMembersPaginationQuery")
@argumentDefinitions(
  cursor: { type: "String" }
  count: { type: "Int", defaultValue: 10}
){
  uuid
  userMembers(first: $count, after: $cursor)
  @connection(key: "PortfolioUserMembers_userMembers") {
    edges {
      cursor
      node {
        uuid
        externalIdentifier
        active {
          start
          finish
        }
        user {
          uuid
          externalIdentifier
          givenName
          familyName
          email
        }
        role
      }
    }
    count
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
  }
}
`;

/**
 * Renders an alert component to inform the user that there is
 * no inactive property in the portfolio.
 * @returns {React.ReactElement} The alert component.
 */
export function noUsersMsg() {
  return (
    <FormattedMessage
      id="admin.admin_portfolio.admin_portfolio_show.portfolio_user_members.no_property_member.message"
      defaultMessage="<strong>No user memberships found in this portfolio.</strong> Unfortunately we do not currently have any user memberships for this portfolio. If you believe that this is an error please make use of our support site to raise an issue."
      values={{
        strong: (chunks) => <strong>{chunks}</strong>,
      }}
    />

  );
}

/**
 * @param {object} props
 * @param {object} props.portfolio
 * @returns {React.ReactElement} - AdminPortfolioUserMembers component
 */
function AdminPortfolioUserMembers(props) {
  if (!props) {
    return null;
  }
  const { portfolio } = props || {};
  const {
    data, loadNext, hasNext, isLoadingNext,
  } = usePaginationFragment(AdminPortfolioUserMembersFragment, portfolio);
  const userMembers = data.userMembers?.edges.map((edge) => edge.node);

  const intl = useIntl();

  /**
   * Loads the next set of users when the user hits the load more button.
   * @returns {void}
   */
  const loadMore = () => {
    if (!hasNext || isLoadingNext) {
      return;
    }

    loadNext(
      50,
      { onComplete: (error) => { console.log({ error }); } },
    );
  };

  const copyLoadMore = intl.formatMessage({ id: 'admin.admin_user_members.load_more_button.state_load_more.label', defaultMessage: 'Load more...' });
  const copyNoMore = intl.formatMessage({ id: 'admin.admin_user_members.load_more_button.state_no_more.label', defaultMessage: 'No more portfolio user members to load' });

  return (
    <Card className="mb-4">
      <CardHeader>
        {intl.formatMessage({ id: 'admin.admin_portfolio.admin_portfolio_show.admin_portfolio_user_members.card_header.user.label', defaultMessage: 'User Members' })}
      </CardHeader>
      <Table responsive>
        <thead>
          <tr>
            <th scope="col">
              {intl.formatMessage({ id: 'admin.admin_portfolio.admin_portfolio_show.admin_portfolio_user_members.card_body.head.username.label', defaultMessage: 'Username' })}
            </th>
            <th scope="col">
              {intl.formatMessage({ id: 'admin.admin_portfolio.admin_portfolio_show.admin_portfolio_user_members.card_body.head.email.label', defaultMessage: 'Email' })}
            </th>
            <th scope="col">
              <abbr title={intl.formatMessage({ id: 'admin.admin_portfolio.admin_portfolio_show.admin_portfolio_user_members.card_body.head.identifier.abbr.title', defaultMessage: 'Identifier' })}>
                {intl.formatMessage({ id: 'admin.admin_portfolio.admin_portfolio_show.admin_portfolio_user_members.card_body.head.id.label', defaultMessage: 'ID' })}
              </abbr>
            </th>

            <th scope="col">
              <abbr title={intl.formatMessage({ id: 'admin.admin_portfolio.admin_portfolio_show.admin_portfolio_user_members.card_body.head.ext_identifier.abbr.title', defaultMessage: 'External identifier' })}>
                {intl.formatMessage({ id: 'admin.admin_portfolio.admin_portfolio_show.admin_portfolio_user_members.card_body.head.ext_identifier.label', defaultMessage: 'Ext ID' })}
              </abbr>
            </th>
            <th scope="col">
              {intl.formatMessage({ id: 'admin.admin_portfolio.admin_portfolio_show.admin_portfolio_user_members.card_body.head.active.label', defaultMessage: 'Active' })}
            </th>
          </tr>
        </thead>
        <tbody>
          {userMembers.length === 0 && (
            <tr>
              <td colSpan={5}>
                {noUsersMsg()}
              </td>
            </tr>
          )}
          {userMembers.map((member) => {
            const {
              active, externalIdentifier,
              uuid, role, user,
            } = member || {};
            const { start, finish } = active || {};
            const { email, familyName, givenName } = user;

            return (
              <tr key={uuid}>
                <th scope="row" colSpan={2}>
                  <Link to={`/admin/portfolio-user-members/${uuid}`}>
                    <span style={{ fontWeight: 'bold' }}>{username({ familyName, givenName })}</span>
                  </Link>
                  <br />
                  {email}
                  <br />
                  <BadgePortfolioUserRole userRole={role} />
                </th>
                <th scope="row" colSpan={2}>
                  <UUID uuid={uuid} />
                  <br />
                  {externalIdentifier}
                </th>
                <td aria-label="badge active">
                  <BadgeActive start={start} finish={finish} />
                  <br />
                  {getTimeRangeMsg(active, intl)}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <CardFooter>
        <LoadMoreButton
          copyLoadMore={copyLoadMore}
          copyNoMore={copyNoMore}
          hasNext={hasNext}
          onClick={loadMore}
        />
      </CardFooter>
    </Card>
  );
}

AdminPortfolioUserMembers.propTypes = {
  // note: known bug https://github.com/jsx-eslint/eslint-plugin-react/issues/1002#issuecomment-320160686
  // eslint-disable-next-line react/forbid-prop-types, react/no-unused-prop-types
  portfolio: PropTypes.object.isRequired,
};

export default AdminPortfolioUserMembers;
