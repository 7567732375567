import {
  SpanKind, SpanStatusCode,
} from '@opentelemetry/api';

import { tracingProvider } from 'src/tracing/setup';
import { tracingState } from 'src/tracing/state';

const tracerName = 'frontend';

/**
 * Description
 * @param {any} name
 * @param {any} opts
 * @param {any} f
 * @param {any} args
 * @returns {Promise} - promise
 */
async function withTracingOptionsAsync(name, opts, f, ...args) {
  const tracer = tracingProvider.getProvider().getTracer(tracerName);
  // Always set start/end time to avoid issues with sleep/hibernation
  const tracingOpts = opts;
  tracingOpts.startTime = new Date();
  return tracer.startActiveSpan(name, tracingOpts, async (span) => {
    try {
      return await f(...args);
    } catch (e) {
      if (span) {
        span.recordException(e);
        span.setStatus({
          code: SpanStatusCode.ERROR,
          message: (e && (e.stack || e.message)) || String(e),
        });
      }
      throw e;
    } finally {
      if (span) {
        await tracingState.addToSpan(span);
        span.end(new Date());
      }
    }
  });
}

/**
 * Description
 * @param {any} name
 * @param {any} f
 * @param {any} args
 * @returns {Promise} - promise
 */
async function withTracingAsync(name, f, ...args) {
  return withTracingOptionsAsync(name, { kind: SpanKind.INTERNAL }, f, ...args);
}

/**
 * Wrap tracing around a function that returns a Promise
 * @param {any} name
 * @param {any} opts
 * @param {any} f
 * @param {any} args
 * @returns {Promise} - promise
 */
function withTracingOptions(name, opts, f, ...args) {
  const tracer = tracingProvider.getProvider().getTracer(tracerName);
  // Always set start/end time to avoid issues with sleep/hibernation
  const tracingOpts = opts;
  tracingOpts.startTime = new Date();
  return tracer.startActiveSpan(name, tracingOpts, (span) => new Promise((resolve) => {
    resolve(f(...args));
  })
    .then((result) => result)
    .catch((e) => {
      if (span) {
        span.recordException(e);
        span.setStatus({
          code: SpanStatusCode.ERROR,
          message: (e && (e.stack || e.message)) || String(e),
        });
      }
      throw e;
    })
    .finally(() => {
      if (span) {
        tracingState.addToSpan(span)
          .then(() => span.end(new Date()))
          .catch((e) => console.error('error adding to span', e));
      }
    }));
}

/**
 * Description
 * @param {any} name
 * @param {any} f
 * @param {any} args
 * @returns {Promise} - promise
 */
function withTracing(name, f, ...args) {
  return withTracingOptions(name, { kind: SpanKind.INTERNAL }, f, ...args);
}

export {
  tracerName,
  tracingState,
  withTracingAsync,
  withTracingOptionsAsync,
  withTracing,
  withTracingOptions,
};
