import Link from 'found/Link';
import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';
import {
  Card, CardHeader, Table,
} from 'reactstrap';

import Address from 'src/components/Address';
import BadgeActive from 'src/components/Badge/BadgeActive';
import UUID from 'src/components/UUID';
import getTimeRangeMsg from 'src/util/timeRange';

/**
 * @param {object} props
 * @param {object} props.property portfolio property member's property details
 * @param {object} props.property.active portfolio property member's property active timerange
 * @param {number} props.property.active.start portfolio property member's property active start
 * timestamp
 * @param {number} props.property.active.finish portfolio property member's property active finish
 * timestamp
 * @param {string} props.property.title portfolio property member's property's title
 * @param {object} props.property.address portfolio property member's property's family name
 * @param {string} props.property.externalIdentifier portfolio property member's property's external
 * identifier
 * @param {string} props.property.uuid portfolio property member's property's uuid
 * @returns {React.ReactElement} - PropertyMemberPropertyCard component
 */
function PropertyMemberPropertyCard(props) {
  const { property } = props || {};

  const {
    active, title, address,
    externalIdentifier,
    uuid,
  } = property || {};

  const { start, finish } = active || {};
  const addressString = new Address(address).string();
  const intl = useIntl();

  return (
    <Card className="mb-4">
      <CardHeader>
        {intl.formatMessage({ id: 'admin.admin_portfolio_property_member.admin_portfolio_property_member_show.property_card.card_header.property.label', defaultMessage: 'Property' })}
      </CardHeader>
      <Table responsive>
        <tbody>
          <tr>
            <th scope="col">
              {intl.formatMessage({ id: 'admin.admin_portfolio_property_member.admin_portfolio_property_member_show.property_card.card_body.head.title.label', defaultMessage: 'Title' })}
            </th>
            <th scope="col">
              <abbr title={intl.formatMessage({ id: 'admin.admin_portfolio_property_member.admin_portfolio_property_member_show.property_card.card_body.head.id.abbr.title', defaultMessage: 'Identifier' })}>
                {intl.formatMessage({ id: 'admin.admin_portfolio_property_member.admin_portfolio_property_member_show.property_card.card_body.head.id.label', defaultMessage: 'ID' })}
              </abbr>
            </th>
            <th scope="col">
              {intl.formatMessage({ id: 'admin.admin_portfolio_property_member.admin_portfolio_property_member_show.property_card.card_body.head.address.label', defaultMessage: 'Address' })}
            </th>
            <th scope="col">
              <abbr title={intl.formatMessage({ id: 'admin.admin_portfolio_property_member.admin_portfolio_property_member_show.property_card.card_body.head.ext_id.abbr.title', defaultMessage: 'External identifier' })}>
                {intl.formatMessage({ id: 'admin.admin_portfolio_property_member.admin_portfolio_property_member_show.property_card.card_body.head.ext_id.label', defaultMessage: 'Ext ID' })}
              </abbr>
            </th>
            <th scope="col">
              {intl.formatMessage({ id: 'admin.admin_portfolio_property_member.admin_portfolio_property_member_show.property_card.card_body.head.active.label', defaultMessage: 'Active' })}
            </th>
          </tr>
          <tr>
            <th scope="row" colSpan={2}>
              <Link to={`/admin/properties/${uuid}`}>
                {title}
              </Link>
              <br />
              <UUID uuid={uuid} />
            </th>
            <td>{addressString}</td>
            <td>{externalIdentifier}</td>
            <td aria-label="badge active">
              <BadgeActive start={start} finish={finish} />
              <br />
              {getTimeRangeMsg(active, intl)}
            </td>
          </tr>
        </tbody>
      </Table>
    </Card>
  );
}

PropertyMemberPropertyCard.propTypes = {
  property: PropTypes.shape({
    active: PropTypes.shape({
      start: PropTypes.number,
      finish: PropTypes.number,
    }),
    title: PropTypes.string,
    address: PropTypes.shape({
      line1: PropTypes.string.isRequired,
      line2: PropTypes.string,
      city: PropTypes.string.isRequired,
      state: PropTypes.string.isRequired,
      country: PropTypes.string.isRequired,
      postcode: PropTypes.string.isRequired,
    }).isRequired,
    externalIdentifier: PropTypes.string,
    uuid: PropTypes.string,
  }).isRequired,
};

export default PropertyMemberPropertyCard;
