import { Link } from 'found';
import React from 'react';
import { ButtonGroup, ButtonToolbar } from 'reactstrap';

/**
 * Description
 * @returns {React.ReactComponentElement} - AdminUserCOntrols component
 */
function AdminUserControls() {
  return (
    <ButtonToolbar>
      <ButtonGroup className="ms-2">
        <Link
          to="/admin/users/invite/customer"
          className="btn btn-darken"
        >
          Invite customer
        </Link>
        <Link
          to="/admin/users/invite/admin"
          className="btn btn-darken"
        >
          Invite admin
        </Link>
      </ButtonGroup>
    </ButtonToolbar>
  );
}

export default AdminUserControls;
