import React from 'react';
import { FormattedMessage } from 'react-intl';

import { portfolioPropertiesLink, portfolioUsersLink } from 'src/util/links/customer';

/**
 * Generates a subheading string with the number of properties.
 * @param {number} count - The number of properties.
 * @returns {React.ReactElement} A subheading string
 */
const subHeadingProperties = (count) => (
  <FormattedMessage
    id="portfolio.common.subheading.description.properties"
    defaultMessage="{count, plural, =0 {no properties} one {# property} other {# properties}}"
    values={{ count }}
  />
);

/**
 * Generates a subheading string with the number of properties (100 or more).
 * @returns {React.ReactElement} A subheading string
 */
const subHeadingMoreThan100Properties = () => (
  <FormattedMessage
    id="portfolio.common.subheading.description.properties_gteq_100"
    defaultMessage="100+ properties"
  />
);

/**
 * Generates a subheading string with the number of users.
 * @param {number} count - The number of properties.
 * @returns {React.ReactElement} A subheading string
 */
const subHeadingUsers = (count) => (
  <FormattedMessage
    id="portfolio.common.subheading.description.description.users"
    defaultMessage="{count, plural, =0 {no users} one {# user} other {# users}}"
    values={{ count }}
  />
);

/**
 * Generates a subheading string with the number of users (100 or more).
 * @returns {React.ReactElement} A subheading string
 */
const subHeadingMoreThan100Users = () => (
  <FormattedMessage
    id="portfolio.common.subheading.description.users_gteq_100"
    defaultMessage="100+ users"
  />
);

/**
 * Generates a subheading string with the number of properties and users.
 * @param {object} portfolio portfolio object.
 * @returns {React.ReactElement} A subheading string
 */
const subHeading = (portfolio) => {
  const {
    propertyCount, userCount,
  } = portfolio || {};

  const propertyLabel = propertyCount >= 100
    ? subHeadingMoreThan100Properties()
    : subHeadingProperties(propertyCount);

  const userLabel = userCount >= 100
    ? subHeadingMoreThan100Users
    : subHeadingUsers(userCount);

  return (
    <>

      {portfolioPropertiesLink(portfolio, propertyLabel)}
      {' '}
      •
      {' '}
      {portfolioUsersLink(portfolio, userLabel)}
    </>
  );
};

export default subHeading;
