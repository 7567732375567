import PropTypes from 'prop-types';
import React from 'react';

import { buildTradeSummaryData } from 'src/components/Dashboard/helpers/common';
import { renderSummaryCards } from 'src/components/Dashboard/helpers/showTradeSummary';
import Loading from 'src/components/Loading';

/**
 * Renders the trade summary section for a portfolio.
 * @param {object} props - The component props.
 * @param {object} props.mainData - The main data for the portfolio.
 * @returns {React.ReactComponentElement} The rendered trade summary section.
 */
function PortfolioShowTradeSummary(props) {
  const { mainData } = props;

  if (!mainData) {
    return <Loading />;
  }

  const { hasCounterfactual, portfolio } = mainData;
  const { externalIdentifier: title } = portfolio;

  const summaryData = buildTradeSummaryData(mainData);

  return (
    <div className="trade-summaries mb-4 row row-cols-1 row-cols-sm-2">
      {renderSummaryCards(summaryData, title, hasCounterfactual)}
    </div>
  );
}

PortfolioShowTradeSummary.propTypes = {
  mainData: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default PortfolioShowTradeSummary;
