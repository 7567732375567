import { Link } from 'found';
import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet-async';

import Loading from 'src/components/Loading';
import Breadcrumbs from 'src/enosikit/components/Breadcrumbs';
import InviteAdminUserMutation from 'src/mutations/InviteAdminUserMutation';
import FlashesStore from 'src/stores/FlashesStore';

import AdminUserInviteAdminForm from './AdminUserInviteAdminForm';
import AdminUserControls from '../AdminUserControls';

class AdminUserInviteAdmin extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      processing: false,
    };
  }

  handleAdminUserInviteAdmin = (input) => {
    this.setState({ processing: true });
    FlashesStore.reset();

    InviteAdminUserMutation(
      input,
      this.handleAdminUserInviteAdminSuccess,
      this.handleAdminUserInviteAdminFailure,
    );
  };

  handleAdminUserInviteAdminSuccess = (response, errors) => {
    this.setState({ processing: false });

    if (errors && errors.length > 0) {
      errors.forEach((error) => FlashesStore.flash(FlashesStore.ERROR, {
        message: FlashesStore.ERROR,
        source: {
          errors: [error],
        },
      }));
      return;
    }

    const { uuid, email } = response.inviteAdminUser;

    FlashesStore.flash(
      FlashesStore.SUCCESS,
      <span>
        An email invitation has been sent to
        <Link to={`/admin/users/${uuid}`}>
          {` ${email} `}
        </Link>
        to finish their account registration.
      </span>,
    );

    const { router } = this.props;
    router.push('/admin/users/');
  };

  handleAdminUserInviteAdminFailure = (error) => {
    this.setState({ processing: false });

    FlashesStore.flash(FlashesStore.ERROR, error);
  };

  render() {
    if (this.error) {
      return <div>Error!</div>;
    }
    if (!this.props) {
      return <Loading />;
    }

    const { processing } = this.state;

    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Enosi - Admin - Users - Invite Admin</title>
        </Helmet>

        <Breadcrumbs
          breadcrumbs={[
            { name: 'Admin', path: '/admin' },
            { name: 'Users', path: '/admin/users' },
            { name: 'Invite new admin' },
          ]}
        />
        <div className="clearfix">
          <div className="float-end mb-3">
            <AdminUserControls />
          </div>
        </div>

        <AdminUserInviteAdminForm
          handleInvite={this.handleAdminUserInviteAdmin}
          processing={processing}
        />
      </>
    );
  }
}

AdminUserInviteAdmin.propTypes = {
  relay: PropTypes.shape({
    refetch: PropTypes.func,
  }).isRequired,
  router: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

AdminUserInviteAdmin.defaultProps = {};

export default AdminUserInviteAdmin;
