import { faWindowMinimize } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';

import LogoImage from 'src/assets/images/branding/enosi-logo.svg';
import Logo from 'src/enosikit/components/Logo';

/**
 * Description
 * @param {any} props
 * @returns {React.ReactComponentElement} - NavigationLogo component
 */
function NavigationLogo(props) {
  const renderCobrand = (cobrand) => {
    if (cobrand === null) { return (null); }

    return (
      <>
        <FontAwesomeIcon icon={faWindowMinimize} size="1x" className="ms-1" color="rgb(0,0,0,0.55)" rotation={90} style={{ marginRight: '-.375em' }} />
        <Logo image={cobrand.imagePath} name={cobrand.title} height="27px" />
      </>
    );
  };

  const { primaryBrand, cobrand } = props;

  return (
    <>
      <Logo image={primaryBrand.imagePath} name={primaryBrand.title} />
      {renderCobrand(cobrand)}
    </>
  );
}

NavigationLogo.propTypes = {
  cobrand: PropTypes.shape({
    imagePath: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }),
  primaryBrand: PropTypes.shape({
    imagePath: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }),
};

NavigationLogo.defaultProps = {
  cobrand: null,
  primaryBrand: {
    imagePath: LogoImage,
    title: 'Enosi Powertracer',
    type: 'BRAND_PRIMARY',
  },
};

export default NavigationLogo;
