import { Link } from 'found';
import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { createFragmentContainer, graphql } from 'react-relay';

import Loading from 'src/components/Loading';
import UUID from 'src/components/UUID';
import Breadcrumbs from 'src/enosikit/components/Breadcrumbs';
import PropertyAddUserMutation from 'src/mutations/PropertyAddUserMutation';
import FlashesStore from 'src/stores/FlashesStore';
import { USER_TYPE_CUSTOMER } from 'src/util/constants';

import AdminPropertyUserAddForm from './AdminPropertyUserAddForm';

class AdminPropertyUserAdd extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      processing: false,
    };
  }

  handleAdminPropertyUserAdd = (propertyId, userId) => {
    this.setState({ processing: true });
    FlashesStore.reset();

    PropertyAddUserMutation(
      propertyId,
      userId,
      this.handleAdminPropertyUserAddSuccess,
      this.handleAdminPropertyUserAddFailure,
    );
  };

  handleAdminPropertyUserAddSuccess = (response, errors) => {
    this.setState({ processing: false });

    if (errors && errors.length > 0) {
      errors.forEach((error) => FlashesStore.flash(FlashesStore.ERROR, {
        message: FlashesStore.ERROR,
        source: {
          errors: [error],
        },
      }));
      return;
    }

    const { uuid } = response.propertyAddUser;

    FlashesStore.flash(
      FlashesStore.SUCCESS,
      <span>
        {'Successfully added Property User '}
        <Link to={`/admin/property-users/${uuid}`}>
          <UUID uuid={uuid} />
        </Link>
        .
      </span>,
    );

    const { router } = this.props;
    router.push('/admin/property-users/');
  };

  handleAdminPropertyUserAddFailure = (error) => {
    this.setState({ processing: false });

    FlashesStore.flash(FlashesStore.ERROR, error);
  };

  render() {
    if (this.error) {
      return <div>Error!</div>;
    }
    if (!this.props) {
      return <Loading />;
    }

    const { viewer } = this.props;
    const { properties, users } = viewer;

    const { processing } = this.state;

    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Enosi - Admin - Property Users - Add Property User</title>
        </Helmet>

        <Breadcrumbs
          breadcrumbs={[
            { name: 'Admin', path: '/admin' },
            { name: 'Property Users', path: '/admin/property-users' },
            { name: 'Add Property User' },
          ]}
        />

        <AdminPropertyUserAddForm
          handleAdd={this.handleAdminPropertyUserAdd}
          processing={processing}
          properties={properties && properties.edges
            && properties.edges.map((edge) => edge.node)}
          users={users && users.edges && users.edges
            .filter((edge) => edge.node.type === USER_TYPE_CUSTOMER).map((edge) => edge.node)}
        />
      </>
    );
  }
}

AdminPropertyUserAdd.propTypes = {
  relay: PropTypes.shape({
    hasMore: PropTypes.func,
    isLoading: PropTypes.func,
    loadMore: PropTypes.func,
  }).isRequired,
  router: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  viewer: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

AdminPropertyUserAdd.defaultProps = {
  viewer: null,
};

export default createFragmentContainer(
  AdminPropertyUserAdd,
  {
    viewer: graphql`
      fragment AdminPropertyUserAdd_viewer on Viewer {
        id
        viewerUser {
          email
          givenName
          familyName
        }
        properties(
          first: $count
          after: $cursor
        ) {
          edges {
            cursor
            node {
              uuid
              title
              address { line1 line2 city state postcode }
            }
          }
          count
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          }
        }
        users(
          first: $count
          after: $cursor
        ) {
          edges {
            cursor
            node {
              uuid
              type
              givenName
              familyName
              email
            }
          }
          count
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          }
        }
      }
    `,
  },
);
