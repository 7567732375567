import Link from 'found/Link';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import React from 'react';

import Address from 'src/components/Address';
import BadgeActive from 'src/components/Badge/BadgeActive';
import Loading from 'src/components/Loading';
import UUID from 'src/components/UUID';

/**
 * Description
 * @param {object} props
 * @returns {React.ReactComponentElement} - AdminCommunityShowProperty component
 */
function AdminCommunityShowProperty(props) {
  if (!props) {
    return <tr><td aria-label="loading" colSpan={4}><Loading /></td></tr>;
  }

  const { property } = props;
  const {
    uuid, title, externalIdentifier, active, address,
  } = property;
  const { start, finish } = active;

  const addr = address ? new Address(address) : null;

  return (
    <tr key={`property-${uuid}`}>
      <th scope="row">
        <Link to={`/admin/properties/${uuid}`}>
          {title}
        </Link>
        <br />
        {addr && addr.simpleString()}
      </th>
      <td>
        <UUID uuid={uuid} />
      </td>
      <td>
        {externalIdentifier}
      </td>
      <td>
        <BadgeActive start={start} finish={finish} />
        <br />
        {start && DateTime.fromSeconds(start).toLocaleString(DateTime.DATETIME_FULL)}
        -
        {finish && DateTime.fromSeconds(finish).toLocaleString(DateTime.DATETIME_FULL)}
      </td>
    </tr>
  );
}

AdminCommunityShowProperty.propTypes = {
  property: PropTypes.shape({
    uuid: PropTypes.string,
    externalIdentifier: PropTypes.string,
    title: PropTypes.string,
    active: PropTypes.shape({
      start: PropTypes.number,
      finish: PropTypes.number,
    }),
    address: PropTypes.shape({
      line1: PropTypes.string,
      line2: PropTypes.string,
      city: PropTypes.string,
      state: PropTypes.string,
      postcode: PropTypes.string,
    }),
  }),
};

AdminCommunityShowProperty.defaultProps = {
  property: null,
};

export default AdminCommunityShowProperty;
