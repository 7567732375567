import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';
import { Badge } from 'reactstrap';

const colorMapping = {
  TRADE_RULE_STATE_ACCEPTED: 'success',
  TRADE_RULE_STATE_CANCELLED: 'danger',
  TRADE_RULE_STATE_CLOSED: 'info',
  TRADE_RULE_STATE_PROPOSED: 'warning',
  TRADE_RULE_STATE_REJECTED: 'danger',
};

/**
 * Description
 * @param {object} props
 * @param {string} props.state
 * @returns {React.ReactComponentElement} - BadgeTradeRuleState component
 */
function BadgeTradeRuleState({ state }) {
  if (!state) { return (null); }

  const intl = useIntl();
  const color = colorMapping[state] || 'mid';
  const title = state.split('_').slice(-1)[0];
  let finalState;
  switch (title?.toLowerCase()) {
    case 'accepted':
      finalState = intl.formatMessage({ id: 'badge.badge_trade_rule_state.accepted.label', defaultMessage: 'Accepted' });
      break;
    case 'cancelled':
      finalState = intl.formatMessage({ id: 'badge.badge_trade_rule_state.cancelled.label', defaultMessage: 'Cancelled' });
      break;
    case 'closed':
      finalState = intl.formatMessage({ id: 'badge.badge_trade_rule_state.closed.label', defaultMessage: 'Closed' });
      break;
    case 'proposed':
      finalState = intl.formatMessage({ id: 'badge.badge_trade_rule_state.proposed.label', defaultMessage: 'Proposed' });
      break;
    case 'rejected':
      finalState = intl.formatMessage({ id: 'badge.badge_trade_rule_state.rejected.label', defaultMessage: 'Rejected' });
      break;
    default:
      finalState = '';
  }
  finalState = finalState?.toUpperCase();

  return (
    <Badge color={color} className="badge-outline">{finalState}</Badge>
  );
}

BadgeTradeRuleState.propTypes = {
  state: PropTypes.string.isRequired,
};

export default BadgeTradeRuleState;
