import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import BadgeActive from 'src/components/Badge/BadgeActive';
import BadgePortfolioUserRole from 'src/components/Badge/BadgePortfolioUserRole';
import LoadMoreButton from 'src/components/LoadMoreButton';
import Alert from 'src/enosikit/components/Alert';
import { ACTIVE_STATE_ACTIVE, ACTIVE_STATE_INACTIVE, LIST_FILTER_ALL } from 'src/util/constants';
import activePeriodText from 'src/util/i18n/activePeriodText';

import getElementsWithState from '../helpers';

/**
 * Renders an alert component to inform the user that there is no user in a portfolio.
 * @returns {React.ReactElement} The alert component.
 */
export function NoUserAlert() {
  return (
    <Alert color="warning" className="mb-2 w-100">
      <FormattedMessage
        id="portfolio.portfolio_user_members_list.no_user.alert"
        defaultMessage="<strong>No users memberships found for this portfolio.</strong> Unfortunately we do not currently have any user memberships for this portfolio. If you believe that this is an error please make use of our support site to raise an issue."
        values={{
          strong: (chunks) => <strong>{chunks}</strong>,
        }}
      />
    </Alert>
  );
}

/**
 * Renders an alert component to notify the user that there is no active user in a portfolio
 * with the selected filters.
 * @returns {React.ReactElement} The alert component.
 */
export function NoActiveUsersForSelectedFiltersAlert() {
  return (
    <Alert color="warning" className="mb-2 w-100">
      <FormattedMessage
        id="portfolio.portfolio_user_members_list.no_active_users_for_selected_filters.alert"
        defaultMessage="<strong>No active users memberships found in this portfolio for that filter selection.</strong> Unfortunately we do not currently have any active user memberships for this portfolio with these filters. If you believe that this is an error please make use of our support site to raise an issue."
        values={{
          strong: (chunks) => <strong>{chunks}</strong>,
        }}
      />
    </Alert>

  );
}

/**
 * Renders an alert component to display a message when there are no inactive user in a portfolio
 * with the selected filters.
 * @returns {React.ReactElement} The alert component.
 */
export function NoInactiveUsersForSelectedFiltersAlert() {
  return (
    <Alert color="warning" className="mb-2 w-100">
      <FormattedMessage
        id="portfolio.portfolio_user_members_list.no_inactive_users_for_selected_filters.alert"
        defaultMessage="<strong>No inactive users memberships found in this portfolio for that filter selection.</strong> Unfortunately we do not currently have any inactive user memberships for this portfolio with these filters. If you believe that this is an error please make use of our support site to raise an issue."
        values={{
          strong: (chunks) => <strong>{chunks}</strong>,
        }}
      />
    </Alert>
  );
}

/**
 *
 * @param {object} props
 * @param {ACTIVE_STATE_ACTIVE | ACTIVE_STATE_INACTIVE | LIST_FILTER_ALL} props.activeState
 * @param {boolean} props.hasNext Whether there is more users to load.
 * @param {Function} props.loadMore Function to load more users.
 * @param {Array} props.rowsData Array of user data to be displayed in the table.
 * @returns {React.ReactComponentElement} PorfolioUserMemberListTable.
 */
function PorfolioUserMemberListTable({
  activeState, hasNext, loadMore, rowsData,
}) {
  if (rowsData.length === 0) return <NoUserAlert />;

  const rowsDataWithState = getElementsWithState(rowsData, activeState);

  if (rowsDataWithState.length === 0) {
    switch (activeState) {
      case ACTIVE_STATE_INACTIVE:
        return <NoInactiveUsersForSelectedFiltersAlert />;

      case ACTIVE_STATE_ACTIVE:
        return <NoActiveUsersForSelectedFiltersAlert />;

      default: return <NoUserAlert />;
    }
  }
  const intl = useIntl();

  const copyLoadMore = intl.formatMessage({ id: 'portfolio.portfolio_user_member_list_table.load_more_button.state_load_more.label', defaultMessage: 'Load more...' });
  const copyNoMore = intl.formatMessage({ id: 'portfolio.portfolio_user_member_list_table.load_more_button.state_no_more.label', defaultMessage: 'No more user members to load' });

  return (
    <div className="mt-4 mb-4 card">
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th scope="col">
                <FormattedMessage id="portfolio.portfolio_user_members_list.table.head.user.label" defaultMessage="User" />
              </th>
              <th scope="col">
                <FormattedMessage id="portfolio.portfolio_user_members_list.table.head.active.label" defaultMessage="Active" />
              </th>
            </tr>
          </thead>
          <tbody>
            {rowsDataWithState.map((user) => {
              const {
                active, email, familyName, givenName, uuid, role,
              } = user;
              const { start, finish } = active;
              return (
                <tr key={`user-row-${uuid}`}>
                  <th scope="row" data-testid="user-info">
                    {[givenName, familyName].filter((el) => !!el).join(' ')}
                    <br />
                    <span style={{ fontWeight: 'normal' }}>{email}</span>
                    <br />
                    <BadgePortfolioUserRole userRole={role} />
                  </th>
                  <td>
                    <BadgeActive start={start} finish={finish} />
                    <br />
                    {activePeriodText(intl, active)}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="card-footer">
        <div>
          <LoadMoreButton
            copyLoadMore={copyLoadMore}
            copyNoMore={copyNoMore}
            hasNext={hasNext}
            onClick={loadMore}
          />
        </div>
      </div>
    </div>
  );
}

PorfolioUserMemberListTable.propTypes = {
  activeState: PropTypes.oneOf([
    ACTIVE_STATE_ACTIVE, ACTIVE_STATE_INACTIVE, LIST_FILTER_ALL,
  ]).isRequired,
  hasNext: PropTypes.bool.isRequired,
  loadMore: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  rowsData: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default PorfolioUserMemberListTable;
