import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { getChartSummaryData } from 'src/components/Dashboard/helpers/common';
import Loading from 'src/components/Loading';
import { APIConfig } from 'src/config';
import Chart from 'src/enosikit/components/Chart';
import Cards from 'src/enosikit/components/Chart/components/Cards';
import { meterContent, tradeContent } from 'src/enosikit/components/Chart/helpers/cards';
import { getTotalCarbonEmission } from 'src/lib/carbondata/helpers';
import {
  DATA_AGGREGATE_BY_PORTFOLIO, DATA_AGGREGATE_BY_PROPERTY,
  DATA_GROUP_BY_COUNTERPARTY, DATA_GROUP_BY_TRADE_TYPE,
  EXPORTS, IMPORTS, SOURCE,
  SOURCE_HISTORIAN, SOURCE_TRADES,
  TIME_ZONE_SYSTEM,
  UNIT_CARBON, UNIT_CURRENCY, UNIT_ENERGY,
} from 'src/util/constants';

import { meterChartTooltip, tradeChartTooltip } from './helpers/chartTooltip';
import {
  buildCardsData, buildChartData, buildMeterViewCardLabel, buildTradeViewCardLabel,
} from './helpers/portfolioShowChart';

/**
 * Returns the PortfolioShowChart component
 * @param {object} props
 * @param {object} props.mainData MainData for the portfolio dashboard.
 * @param {object} props.chartView Grouping and aggregation options for the chart.
 * @param {DATA_AGGREGATE_BY_PROPERTY | DATA_AGGREGATE_BY_PORTFOLIO} props.chartView.aggregateBy
 * @param {DATA_GROUP_BY_COUNTERPARTY | DATA_GROUP_BY_TRADE_TYPE} props.chartView.groupBy
 * @param {Function} props.controlSetStateFunc Set the meter or the trade view.
 * @param {string} props.historianAggregation Aggregation for the chart.
 * @param {SOURCE_HISTORIAN | SOURCE_TRADES} props.source MainData for the portfolio dashboard
 * @param {Function} props.timespanUpdateFunc Gives the ability to update the timespan.
 * @param {string} props.tradeAggregation The aggregation method for the trade data.
 * @param {UNIT_ENERGY| UNIT_CARBON | UNIT_CURRENCY} props.unit The unit for the chart.
 * @returns {React.ReactComponentElement} PortfolioShowChart
 */
function PortfolioShowChart(props) {
  if (!props?.mainData) {
    return <Loading />;
  }

  const chartRef = useRef(true);

  const [chartSize, setChartSize] = useState({ height: 0, width: 0 });
  const [hoverKeys, setHoverKeys] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [tooltipTimestamp, setTooltipTimestamp] = useState(null);

  const {
    chartView, controlSetStateFunc, mainData, historianAggregation,
    source, timespanUpdateFunc, tradeAggregation, unit,
  } = props;

  const mode = APIConfig().MODE;
  const meterView = source === SOURCE_HISTORIAN;
  const tradeView = source === SOURCE_TRADES;
  const aggByProperty = chartView.aggregateBy === DATA_AGGREGATE_BY_PROPERTY;

  const { hasCounterfactual } = mainData;
  const chartData = buildChartData(meterView, mainData, chartView);
  const chartSummaryData = getChartSummaryData(mainData);
  const cardsData = buildCardsData(meterView, chartData, chartView);
  const totalCarbonEmission = getTotalCarbonEmission(mainData.buy?.data);
  const { buy: buySummary, sell: sellSummary } = chartSummaryData;

  const {
    ChartControls, ChartMeter, ChartSummary, ChartTrade,
  } = Chart;

  /**
   * Handles the hover state for the cards in the PortfolioShowChart component.
   * @param {string} key The unique identifier of the card being hovered over.
   * @param {boolean} hover Indicates whether the card is being hovered over or not.
   * @returns {void}
   */
  const cardsHoverFunc = (key, hover) => {
    setHoverKeys((prevHoverKeys) => {
      const newHoverKeys = Array.isArray(prevHoverKeys) ? [...prevHoverKeys] : [];

      if (hover) {
        if (!newHoverKeys.includes(key)) {
          newHoverKeys.push(key);
        }
        return newHoverKeys;
      }
      const index = newHoverKeys.indexOf(key);
      if (index !== -1) {
        newHoverKeys.splice(index, 1);
      }

      return newHoverKeys;
    });
  };

  /**
   * Handles the selection state for the cards in the PortfolioShowChart component.
   * @param {string} key The unique identifier of the card being selected.
   * @returns {void}
   */
  const cardsSelectedFunc = (key) => {
    setSelectedKeys((prevSelectedKeys) => {
      const newSelectedKeys = Array.isArray(prevSelectedKeys) ? [...prevSelectedKeys] : [];

      if (selectedKeys.includes(key)) {
        newSelectedKeys.splice(selectedKeys.indexOf(key), 1);
        return newSelectedKeys;
      }
      newSelectedKeys.push(key);

      return newSelectedKeys;
    });
  };

  /**
   * Set the boundary for the bar chart.
   * @returns {void}
   */
  const setChartBounds = () => {
    if (!chartRef) {
      return;
    }

    let { clientHeight: height, clientWidth: width } = chartRef.current;

    const {
      paddingTop, paddingBottom, paddingLeft, paddingRight,
    } = window.getComputedStyle(chartRef.current);

    if (paddingTop !== '') { height -= parseFloat(paddingTop); }
    if (paddingBottom !== '') { height -= parseFloat(paddingBottom); }
    if (paddingLeft !== '') { width -= parseFloat(paddingLeft); }
    if (paddingRight !== '') { width -= parseFloat(paddingRight); }

    setChartSize({ height, width });
  };

  /**
   * Provides the control options for the chart in the PortfolioShowChart component.
   * @returns {object[]} An array of control option objects.
   */
  const controlOptionFunc = () => {
    const typeOpts = {
      group: SOURCE,
      items: [{
        value: SOURCE_TRADES, label: <FormattedMessage id="portfolio.portfolio_show.chart.control.source.trades.title" defaultMessage="Trades" />, active: (source === SOURCE_TRADES), disabled: false,
      }, {
        value: SOURCE_HISTORIAN, label: <FormattedMessage id="portfolio.portfolio_show.chart.control.source.historian.title" defaultMessage="Meter" />, active: (source === SOURCE_HISTORIAN), disabled: false,
      }],
    };
    return [typeOpts];
  };

  useEffect(() => {
    window.addEventListener('resize', setChartBounds);
    setChartBounds();
    // clean up on unmount
    return () => {
      window.removeEventListener('resize', setChartBounds);
    };
  }, []);

  useEffect(() => {
    setSelectedKeys([]);
  }, [source]);

  /**
   * Handles the timespan update when a bar(in the chart) is clicked.
   * @returns {void}
   */
  const handleChartClick = () => {
    if (tooltipTimestamp === null || historianAggregation.match(/^\d+S$/)) {
      return;
    }
    timespanUpdateFunc(tooltipTimestamp, tooltipTimestamp);
  };

  /**
   * Renders the content for a meter card.
   * @param {object} cardDatum An object containing the data for the meter card.
   * @param {object=} cardDatum.property The property that is part of the portfolio.
   * @param {object=} cardDatum.counterParty The counterparty.
   * @param {object=} cardDatum.counterPartyProperty The coutnerparty's property.
   * @param {object} cardDatum.buy The buy object for the meter card.
   * @param {object} cardDatum.sell The sell object for the meter card.
   * @returns {React.ReactElement|null} The rendered meter card content.
   */
  const meterViewCard = (cardDatum) => {
    const { property, buy, sell } = cardDatum;
    const { volume: buyVolume } = buy;
    const { volume: sellVolume } = sell;
    const cardLabel = buildMeterViewCardLabel(property);

    return meterContent(cardLabel, buyVolume, sellVolume);
  };

  const intl = useIntl();

  /**
   * Renders the content for a trade card.
   * @param {object} cardDatum An object containing the data for the trade card.
   * @param {object=} cardDatum.counterPartyUser Counterparty.
   * @param {object=} cardDatum.counterPartyProperty Counterparty's property (if visible).
   * @param {object=} cardDatum.property property object.
   * @param {object=} cardDatum.tradeType trade type for the card.
   * @returns {React.ReactElement} The rendered trade card content.
   */
  const tradeViewCard = (cardDatum) => {
    const { tradeType } = cardDatum;
    const cardLabel = buildTradeViewCardLabel(intl, cardDatum, chartView);

    return tradeContent(tradeType, cardDatum, cardLabel);
  };

  return (
    <>
      <div className="mt-4 mb-4 card">
        <div className="card-body" ref={chartRef}>
          <div className="card-body d-flex justify-content-between">
            <div className="flex-fill justify-content-center order-2 order-xs-3 col-xs-12">
              <ChartControls
                buttons={controlOptionFunc()}
                onButtonClick={(opts) => { controlSetStateFunc(opts); }}
              />
            </div>
            <div className="flex-fill order-1 order-xs-1 col-xs-12">
              <ChartSummary
                align="left"
                category={IMPORTS}
                carbonData={totalCarbonEmission}
                counterfactual={buySummary.netDiffValue}
                hasCounterfactual={hasCounterfactual}
                mode={mode}
                tradedEnergy={buySummary.tradedEnergy}
                tradedValue={buySummary.tradedValue}
                untradedEnergy={buySummary.untradedVolume}
                untradedValue={buySummary.untradedValue}
                rebatedEnergy={buySummary.rebatedEnergy}
              />
            </div>
            <div className="flex-fill order-3 order-xs-2 col-xs-12">
              <ChartSummary
                align="right"
                category={EXPORTS}
                counterfactual={sellSummary.netDiffValue}
                hasCounterfactual={hasCounterfactual}
                mode={mode}
                tradedEnergy={sellSummary.tradedEnergy}
                tradedValue={sellSummary.tradedValue}
                untradedEnergy={sellSummary.untradedVolume}
                untradedValue={sellSummary.untradedValue}
                rebatedEnergy={sellSummary.rebatedEnergy}
              />
            </div>
          </div>
          {tradeView && (
            <ChartTrade
              aggregation={tradeAggregation}
              chartView={chartView}
              handleChartClick={() => handleChartClick()}
              hoverKeys={hoverKeys}
              selectedKeys={selectedKeys}
              timezone={TIME_ZONE_SYSTEM}
              tooltipLabelFunc={tradeChartTooltip}
              tooltipTimestamp={tooltipTimestamp}
              tooltipUpdate={(timestamp) => setTooltipTimestamp(timestamp)}
              tradeDataBuy={chartData.buy}
              tradeDataSell={chartData.sell}
              unit={unit}
              width={chartSize.width}
            />
          )}
          {meterView && (
            <ChartMeter
              aggregation={historianAggregation}
              chartView={chartView}
              data={chartData}
              handleChartClick={() => handleChartClick()}
              hoverKeys={hoverKeys}
              isByMeter={aggByProperty}
              selectedKeys={selectedKeys}
              tooltipLabelFunc={meterChartTooltip}
              tooltipTimestamp={tooltipTimestamp}
              tooltipUpdate={(timestamp) => setTooltipTimestamp(timestamp)}
              unit={unit}
              width={chartSize.width}
            />
          )}
        </div>
      </div>
      {(meterView && cardsData.length > 0) && (
        <Cards
          cardFunc={meterViewCard}
          data={cardsData}
          hoverFunc={cardsHoverFunc}
          hoverKeys={hoverKeys}
          selectedFunc={cardsSelectedFunc}
          selectedKeys={selectedKeys}
        />
      )}
      {(tradeView && cardsData.length > 0) && (
        <Cards
          cardFunc={tradeViewCard}
          data={cardsData}
          hoverFunc={cardsHoverFunc}
          hoverKeys={hoverKeys}
          selectedFunc={cardsSelectedFunc}
          selectedKeys={selectedKeys}
        />
      )}
    </>
  );
}

PortfolioShowChart.propTypes = {
  chartView: PropTypes.shape({
    aggregateBy: PropTypes.oneOf([
      DATA_AGGREGATE_BY_PORTFOLIO,
      DATA_AGGREGATE_BY_PROPERTY,
    ]).isRequired,
    groupBy: PropTypes.oneOf([
      DATA_GROUP_BY_COUNTERPARTY,
      DATA_GROUP_BY_TRADE_TYPE,
    ]).isRequired,
  }).isRequired,
  controlSetStateFunc: PropTypes.func.isRequired,
  historianAggregation: PropTypes.string.isRequired,
  mainData: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  source: PropTypes.oneOf([SOURCE_HISTORIAN, SOURCE_TRADES]).isRequired,
  timespanUpdateFunc: PropTypes.func.isRequired,
  tradeAggregation: PropTypes.string.isRequired,
  unit: PropTypes.oneOf([UNIT_CARBON, UNIT_CURRENCY, UNIT_ENERGY]).isRequired,
};

export default PortfolioShowChart;
