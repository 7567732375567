import { Link } from 'found';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, FormattedPlural, injectIntl } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';
import {
  Badge, Card, CardBody, CardSubtitle, CardText, CardTitle, Col, Row,
} from 'reactstrap';

import Loading from 'src/components/Loading';
import { buyPriceInformation, sellPriceInformation } from 'src/components/TradeRule/TradeRuleHelpers';
import { APIConfig } from 'src/config';
import Time from 'src/enosikit/components/Time/components/Time';
import { emptyTradeRulesClassName, getTradeRuleMeterLabel } from 'src/helpers/tradeHelpers';
import {
  CONTRACTED, TRADE_RULE_STATE_ACCEPTED, TRADE_TYPE_CONTRACTED,
} from 'src/util/constants';
import username from 'src/util/decorators/username';
import { getStringAndNumericFormattedDate } from 'src/util/i18n/helpers';
import { tradeRulePriceRange } from 'src/util/tradeRule';

class TradeRuleActiveContracted extends React.Component {
  static tradeRulesForProperty(property) {
    const { meters } = property;
    const rules = { sell: [], buy: [] };

    meters.edges.forEach((meterEdge) => {
      if (!!meterEdge && !!meterEdge.node && !!meterEdge.node.contractedRules) {
        meterEdge.node.contractedRules.edges.forEach((ruleEdge) => {
          const { node: ruleNode } = ruleEdge;
          if (
            ruleNode.state !== TRADE_RULE_STATE_ACCEPTED
            || ruleNode.tradeType !== TRADE_TYPE_CONTRACTED
          ) {
            return;
          }

          if (ruleNode.seller.tradePoint.uuid === meterEdge.node.tradePointId) {
            rules.sell.push({ ...ruleNode, meter: meterEdge.node });
          } else {
            rules.buy.push({ ...ruleNode, meter: meterEdge.node });
          }
        });
      }
    });

    return rules;
  }

  render() {
    if (this.error) {
      return <div><FormattedMessage id="error.title" defaultMessage="Error!" /></div>;
    }
    if (!this.props) {
      return <Loading />;
    }

    const { intl, property } = this.props;
    const { uuid: propertyId } = property;
    const { formatMessage } = intl;
    const tradeRules = TradeRuleActiveContracted.tradeRulesForProperty(property);
    const noTradeRulesClass = emptyTradeRulesClassName(tradeRules, CONTRACTED);
    const singleTrade = formatMessage({ id: 'trade_rule.trade_rule_active.trade_rule_active_contracted.trade.singular', defaultMessage: 'contracted trade rule' });
    const multipleTrade = formatMessage({ id: 'trade_rule.trade_rule_active.trade_rule_active_contracted.trade.multiple', defaultMessage: 'contracted trade rules' });
    const buyRulesCount = tradeRules?.buy?.length || 0;
    const sellRulesCount = tradeRules?.sell?.length || 0;

    return (
      <Card className={`mt-4 mb-4 trade-rules-contracted${noTradeRulesClass}`}>
        <CardBody>
          <h2><FormattedMessage id="trade_rule.trade_rule_active.contracted.title" defaultMessage="Contracted trades" /></h2>
          <p>
            <FormattedMessage
              id="trade_rule.trade_rule_active.contracted.help_text"
              defaultMessage="You have agreed to buy your energy from or sell your energy to one or more suppliers directly first as a contracted trade."
            />

          </p>

          <Row>
            <Col xs="12" sm="12" md="6">
              <h3><FormattedMessage id="trade_rule.trade_rule_active.trade_rule_active_contracted.selling.title" defaultMessage="Selling" /></h3>
              <p>
                <FormattedMessage
                  id="trade_rule.trade_rule_active.trade_rule_active_contracted.sell.count_details"
                  defaultMessage="You have {tradeRulesCount} {tradeRulesCountLabel} to sell energy."
                  values={{
                    tradeRulesCount: <strong>{sellRulesCount}</strong>,
                    tradeRulesCountLabel: <FormattedPlural
                      value={sellRulesCount}
                      one={singleTrade}
                      other={multipleTrade}
                    />,
                  }}
                />
              </p>
              {tradeRules.sell && tradeRules.sell.length > 0 && tradeRules.sell.map((rule) => {
                const {
                  acceptedAt, acceptedBy, id: ruleId, meter, proposedAt, proposedBy, seller,
                } = rule;
                const { title } = meter;
                const priceRange = tradeRulePriceRange(rule);
                const { maximum, minimum } = priceRange;

                return (
                  <Card className="mt-4 mb-4" key={`trade-rules-${ruleId}`}>
                    <CardBody>
                      <CardTitle tag="h4">
                        {title}
                      </CardTitle>
                      <CardSubtitle tag="h5" className="mb-2">
                        <FormattedMessage id="trade_rule.trade_rule_active.trade_rule_active_contracted.sell.approved.title" defaultMessage="Approved" />
                      </CardSubtitle>
                      <CardText>
                        <FormattedMessage
                          id="trade_rule.trade_rule_active.trade_rule_active_contracted.sell.rule.meter"
                          defaultMessage="Selling from {meterLabel}"
                          values={{
                            meterLabel: getTradeRuleMeterLabel(seller),
                          }}
                        />
                        <br />
                        <FormattedMessage
                          id="trade_rule.trade_rule_active.trade_rule_active_contracted.sell.rule.proposed"
                          defaultMessage="Proposed by {actor} on {timestamp}"
                          values={{
                            actor: <strong>{username(proposedBy)}</strong>,
                            timestamp: <Time
                              child={getStringAndNumericFormattedDate(intl, proposedAt)}
                              unixTimestamp={proposedAt}
                            />,

                          }}
                        />
                        <br />
                        {acceptedAt && (
                          <FormattedMessage
                            id="trade_rule.trade_rule_active.trade_rule_active_contracted.sell.rule.accepted"
                            defaultMessage="Accepted by {actor} on {timestamp}"
                            values={{
                              actor: <strong>{username(acceptedBy)}</strong>,
                              timestamp: <Time
                                child={getStringAndNumericFormattedDate(intl, acceptedAt)}
                                unixTimestamp={acceptedAt}
                              />,
                            }}
                          />
                        )}
                        <br />
                        {
                          sellPriceInformation({
                            mode: APIConfig().MODE,
                            minimum,
                            maximum,
                            detailsLink: <Link to={`/properties/${propertyId}/trade-rules/${ruleId}`}><FormattedMessage id="trade_rule.trade_rule_active.trade_rule_active_contracted.buy.further_details" defaultMessage="further details" /></Link>,
                            units: <abbr title={formatMessage({ id: 'trade_rule.trade_rule_active.trade_rule_active_contracted.sell.energy_cost.abbr.title', defaultMessage: 'cents per kilowatt hour' })}><FormattedMessage id="trade_rule.trade_rule_active.trade_rule_active_contracted.sell.energy_cost.abbr.label" defaultMessage="c/kWh" /></abbr>,
                          })
                        }
                        <br />
                        <Link to={`/properties/${propertyId}/trade-rules/${ruleId}`}>
                          <Badge color="mid">{ruleId}</Badge>
                        </Link>
                      </CardText>
                    </CardBody>
                  </Card>
                );
              })}
            </Col>

            <Col xs="12" sm="12" md="6">
              <h3><FormattedMessage id="trade_rule.trade_rule_active.trade_rule_active_contracted.buying.title" defaultMessage="Buying" /></h3>
              <p>
                <FormattedMessage
                  id="trade_rule.trade_rule_active.trade_rule_active_contracted.buy.count_details"
                  defaultMessage="You have {tradeRulesCount} {tradeRulesCountLabel} to buy energy."
                  values={{
                    tradeRulesCount: <strong>{tradeRules.buy.length}</strong>,
                    tradeRulesCountLabel: <FormattedPlural
                      value={buyRulesCount}
                      one={singleTrade}
                      other={multipleTrade}
                    />,
                  }}
                />
              </p>

              {tradeRules.buy && tradeRules.buy.length > 0 && tradeRules.buy.map((rule) => {
                const {
                  acceptedAt, acceptedBy, buyer, uuid: ruleId, meter, proposedAt, proposedBy,
                } = rule;
                const { title } = meter;
                const priceRange = tradeRulePriceRange(rule);
                const { maximum, minimum } = priceRange;

                return (
                  <Card className="mt-4 mb-4" key={`trade-rules-${ruleId}`}>
                    <CardBody>
                      <CardTitle tag="h4">
                        {title}
                      </CardTitle>
                      <CardSubtitle tag="h5" className="mb-2">
                        <FormattedMessage id="trade_rule.trade_rule_active.trade_rule_active_contracted.buy.approved.title" defaultMessage="Approved" />
                      </CardSubtitle>
                      <CardText>
                        <FormattedMessage
                          id="trade_rule.trade_rule_active.trade_rule_active_contracted.buy.rule.meter"
                          defaultMessage="Buying for {meterLabel}"
                          values={{
                            meterLabel: getTradeRuleMeterLabel(buyer),
                          }}
                        />
                        <br />
                        <FormattedMessage
                          id="trade_rule.trade_rule_active.trade_rule_active_contracted.buy.rule.proposed"
                          defaultMessage="Proposed by {actor} on {timestamp}"
                          values={{
                            actor: <strong>{username(proposedBy)}</strong>,
                            timestamp: <Time
                              child={getStringAndNumericFormattedDate(intl, proposedAt)}
                              unixTimestamp={proposedAt}
                            />,

                          }}
                        />
                        <br />
                        {acceptedAt && (
                          <FormattedMessage
                            id="trade_rule.trade_rule_active.trade_rule_active_contracted.buy.rule.accepted"
                            defaultMessage="Accepted by {actor} on {timestamp}"
                            values={{
                              actor: <strong>{username(acceptedBy)}</strong>,
                              timestamp: <Time
                                child={getStringAndNumericFormattedDate(intl, acceptedAt)}
                                unixTimestamp={acceptedAt}
                              />,
                            }}
                          />
                        )}
                        <br />
                        {
                          buyPriceInformation({
                            mode: APIConfig().MODE,
                            minimum,
                            maximum,
                            detailsLink: <Link to={`/properties/${propertyId}/trade-rules/${ruleId}`}><FormattedMessage id="trade_rule.trade_rule_active.trade_rule_active_contracted.buy.further_details" defaultMessage="further details" /></Link>,
                            units: <abbr title={formatMessage({ id: 'trade_rule.trade_rule_active.trade_rule_active_contracted.buy.energy_cost.abbr.title', defaultMessage: 'cents per kilowatt hour' })}><FormattedMessage id="trade_rule.trade_rule_active.trade_rule_active_contracted.buy.energy_cost.abbr.label" defaultMessage="c/kWh" /></abbr>,
                          })
                        }
                        <br />
                        <Link
                          to={`/properties/${propertyId}/trade-rules/${ruleId}`}
                        >
                          <Badge color="mid">{ruleId}</Badge>
                        </Link>
                      </CardText>
                    </CardBody>
                  </Card>
                );
              })}
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  }
}

TradeRuleActiveContracted.propTypes = {
  property: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  intl: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  relay: PropTypes.shape({
    refetch: PropTypes.func,
  }).isRequired,
  router: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

TradeRuleActiveContracted.defaultProps = {
  property: null,
};

export default injectIntl(createFragmentContainer(
  TradeRuleActiveContracted,
  {
    property: graphql`
      fragment TradeRuleActiveContracted_property on Property {
        uuid
        meters {
          edges {
            node {
              uuid
              identifier
              title
              tradePointId
              contractedRules: rules(first: 500, type: TRADE_TYPE_CONTRACTED, state: TRADE_RULE_STATE_ACCEPTED) {
                edges {
                  node {
                    uuid
                    priority
                    tradeType
                    state
                    buyer {
                      userId
                      communityId
                      residualId
                      tradePoint {
                        uuid
                        meter {
                          uuid
                          identifier
                          title
                          property {
                            uuid
                            title
                          }
                        }
                      }
                    }
                    seller {
                      userId
                      communityId
                      residualId
                      tradePoint {
                        uuid
                        meter {
                          uuid
                          identifier
                          title
                          property {
                            uuid
                            title
                          }
                        }
                      }
                    }
                    clauses {
                      edges {
                        node {
                          price
                        }
                      }
                    }
                    start
                    finish
                    proposedAt
                    proposedBy {
                      uuid
                      email
                      givenName
                      familyName
                    }
                    acceptedAt
                    acceptedBy {
                      uuid
                      email
                      givenName
                      familyName
                    }
                  }
                }
              }
            }
          }
        }
      }
    `,
  },
));
