import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Select from 'react-select';
import {
  FormFeedback, FormGroup, Label,
} from 'reactstrap';

import { Days } from 'src/util/constants';
import { getDayNames } from 'src/util/i18n/constants';

/**
 * Description
 * @param {any} props
 * @returns {React.ReactComponentElement} - SelectDays component
 */
function SelectDays(props) {
  const {
    idPrefix, daysSelected, valid, handleDaysChange,
  } = props;
  const intl = useIntl();
  const dayNames = getDayNames(intl.formatMessage);
  const daysOptions = Days.map((day) => ({ value: day, label: dayNames[day] }));
  const daysValue = daysSelected.map((day) => ({ value: day, label: dayNames[day] }));

  const handleChange = (selected) => {
    handleDaysChange(selected.map((option) => option.value));
  };

  return (
    <FormGroup>
      <Label for={`${idPrefix}DaysOfWeek`}>
        <FormattedMessage id="time_of_use.select_days.form.days_of_week.label" defaultMessage="Days of week" />
      </Label>
      <Select
        isMulti
        name="daysOfWeek"
        id={`${idPrefix}DaysOfWeek`}
        options={daysOptions}
        value={daysValue}
        onChange={handleChange}
        className={valid === false ? 'is-invalid' : ''}
        noOptionsMessage={() => intl.formatMessage({ id: 'time_of_use.select_days.form.days_of_week.no_options.message', defaultMessage: 'No options' })}
      />
      <FormFeedback>
        <FormattedMessage id="time_of_use.select_days.form.days_of_week.form_feedback.invalid" defaultMessage="Invalid days of week" />
      </FormFeedback>
    </FormGroup>
  );
}

SelectDays.propTypes = {
  idPrefix: PropTypes.string.isRequired,
  daysSelected: PropTypes.arrayOf(PropTypes.oneOf(Days)).isRequired,
  valid: PropTypes.bool,
  handleDaysChange: PropTypes.func.isRequired,
};

SelectDays.defaultProps = {
  valid: null,
};

export default SelectDays;
