import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';
import { graphql, useFragment } from 'react-relay';
import { Col, Row } from 'reactstrap';

import Loading from 'src/components/Loading';
import Breadcrumbs from 'src/enosikit/components/Breadcrumbs';

import PropertyMemberDetailsCard from './PropertyMemberDetailsCard';
import PropertyMemberPortfolioCard from './PropertyMemberPortfolioCard';
import PropertyMemberPropertyCard from './PropertyMemberPropertyCard';

const AdminPortfolioPropertyMemberShowFragment = graphql`
  fragment AdminPortfolioPropertyMemberShow_portfolioPropertyMember on PortfolioPropertyMember {
    uuid
    externalIdentifier
    active { start finish }
    portfolio {
      uuid
      externalIdentifier
      title
      active {
        start
        finish
      }
    }
    property {
      uuid
      externalIdentifier
      title
      address {
        line1
        line2
        city
        state
        country
        postcode
      }
      active {
        start
        finish
      }
    }
    createdAt
    updatedAt
  }
`;

/**
 * Description
 * @param {object} props
 * @returns {React.ReactElement} - AdminPortfolioPropertyMemberShow component
 */
function AdminPortfolioPropertyMemberShow(props) {
  if (!props) {
    return <Loading />;
  }

  const portfolioPropertyMember = useFragment(
    AdminPortfolioPropertyMemberShowFragment,
    props.portfolioPropertyMember, // eslint-disable-line react/destructuring-assignment
  );
  const { property, portfolio } = portfolioPropertyMember || {};
  const { title } = property || {};

  const intl = useIntl();
  const pageTitle = intl.formatMessage({ id: 'admin.admin_portfolio_property_member.admin_portfolio_property_member_show.page.title', defaultMessage: 'Enosi Admin - Portfolio Property Member - {title}' }, { title });
  const breadcrumbsAdmin = intl.formatMessage({ id: 'admin.admin_portfolio_user_member.admin_portfolio_user_member_show.breadcrumbs.admin', defaultMessage: 'Admin' });
  const breadcrumbsPortfolioUserMembers = intl.formatMessage({ id: 'admin.admin_portfolio_user_member.admin_portfolio_user_member_show.breadcrumbs.portfolio_property_members', defaultMessage: 'Portfolio Property Members' });
  const breadcrumbsPortfolioUserMember = intl.formatMessage({ id: 'admin.admin_portfolio_user_member.admin_portfolio_user_member_show.breadcrumbs.portfolio_property_member', defaultMessage: 'Portfolio Property Member: {title}' }, { title });

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{pageTitle}</title>
      </Helmet>

      <Breadcrumbs
        breadcrumbs={[
          { name: breadcrumbsAdmin, path: '/admin' },
          { name: breadcrumbsPortfolioUserMembers, path: '/admin/portfolio-user-members' },
          { name: breadcrumbsPortfolioUserMember },
        ]}
      />

      <h1>{title}</h1>

      <Row className="clear-both">
        <Col xs={12} sm={6} md={4}>
          <PropertyMemberDetailsCard portfolioPropertyMember={portfolioPropertyMember} />
        </Col>

        <Col xs={12} sm={6} md={8}>
          <PropertyMemberPortfolioCard portfolio={portfolio} />
          <PropertyMemberPropertyCard property={property} />
        </Col>
      </Row>
    </>
  );
}

AdminPortfolioPropertyMemberShow.propTypes = {
  portfolioPropertyMember: PropTypes.shape({
    id: PropTypes.string.isRequired,
    externalIdentifier: PropTypes.string.isRequired,
    active: PropTypes.shape({
      start: PropTypes.number.isRequired,
      finish: PropTypes.number,
    }),
    portfolio: PropTypes.shape({
      id: PropTypes.string.isRequired,
      externalIdentifier: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      active: PropTypes.shape({
        start: PropTypes.number.isRequired,
        finish: PropTypes.number,
      }),
    }),
    property: PropTypes.shape({
      id: PropTypes.string.isRequired,
      externalIdentifier: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      address: PropTypes.shape({
        line1: PropTypes.string.isRequired,
        line2: PropTypes.string.isRequired,
        city: PropTypes.string.isRequired,
        state: PropTypes.string.isRequired,
        country: PropTypes.string.isRequired,
        postcode: PropTypes.string.isRequired,
      }),
      active: PropTypes.shape({
        start: PropTypes.number.isRequired,
        finish: PropTypes.number,
      }),
    }),
    role: PropTypes.string.isRequired,
    createdAt: PropTypes.number.isRequired,
    updatedAt: PropTypes.number.isRequired,
  }),
};

AdminPortfolioPropertyMemberShow.defaultProps = {
  portfolioPropertyMember: null,
};

export default AdminPortfolioPropertyMemberShow;
