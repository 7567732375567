import Link from 'found/Link';
import PropTypes from 'prop-types';
import React from 'react';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';

class Breadcrumbs extends React.Component {
  static breadcrumbItem(breadcrumb, i) {
    let link;
    if (breadcrumb.path) {
      link = (<Link to={breadcrumb.path} key={`breadcrumb-${i}`}>{breadcrumb.name}</Link>);
    } else {
      link = breadcrumb.name;
    }
    return (
      <BreadcrumbItem key={`breadcrumb-${i}`}>
        {link}
      </BreadcrumbItem>
    );
  }

  render() {
    const { breadcrumbs } = this.props;

    return (
      <Breadcrumb key="breadcrumbs">
        { breadcrumbs.map((breadcrumb, i) => Breadcrumbs.breadcrumbItem(breadcrumb, i)) }
      </Breadcrumb>
    );
  }
}

Breadcrumbs.propTypes = {
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element,
      ]),
      path: PropTypes.string,
    }),
  ).isRequired,
};

export default Breadcrumbs;
