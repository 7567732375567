import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import { TariffInfo } from 'src/components/Tariff';
import { activeTariffs } from 'src/util/meterTariff';

/**
 * Description
 * @param {any} property
 * @returns {React.ReactComponentElement} - PropertySettingsMeterTariff component
 */
function PropertySettingsMeterTariff({ property }) {
  const { meters } = property;
  return (
    <div className="property-active-tariffs">
      {meters.edges.map((el) => el.node).map((meter) => (
        <Fragment key={meter.uuid}>
          <hr />
          <div>
            <h3>
              {meter.title}
              <br />
              <small>{meter.identifier}</small>
            </h3>
            {meter.tariffs && meter.tariffs.edges
              && activeTariffs(meter.tariffs.edges).map((tariff) => (
                <TariffInfo tariff={tariff} key={tariff.uuid} />
              ))}
          </div>
        </Fragment>
      ))}
    </div>
  );
}

PropertySettingsMeterTariff.propTypes = {
  property: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

PropertySettingsMeterTariff.defaultProps = {
  property: null,
};

export default createFragmentContainer(
  PropertySettingsMeterTariff,
  {
    property: graphql`
      fragment PropertySettingsMeterTariff_property on Property {
        meters(first:500) {
          edges {
            node {
              uuid
              identifier
              title
              tariffs(first:500) {
                edges {
                  node {
                    uuid
                    identifier
                    tariffType
                    title
                    active { start finish }
                    ...TariffInfo_tariff
                  }
                }
              }
            }
          }
        }
      }
    `,
  },
);
