import Link from 'found/Link';
import PropTypes from 'prop-types';
import React from 'react';
import { Table } from 'reactstrap';

import BadgeActive from 'src/components/Badge/BadgeActive';
import BadgeTradePointType from 'src/components/Badge/BadgeTradePointType';
import Loading from 'src/components/Loading';
import UUID from 'src/components/UUID';
import username from 'src/util/decorators/username';

/**
 * Description
 * @param {object} props
 * @returns {React.ReactElement} - AdminTradeRuleListTrader component
 */
function AdminTradeRuleListTrader(props) {
  if (!props) {
    return <Loading />;
  }

  const { trader } = props;
  const { tradePoint, community, user } = trader;
  const { uuid: tradePointId, type: tradePointType } = tradePoint;

  const tradePointBadge = <BadgeTradePointType type={tradePointType} />;

  if (user) {
    const { uuid, email, active } = user;

    return (
      <Table responsive>
        <thead>
          <tr>
            <th scope="col">Type</th>
            <th scope="col">Trade Point</th>
            <th scope="col">User</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              {tradePointBadge}
            </td>
            <th scope="row">
              <UUID uuid={tradePointId} />
            </th>
            <td>
              <Link to={`/admin/users/${uuid}`}>
                {username(user)}
              </Link>
              <br />
              {email}
              <br />
              <BadgeActive start={active.start} finish={active.finish} />
            </td>
          </tr>
        </tbody>
      </Table>
    );
  }

  if (community) {
    const { uuid, title, active } = community;

    return (
      <Table responsive>
        <thead>
          <tr>
            <th scope="col">Type</th>
            <th scope="col">Trade Point</th>
            <th scope="col">Community</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              {tradePointBadge}
            </td>
            <th scope="row">
              <UUID uuid={tradePointId} />
            </th>
            <td>
              <Link to={`/admin/communities/${uuid}`}>
                {title}
              </Link>
              <br />
              <BadgeActive start={active.start} finish={active.finish} />
            </td>
          </tr>
        </tbody>
      </Table>
    );
  }

  return (
    <Table responsive>
      <thead>
        <tr>
          <th scope="col">Type</th>
          <th scope="col">Trade Point</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            {tradePointBadge}
          </td>
          <th scope="row">
            <UUID uuid={tradePointId} />
          </th>
        </tr>
      </tbody>
    </Table>
  );
}

AdminTradeRuleListTrader.propTypes = {
  trader: PropTypes.shape({
    tradePoint: PropTypes.shape({
      uuid: PropTypes.string,
      type: PropTypes.string,
    }),
    community: PropTypes.shape({
      uuid: PropTypes.string,
      title: PropTypes.string,
      active: PropTypes.shape({
        start: PropTypes.number,
        finish: PropTypes.number,
      }),
    }),
    user: PropTypes.shape({
      uuid: PropTypes.string,
      email: PropTypes.string,
      givenName: PropTypes.string,
      familyName: PropTypes.string,
      active: PropTypes.shape({
        start: PropTypes.number,
        finish: PropTypes.number,
      }),
    }),
  }),
};

AdminTradeRuleListTrader.defaultProps = {
  trader: null,
};

export default AdminTradeRuleListTrader;
