import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const Image = styled.img.attrs({
  className: 'rounded-circle',
})`
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  object-fit: cover;
`;

/**
 * Description
 * @param {any} props
 * @returns {React.ReactComponentElement} - AvatarImage component
 */
function AvatarImage(props) {
  const { image, size, username } = props;

  return (
    <Image src={image} size={size} alt={username} />
  );
}

AvatarImage.propTypes = {
  image: PropTypes.string.isRequired,
  size: PropTypes.string,
  username: PropTypes.string,
};

AvatarImage.defaultProps = {
  size: '50px',
  username: null,
};

export default AvatarImage;
