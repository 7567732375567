import { config } from '@fortawesome/fontawesome-svg-core';
import { faBell, faCog, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Link from 'found/Link';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Collapse, Container, Dropdown, DropdownItem, DropdownMenu, DropdownToggle,
  Nav, NavItem, Navbar, NavbarBrand, NavbarToggler,
} from 'reactstrap';
import styled from 'styled-components';

import AvatarImage from '../../Avatar/components/AvatarImage';
import AvatarItem from '../../Avatar/components/AvatarItem';
import Logo from '../../Logo/components/Logo';
import Navigation from '../../Navigation/components/Navigation';

// Fontawesome CSS needs to be loaded?
config.autoAddCss = true;

const RightNav = styled(Nav).attrs({
  className: 'ms-auto',
})``;

const LeftNav = styled(Nav).attrs({
  className: 'me-auto',
})``;

class NavigationBar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      navbarIsOpen: false,
      profileDropdownOpen: false,
    };
  }

  toggleNavbar = () => {
    this.setState((prevState) => ({ navbarIsOpen: !prevState.navbarIsOpen }));
  };

  toggleProfile = () => {
    this.setState((prevState) => ({ profileDropdownOpen: !prevState.profileDropdownOpen }));
  };

  render() {
    const {
      properties, messageCount, user, selectedPropertyId,
    } = this.props;

    const { navbarIsOpen, profileDropdownOpen } = this.state;

    return (
      <Navbar light expand="lg">
        <Container className="d-flex flex-wrap justify-content-between px-0 px-sm-3">
          <NavbarBrand>
            <Link to="/">
              <Logo image="images/branding/enosi-logo.svg" size="130px" />
            </Link>
          </NavbarBrand>
          <NavbarToggler onClick={this.toggleNavbar} />
          <Collapse isOpen={navbarIsOpen} navbar>
            <LeftNav navbar>
              <Navigation
                properties={properties}
                selectedPropertyId={selectedPropertyId}
              />
            </LeftNav>
            <RightNav navbar>
              <NavItem>
                <Link to="/notifications" className="nav-link">
                  <span className="fa-layers fa-fw" style={{ fontSize: '1.5rem', marginTop: '0.6rem' }}>
                    <FontAwesomeIcon icon={faBell} />
                    <span className="fa-layers-counter danger fw-bold" style={{ fontSize: '2.5rem', paddingTop: '.8rem' }}>{messageCount}</span>
                  </span>
                </Link>
              </NavItem>
              <Dropdown nav isOpen={profileDropdownOpen} toggle={this.toggleProfile}>
                <DropdownToggle nav>
                  <AvatarItem
                    primaryText={user.name}
                    secondaryText={user.email}
                    avatarImage={<AvatarImage image={user.avatar} username={user.name} size="45px" />}
                  />
                </DropdownToggle>
                <DropdownMenu>
                  <Link to="/user/settings" activeClassName="active" className="dropdown-item" role="menuitem">
                    <FontAwesomeIcon icon={faCog} className="me-2" />
                    <FormattedMessage id="navigation.user.settings" defaultMessage="Profile settings" />
                  </Link>
                  <DropdownItem divider />
                  <Link to="/authentication/logout" activeClassName="active" className="dropdown-item" role="menuitem">
                    <FontAwesomeIcon icon={faSignOutAlt} className="me-2" />
                    <FormattedMessage id="navigation.user.logout" defaultMessage="Log out" />
                  </Link>
                </DropdownMenu>
              </Dropdown>
            </RightNav>
          </Collapse>
        </Container>
      </Navbar>
    );
  }
}

NavigationBar.propTypes = {
  properties: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string,
      title: PropTypes.string,
      active: PropTypes.shape({
        start: PropTypes.string,
        finish: PropTypes.string,
      }),
    }),
  ),
  selectedPropertyId: PropTypes.string,
  messageCount: PropTypes.number,
  user: PropTypes.shape({
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    avatar: PropTypes.string.isRequired,
  }).isRequired,
};

NavigationBar.defaultProps = {
  properties: [],
  selectedPropertyId: '',
  messageCount: 0,
};

export default NavigationBar;
