import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Tooltip } from 'reactstrap';

const idSuffix = Math.random().toString(36).slice(2, -1).toUpperCase();

/**
 * Description
 * @param {any} uuid - UUID
 * @returns {any} - UUID component
 */
function UUID({ uuid }) {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const shortUUID = uuid?.split('-')[0];
  const id = `UUID-${uuid}-${idSuffix}`;
  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <>
      <abbr title={uuid} id={id}>
        {shortUUID}
      </abbr>
      <Tooltip placement="auto" isOpen={tooltipOpen} target={id} toggle={toggle} boundariesElement="window">
        {uuid}
      </Tooltip>
    </>
  );
}

UUID.propTypes = {
  uuid: PropTypes.string.isRequired,
};

export default UUID;
