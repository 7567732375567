import { Link } from 'found';
import PropTypes from 'prop-types';
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import BadgeTradeDirection from 'src/components/Badge/BadgeTradeDirection';
import BadgeTradePointType from 'src/components/Badge/BadgeTradePointType';
import BadgeTradeRuleState from 'src/components/Badge/BadgeTradeRuleState';
import BadgeTradeType from 'src/components/Badge/BadgeTradeType';
import Loading from 'src/components/Loading';
import UUID from 'src/components/UUID';
import {
  TRADE_DIRECTION_BUY, TRADE_DIRECTION_SELL, TRADE_DIRECTION_UNSPECIFIED,
  TRADE_POINT_TYPE_COMMUNITY, TRADE_POINT_TYPE_KNOWN, TRADE_POINT_TYPE_RESIDUAL,
} from 'src/util/constants';
import username from 'src/util/decorators/username';
import { tradeRuleClauseConditions } from 'src/util/tradeRule';

import AdminMeterTradeRulesItemClause from './AdminMeterTradeRulesItemClause';
import AdminMeterTradeRulesItemTimeRange from './AdminMeterTradeRulesItemTimeRange';

class AdminMeterTradeRulesItem extends React.Component {
  static clauseConditions(rule) {
    const conditions = tradeRuleClauseConditions(rule);
    if (conditions.length === 0) {
      return { element: null, display: false };
    }

    return {
      element: (
        <li>
          All
          {' '}
          {conditions.reduce((prev, curr) => [prev, ', ', curr])}
        </li>
      ),
      display: true,
    };
  }

  static counterPartyTrader(tradePointId, rule) {
    const direction = this.direction(tradePointId, rule);

    let trader;
    if (direction === TRADE_DIRECTION_BUY) {
      trader = rule.seller;
    } else if (direction === TRADE_DIRECTION_SELL) {
      trader = rule.buyer;
    } else {
      return null;
    }

    if (trader.user) {
      return trader.user;
    }
    if (trader.community) {
      return trader.community;
    }
    if (trader.residual) {
      return trader.residual;
    }

    return null;
  }

  static counterPartyTradePoint(tradePointId, rule) {
    const direction = this.direction(tradePointId, rule);

    let tradePoint;
    if (direction === TRADE_DIRECTION_BUY) {
      tradePoint = rule.seller.tradePoint;
    } else if (direction === TRADE_DIRECTION_SELL) {
      tradePoint = rule.buyer.tradePoint;
    } else {
      return null;
    }

    if (tradePoint.type === TRADE_POINT_TYPE_KNOWN) {
      return { label: 'Trade Point', tradePoint };
    }
    if (tradePoint.type === TRADE_POINT_TYPE_COMMUNITY) {
      return { label: 'Community', tradePoint };
    }
    if (tradePoint.type === TRADE_POINT_TYPE_RESIDUAL) {
      return { label: 'Retailer', tradePoint };
    }

    return null;
  }

  static direction(tradePointId, rule) {
    const { buyer, seller } = rule;

    let buyerTradePointId = null;
    let sellerTradePointId = null;

    if (buyer.tradePoint && buyer.tradePoint.uuid) {
      buyerTradePointId = buyer.tradePoint.uuid;
    }
    if (seller.tradePoint && seller.tradePoint.uuid) {
      sellerTradePointId = seller.tradePoint.uuid;
    }

    if (buyerTradePointId && buyerTradePointId === tradePointId) {
      return TRADE_DIRECTION_BUY;
    }
    if (sellerTradePointId && sellerTradePointId === tradePointId) {
      return TRADE_DIRECTION_SELL;
    }
    return TRADE_DIRECTION_UNSPECIFIED;
  }

  render() {
    if (this.error) {
      return <div>Error!</div>;
    }
    if (!this.props) {
      return <Loading />;
    }

    const { propertyId, tradePointId, rule } = this.props;

    const {
      uuid, tradeType, state, start, finish,
    } = rule;

    const clauses = (!rule.clauses || !rule.clauses.edges)
      ? [] : rule.clauses.edges.map((el) => (el.node)).sort((a, b) => (b.price - a.price));

    const clauseConditions = AdminMeterTradeRulesItem.clauseConditions(rule);

    // eslint-disable-next-line max-len -- conflict between max-len and function-paren-newline.
    const counterPartyTradePoint = AdminMeterTradeRulesItem.counterPartyTradePoint(tradePointId, rule);
    const counterPartyTrader = AdminMeterTradeRulesItem.counterPartyTrader(tradePointId, rule);

    return (
      <tr key={`trade-rule-${uuid}`}>
        <th>
          <Link to={`/properties/${propertyId}/trade-rules/${uuid}`}>
            <UUID uuid={uuid} />
          </Link>
          <br />
          <BadgeTradeDirection
            direction={AdminMeterTradeRulesItem.direction(tradePointId, rule)}
          />
          <br />
          <BadgeTradeType type={tradeType} />
          <br />
          <BadgeTradeRuleState state={state} />
        </th>
        <td>
          {counterPartyTrader && (
            <>
              <strong>{username(counterPartyTrader)}</strong>
              <br />
              <UUID uuid={counterPartyTrader.uuid} />
            </>
          )}
        </td>
        <td>
          {counterPartyTradePoint && (
            <>
              <strong>{counterPartyTradePoint.label}</strong>
              <br />
              <UUID uuid={counterPartyTradePoint.tradePoint.uuid} />
              <br />
              <BadgeTradePointType type={counterPartyTradePoint.tradePoint.type} />
            </>
          )}
        </td>
        <td>
          <ul className="ps-2">
            {clauses && clauses.map((clause) => (
              <AdminMeterTradeRulesItemClause
                clause={clause}
                key={clause.uuid}
                showConditions={!clauseConditions.display}
              />
            ))}
            {clauseConditions.display && clauseConditions.element}
          </ul>
        </td>
        <td>
          <AdminMeterTradeRulesItemTimeRange start={start} finish={finish} />
        </td>
      </tr>
    );
  }
}

AdminMeterTradeRulesItem.propTypes = {
  propertyId: PropTypes.string.isRequired,
  rule: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  tradePointId: PropTypes.string.isRequired,
};

export default createFragmentContainer(
  AdminMeterTradeRulesItem,
  {
    rule: graphql`
      fragment AdminMeterTradeRulesItem_rule on TradeRule {
        uuid
        buyer {
          user { uuid email givenName familyName }
          community { uuid title }
          residualId
          tradePoint { uuid type }
        }
        seller {
          user { uuid email givenName familyName }
          community { uuid title }
          residualId
          tradePoint { uuid type }
        }
        clauses {
          edges {
            node {
              uuid
              price
              ignoreDaylightSavings
              ignorePublicHolidays
              timezone
              ...AdminMeterTradeRulesItemClause_clause
            }
          }
        }
        tradeType
        state
        start
        finish
        proposedAt
        proposedBy { uuid email givenName familyName }
        acceptedAt
        acceptedBy { uuid email givenName familyName }
        rejectedAt
        rejectedBy { uuid email givenName familyName }
        cancelledAt
        cancelledBy { uuid email givenName familyName }
        closedAt
        closedBy { uuid email givenName familyName }
      }
    `,
  },
);
