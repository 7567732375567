import {
  SpanKind, SpanStatusCode, context, trace,
} from '@opentelemetry/api';
import { Auth } from 'aws-amplify';
import {
  Environment, Network, RecordSource, Store,
} from 'relay-runtime';

import { APIConfig } from 'src/config';
import { tracingState, withTracingOptions } from 'src/tracing';
import { shutdownIntercom } from 'src/util/intercom';

/**
 * Update intercom if there is a route change in the application
 *
 */
const handleIntercom = () => {
  if (window.Intercom) {
    window.Intercom('update');
  }
};

/**
 * Helper function to get the current session, with tracing
 * @returns {object} - session object
 */
export function getCurrentSession() {
  return withTracingOptions('Auth.currentSession', { kind: SpanKind.CLIENT }, () => Auth.currentSession()
    .then((sess) => {
      console.log({ sess });
      return sess;
    })
    .catch((e) => {
      const span = trace.getSpan(context.active());
      console.error('getCurrentSession error', e, span);
      if (span) {
        span.recordException(e);
        span.setStatus({
          code: SpanStatusCode.ERROR,
          message: (e && (e.stack || e.message)) || String(e),
        });
      }
      const { pathname, search } = window.location;
      shutdownIntercom();
      if (!APIConfig().LOCAL) {
        window.location.replace(`/login?redirect=${pathname}${search}`);
      }
    }));
}

/**
 * Wrap the function that fetches the results of an operation (query/mutation/etc)
 * and returns its results as a Promise using a span
 * @param {object} operation - (query/mutation/etc)
 * @param {object} variables - query variables
 * @returns {object} - api response object
 */
function fetchQueryRaw(
  operation,
  variables,
  // cacheConfig,
  // uploadables,
) {
  let token;
  return getCurrentSession()
    .then((sess) => {
      if (sess) {
        token = sess.getAccessToken().getJwtToken();
        // // Another, alternative hacky way to get the user if
        // if (sess.idToken && sess.idToken.payload && sess.idToken.payload['custom:id']) {
        //   span.setAttribute('user_id', sess.idToken.payload['custom:id']);
        // }
        const req = {
          method: 'POST',
          headers: {
            'content-type': 'application/json',
          },
          body: JSON.stringify({
            query: operation.text, // GraphQL text from input
            variables,
          }),
        };
        // Add authentication
        if (token) {
          req.headers.Authorization = `Bearer ${token}`;
        }

        let url = APIConfig().GRAPH_URL;
        if (!url) {
          // TODO: get the base rule and concatenate
          url = 'http://0.0.0.0:5678/api/v2';
        }

        handleIntercom();

        let status;
        return fetch(url, req)
          .then((response) => {
            status = response.status;
            return response.json();
          })
          .then((response) => {
            const span = trace.getSpan(context.active());
            if (status >= 400) {
              if (span) {
                span.setStatus({ code: SpanStatusCode.ERROR, message: 'error getting json' });
              }
              return {};
            }
            const { data, errors } = response;
            if (errors) {
              if (span) {
                span.recordException(errors);
                span.setStatus({ code: SpanStatusCode.ERROR, message: 'fetch error' });
              }
              if (Object.keys(data).reduce((acc, d) => (acc && (data[d] === null)), true)) {
                return { data: null, errors };
              }
            }
            // A bit of a hack to populate the tracing state as early as possible
            if (data && data.viewer && data.viewer.viewerUser) {
              tracingState.setUserID(data.viewer.viewerUser.id);
            }
            return { data, errors };
          })
          .catch((error) => {
            console.error('fetchQueryRaw.fetch error', error);
            const span = trace.getSpan(context.active());
            if (span) {
              span.recordException(error);
              span.setStatus({ code: SpanStatusCode.ERROR, message: 'fetch error' });
            }
          });
      }
      return sess;
    })
    .catch((error) => {
      console.error('fetchQueryRaw.getCurrentSession error', error);
      const span = trace.getSpan(context.active());
      if (span) {
        span.recordException(error);
        span.setStatus({ code: SpanStatusCode.ERROR, message: 'getCurrentSession error' });
      }
      const { pathname, search } = window.location;
      if (!APIConfig().LOCAL) {
        window.location.replace(`/login?redirect=${pathname}${search}`);
      }
    });
}

/**
 * Wrap the function that fetches the results of an operation (query/mutation/etc)
 * and returns its results as a Promise using a span
 * @param {object} operation -  (query/mutation/etc)
 * @param {object} variables - query variables
 * @returns {object} - promise (using a span)
 */
export function fetchQuery(
  operation,
  variables,
  // cacheConfig,
  // uploadables,
) {
  return withTracingOptions('fetchQuery', { kind: SpanKind.CLIENT }, fetchQueryRaw, operation, variables);
}

/**
 * Description
 * @param {object} operation
 * @param {object} request
 * @param {Function} onCompleted
 * @param {Function} onError
 * @returns {object} - api response object
 */
export function fetchREST(operation, request, onCompleted, onError) {
  return withTracingOptions('fetchREST', { kind: SpanKind.CLIENT }, () => {
    const httpReq = {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      body: request,
    };
    const url = `${APIConfig().REST_BASE_URL}/${operation}`;
    const span = trace.getSpan(context.active());

    if (span) {
      span.setAttribute('operation', operation);
    }
    let status;
    fetch(url, httpReq)
      .then((response) => {
        status = response.status;
        return response.json();
      })
      .then((body) => {
        if (status >= 400) {
          if (span) {
            span.setStatus({ code: SpanStatusCode.ERROR, message: 'error getting json' });
          }
          onError(body);
        } else {
          if (span) {
            span.setStatus({ code: SpanStatusCode.OK });
          }
          onCompleted(body);
        }
      })
      .catch((error) => {
        console.log({ operation, url, error });
        if (span) {
          span.recordException(error);
          span.setStatus({ code: SpanStatusCode.ERROR, message: 'error getting json' });
        }
        onError(error);
      })
      .finally(() => {
        if (span) {
          tracingState.addToSpan(span)
            .then(() => span.end())
            .catch((e) => console.error('error adding to span', e));
        }
      });
  });
}

// Create a network layer from the fetch function
const network = Network.create(fetchQuery);
const store = new Store(new RecordSource());

const environment = new Environment({
  network,
  store,
  // ... other options
});

export default environment;
