import { commitMutation, graphql } from 'react-relay';

import environment from 'src/util/environment';

const mutation = graphql`
  mutation PrioritizeNominatedTradesMutation(
    $tradePointId: ID!
    $rules: [RuleWithPriority!]!
  ) {
    prioritizeNominatedTrades(
      tradePointId: $tradePointId
      rules: $rules
    ) {
      uuid
    }
  }
`;

const PrioritizeNominatedTradesMutation = (tradePointId, rules, onCompleted, onError) => {
  const variables = { tradePointId, rules };

  commitMutation(
    environment,
    {
      mutation,
      variables,
      onCompleted,
      onError,
    },
  );
};

export default PrioritizeNominatedTradesMutation;
