import { faBolt, faSolarPanel, faWind } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const Icon = styled(FontAwesomeIcon).attrs({
  className: 'ms-1 me-1',
})``;

const IconSet = styled.div``;

/**
 * Renders a set of icons representing the consumer, solar
 * and wind energy sources associated with a card.
 * @param {object} props - The component props.
 * @param {boolean} props.consumer - Whether the card represents a consumer energy source.
 * @param {boolean} props.solar - Whether the card represents a solar energy source.
 * @param {boolean} props.wind - Whether the card represents a wind energy source.
 * @returns {React.ReactComponentElement} - The rendered icon set.
 */
function ListCardMetadata(props) {
  const { consumer, solar, wind } = props;

  return (
    // TODO: Colour icons
    // - enosi-green for consumer
    // - enosi-yellow for solar
    // - enosi-blue (tba) for wind
    <IconSet>
      {consumer && <Icon icon={faBolt} />}
      {solar && <Icon icon={faSolarPanel} />}
      {wind && <Icon icon={faWind} />}
    </IconSet>
  );
}

ListCardMetadata.propTypes = {
  consumer: PropTypes.bool,
  solar: PropTypes.bool,
  wind: PropTypes.bool,
};

ListCardMetadata.defaultProps = {
  consumer: false,
  solar: false,
  wind: false,
};

export default ListCardMetadata;
