/**
 * @generated SignedSource<<e651269164a9a15e0de1fa3c8b511849>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TradeRuleShow_property",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "uuid",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TradeRuleTimeline_property"
    }
  ],
  "type": "Property",
  "abstractKey": null
};

node.hash = "b8a61425fa3079dc947960fe7f359791";

module.exports = node;
