import { Link } from 'found';
import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { FormattedMessage, injectIntl } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';
import { ButtonGroup, ButtonToolbar } from 'reactstrap';

import Address from 'src/components/Address';
import Loading from 'src/components/Loading';
import Breadcrumbs from 'src/enosikit/components/Breadcrumbs';
import Heading from 'src/enosikit/components/Heading';
import ProposeNominatedTradeMutation from 'src/mutations/ProposeNominatedTradeMutation';
import FlashesStore from 'src/stores/FlashesStore';

import TradeRuleProposeNominatedFlatForm from './TradeRuleProposeNominatedFlatForm';

class TradeRuleProposeNominatedFlat extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      processing: false,
    };
  }

  handleTradeRuleProposeNominated = (input) => {
    this.setState({ processing: true });
    FlashesStore.reset();
    ProposeNominatedTradeMutation(
      input,
      this.handleTradeRuleProposeNominatedSuccess,
      this.handleTradeRuleProposeNominatedFailure,
    );
  };

  handleTradeRuleProposeNominatedSuccess = (response, errors) => {
    this.setState({ processing: false });

    if (errors && errors.length > 0) {
      errors.forEach((error) => FlashesStore.flash(FlashesStore.ERROR, {
        message: FlashesStore.ERROR,
        source: {
          errors: [error],
        },
      }));
      return;
    }

    const { uuid } = response.proposeNominatedTrade;
    const { intl } = this.props;
    const successMsg = intl.formatMessage({ id: 'trade_rule.trade_rule_propose_nominated_flat.response_message.success', defaultMessage: 'A new peer-to-peer trade rule proposal has been sent with id {uuid}.' }, { uuid });

    FlashesStore.flash(
      FlashesStore.SUCCESS,
      successMsg,
    );

    const { router, property } = this.props;
    router.push(`/properties/${property.uuid}/trade-rules/active`);
  };

  handleTradeRuleProposeNominatedFailure = (error) => {
    this.setState({ processing: false });

    FlashesStore.flash(FlashesStore.ERROR, error);
  };

  renderPropertyControls = () => {
    const { property } = this.props;
    return (
      <ButtonToolbar>
        <ButtonGroup className="ms-2">
          <Link
            to={`/properties/${property.uuid}/trade-rules`}
            className="btn btn-darken"
          >
            <FormattedMessage id="trade_rule.trade_rule_propose_nominated_flat.all_trade_rules.label" defaultMessage="All trade rules" />
          </Link>
        </ButtonGroup>

        <ButtonGroup className="ms-2">
          <Link
            to={`/properties/${property.uuid}/settings`}
            className="btn btn-darken"
          >
            <FormattedMessage id="trade_rule.trade_rule_propose_nominated_flat.property_settings.label" defaultMessage="Property Settings" />
          </Link>
        </ButtonGroup>
      </ButtonToolbar>
    );
  };

  render() {
    if (this.error) {
      return <div><FormattedMessage id="error.title" defaultMessage="Error!" /></div>;
    }
    if (!this.props) {
      return <Loading />;
    }

    const { intl, property, router } = this.props;
    const { formatMessage } = intl;
    const { address, uuid, title } = property;
    const addr = new Address(address);

    const { processing } = this.state;
    const pageTitle = intl.formatMessage({ id: 'trade_rule.trade_rule_propose_nominated_flat.page.title', defaultMessage: 'Enosi - Property: {title} - Propose peer-to-peer trade rule' }, { title });

    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{pageTitle}</title>
        </Helmet>

        <Breadcrumbs
          breadcrumbs={[
            {
              name: <FormattedMessage id="trade_rule.trade_rule_propose_nominated_flat.breadcrumbs.properties.label" defaultMessage=" Properties" />, path: '/properties',
            },
            { name: title, path: `/properties/${uuid}` },
            { name: <FormattedMessage id="trade_rule.trade_rule_propose_nominated_flat.breadcrumbs.trade_rules.label" defaultMessage="Trade Rules" />, path: `/properties/${uuid}/trade-rules/active` },
            { name: formatMessage({ id: 'trade_rule.trade_rule_propose_nominated_flat.breadcrumbs.propose_peer_to_peer_trade_rule.label', defaultMessage: 'Propose peer-to-peer trade rule' }) },
          ]}
        />
        <Heading
          title={title}
          subtitle={addr.simpleString()}
          context={<FormattedMessage id="trade_rule.trade_rule_propose_nominated_flat.trade_rule_propose_peer_to_peer.title" defaultMessage="Trade Rule - Propose Peer-to-Peer" />}
          controls={this.renderPropertyControls()}
        />
        <TradeRuleProposeNominatedFlatForm
          property={property}
          handleTradeRuleProposeNominated={this.handleTradeRuleProposeNominated}
          processing={processing}
          router={router}
        />
      </>
    );
  }
}

TradeRuleProposeNominatedFlat.propTypes = {
  property: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  intl: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  relay: PropTypes.shape({
    refetch: PropTypes.func,
  }).isRequired,
  router: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

TradeRuleProposeNominatedFlat.defaultProps = {
  property: null,
};

export default injectIntl(createFragmentContainer(
  TradeRuleProposeNominatedFlat,
  {
    property: graphql`
      fragment TradeRuleProposeNominatedFlat_property on Property {
      uuid
        title
        address {
      line1
          line2
          city
          state
          postcode
          gps {
      latitude
            longitude
    }
        }
        ...TradeRuleProposeNominatedFlatForm_property
}
`,
  },
));
