/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
import { registerLocale } from 'react-datepicker';
import * as fetch from 'sync-fetch';

import { APIConfig } from 'src/config';

import { decimalSeparator } from './constants';

const DEFAULT_LOCALE = 'en-AU';
const SUPPORTED_DEFAULT_LOCALES = [
  'en', 'en-AU', 'en-GB', 'en-US', 'it', 'ja',
];

/**
 * Returns true if the locale is one supported natively by Powertracer.
 * @param {string} locale
 * @returns {boolean} true if supported, false if not.
 */
const isSupported = (locale) => (SUPPORTED_DEFAULT_LOCALES.indexOf(locale) >= 0);

/**
 * Returns the default locale for the selected locale.
 * @param {string} locale a valid locale as per
 * @returns {string} the stripped locale without any partner specifics.
 */
export const getDefaultLocale = (locale) => {
  if (!locale || typeof locale !== 'string') {
    return DEFAULT_LOCALE;
  }

  const matches = locale.match(/^(.+?)-x-(.+?)$/);
  const secondaryLocale = matches ? matches[1] : locale;

  if (isSupported(secondaryLocale)) {
    return secondaryLocale;
  }

  const [tertiaryLocale] = secondaryLocale.split('-');
  if (isSupported(tertiaryLocale)) {
    return tertiaryLocale;
  }

  return DEFAULT_LOCALE;
};

/**
 * Returns the language for the locale.
 * @param {string} locale
 * @returns {string} the languange.
 */
export const getLanguage = (locale) => {
  if (!locale || !(typeof locale === 'string')) {
    const [language] = DEFAULT_LOCALE.split('-');

    return language;
  }

  const [language] = locale.split('-');

  return language;
};

/**
 * Trimmed locale
 * @param {string} locale
 * @returns {string} The trimmed locale.
 */
export const trimLocale = (locale) => {
  const matched = locale.match(/^([a-z]{2}(-[A-Z]{2})?)-x-(.+?)$/);
  if (!matched) {
    return locale;
  }

  const privateTag = matched[3].replace(/[^a-zA-Z]/, '').toLowerCase().substring(0, 8);
  const trimmed = `${matched[1]}-x-${privateTag}`;

  return trimmed;
};

/**
 * Returns the locale of the application based on the partner/retailer
 * @returns {string} - locale
 */
export const getFullLocale = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const requestLocale = urlParams.get('locale');
  const partnerLocale = APIConfig()?.LOCALE;

  return requestLocale || partnerLocale || DEFAULT_LOCALE;
};

/**
 * Returns the locale of the application based on the partner/retailer
 * @returns {string} - locale
 */
export const getLocale = () => (trimLocale(getFullLocale()));

/**
 * Return an embedded set of i18n messages.
 * @param {string} fullLocale the full locale of the embedded langauge pack.
 * @returns {object} the i18n messages.
 */
export const i18nMessagesEmbedded = (fullLocale) => {
  try {
    require.resolve(`../../../assets/i18n/compiled/${fullLocale}/${fullLocale}.json`);
    return require(`../../../assets/i18n/compiled/${fullLocale}/${fullLocale}.json`);
  } catch (e) {
    return {};
  }
};

/**
 * Return the file contents at `/public/i18n/${locale}/${locale}.json`.
 * @param {string} fullLocale the name of the locale.
 * @returns {Promise} the locale object.
 */
const i18nMessagesPublic = (fullLocale) => {
  const localePath = `/public/i18n/${fullLocale}/${fullLocale}.json`;
  const fileJSON = fetch(localePath).json();

  return fileJSON;
};

/**
 * Retuns the language pack(static) that is used across the application
 * @returns {object} - language pack map
 */
export const i18nMessages = () => {
  const fullLocale = getFullLocale();
  const locale = getLocale();
  let messagesLocale;

  try {
    messagesLocale = i18nMessagesPublic(fullLocale);
  } catch (e) {
    console.log("failed loading locale '%s', checking compiled options", fullLocale);
  }

  if (messagesLocale === undefined) {
    messagesLocale = i18nMessagesEmbedded(fullLocale);
  }

  const defaultLocale = getDefaultLocale(locale);
  const messagesDefaultLocale = require(`../../../assets/i18n/compiled/${defaultLocale}/${defaultLocale}.json`);

  let dateRangeLocale;
  try {
    dateRangeLocale = require(`date-fns/locale/${defaultLocale}/index.js`);
  } catch {
    dateRangeLocale = require(`date-fns/locale/${DEFAULT_LOCALE}/index.js`);
  }
  registerLocale(defaultLocale, dateRangeLocale);

  // return messagesLocale;
  return { ...messagesDefaultLocale, ...messagesLocale };
};

/**
 * Decimal formatting - if there is a separator defined in the decimal separator it
 * will use that or will use the default (.)
 * @param {string} value
 * @returns {string} - decimal separated value
 */
export const i18nDecimalFormat = (value) => {
  const replaceByValue = decimalSeparator[getDefaultLocale(getLocale())];
  if (!replaceByValue) {
    return value;
  }
  return (value.replace('.', replaceByValue));
};
