import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';
import {
  Card, CardHeader, Table,
} from 'reactstrap';

import BadgeActive from 'src/components/Badge/BadgeActive';
import BadgePortfolioUserRole from 'src/components/Badge/BadgePortfolioUserRole';
import UUID from 'src/components/UUID';

/**
 * @param {object} props
 * @param {object} props.portfolioUserMember portfolio user member object
 * @param {number} props.portfolioUserMember.uuid portfolio user member's uuid
 * @param {number} props.portfolioUserMember.externalIdentifier
 * portfolio user member's external identifier
 * @param {object} props.portfolioUserMember.active portfolio user member's active status
 * @param {string} props.portfolioUserMember.role portfolio user member's role
 * @param {number} props.portfolioUserMember.createdAt portfolio user member's created at timestamp
 * @param {number} props.portfolioUserMember.updatedAt portfolio user member's updated at timestamp
 * @returns {React.ReactElement | null} - UserMemberDetailsCard component
 */
function UserMemberDetailsCard(props) {
  if (!props) return null;

  const { portfolioUserMember } = props || {};
  const {
    uuid, externalIdentifier, active,
    role, createdAt, updatedAt,
  } = portfolioUserMember || {};
  const { start, finish } = active || {};
  const intl = useIntl();

  return (
    <Card>
      <CardHeader>
        {intl.formatMessage({ id: 'admin.admin_portfolio_user_member.admin_portfolio_user_member_show.user_member_details.table.card_header.user_member_details.label', defaultMessage: 'User member details' })}
      </CardHeader>
      <Table responsive>
        <tbody>
          <tr>
            <th scope="row" className="text-right">
              {intl.formatMessage({ id: 'admin.admin_portfolio_user_member.admin_portfolio_user_member_show.user_member_details_card.card_body.head.active_status.label', defaultMessage: 'Active status' })}
            </th>
            <td>
              <BadgeActive start={start} finish={finish} />
            </td>
          </tr>
          <tr>
            <th scope="row" className="text-right">
              <abbr title={intl.formatMessage({ id: 'admin.admin_portfolio_user_member.admin_portfolio_user_member_show.user_member_details_card.card_body.head.id.abbr.title', defaultMessage: 'Identifier' })}>
                {intl.formatMessage({ id: 'admin.admin_portfolio_user_member.admin_portfolio_user_member_show.user_member_details_card.card_body.head.id.label', defaultMessage: 'ID' })}
              </abbr>
            </th>
            <td>
              <UUID uuid={uuid} />
            </td>
          </tr>
          <tr>
            <th scope="row" className="text-right">
              <abbr title={intl.formatMessage({ id: 'admin.admin_portfolio_user_member.admin_portfolio_user_member_show.user_member_details_card.card_body.head.ext_id.abbr.title', defaultMessage: 'External identifier' })}>
                {intl.formatMessage({ id: 'admin.admin_portfolio_user_member.admin_portfolio_user_member_show.user_member_details_card.card_body.head.ext_id.label', defaultMessage: 'Ext ID' })}
              </abbr>
            </th>
            <td>{externalIdentifier}</td>
          </tr>
          <tr>
            <th scope="row" className="text-right">
              {intl.formatMessage({ id: 'admin.admin_portfolio_user_member.admin_portfolio_user_show.user_member_details_card.card_body.head.role.label', defaultMessage: 'Role' })}
            </th>
            <td>
              <BadgePortfolioUserRole userRole={role} />
            </td>
          </tr>
          <tr>
            <th scope="row" className="text-right">
              {intl.formatMessage({ id: 'admin.admin_portfolio_user_member.admin_portfolio_user_member_show.user_member_details_card.card_body.head.start.label', defaultMessage: 'Start' })}
            </th>
            <td>
              {start && DateTime.fromSeconds(start).toLocaleString(DateTime.DATETIME_FULL)}
            </td>
          </tr>
          <tr>
            <th scope="row" className="text-right">
              {intl.formatMessage({ id: 'admin.admin_portfolio_user_member.admin_portfolio_user_member_show.user_member_details_card.card_body.head.finish.label', defaultMessage: 'Finish' })}
            </th>
            <td>
              {finish && DateTime.fromSeconds(finish).toLocaleString(DateTime.DATETIME_FULL)}
            </td>
          </tr>
          <tr>
            <th scope="row" className="text-right">
              {intl.formatMessage({ id: 'admin.admin_portfolio_user_member.admin_portfolio_user_member_show.user_member_details_card.card_body.head.created_at.label', defaultMessage: 'Created at' })}
            </th>
            <td>
              {createdAt
            && DateTime.fromSeconds(createdAt).toLocaleString(DateTime.DATETIME_FULL)}
            </td>
          </tr>
          <tr>
            <th scope="row" className="text-right">
              {intl.formatMessage({ id: 'admin.admin_portfolio_user_member.admin_portfolio_user_member_show.user_member_details_card.card_body.head.updated_at.label', defaultMessage: 'Updated at' })}
            </th>
            <td>
              {updatedAt
            && DateTime.fromSeconds(updatedAt).toLocaleString(DateTime.DATETIME_FULL)}
            </td>
          </tr>
        </tbody>
      </Table>
    </Card>
  );
}

UserMemberDetailsCard.propTypes = {
  portfolioUserMember: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    externalIdentifier: PropTypes.string.isRequired,
    active: PropTypes.shape({
      start: PropTypes.number.isRequired,
      finish: PropTypes.number,
    }),
    role: PropTypes.string.isRequired,
    createdAt: PropTypes.number.isRequired,
    updatedAt: PropTypes.number.isRequired,
  }).isRequired,
};

export default UserMemberDetailsCard;
