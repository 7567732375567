/**
 * @generated SignedSource<<a32fab17cdb5a57547d6aec1e5bd1c42>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AdminPortfolioShow_portfolio",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "uuid",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "externalIdentifier",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TimeRange",
      "kind": "LinkedField",
      "name": "active",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "start",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "finish",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "updatedAt",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AdminPortfolioUserMembers_portfolio"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AdminPortfolioPropertyMembers_portfolio"
    }
  ],
  "type": "Portfolio",
  "abstractKey": null
};

node.hash = "79a1f9517f18f65854d7ab07c427771c";

module.exports = node;
