import { Link } from 'found';
import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { FormattedMessage, injectIntl } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';
import { ButtonGroup, ButtonToolbar } from 'reactstrap';

import Address from 'src/components/Address';
import Loading from 'src/components/Loading';
import { APIConfig } from 'src/config';
import Breadcrumbs from 'src/enosikit/components/Breadcrumbs';
import Heading from 'src/enosikit/components/Heading';
import {
  FEATURE_INHIBIT_COMMUNITY_TRADES,
  FEATURE_INHIBIT_CONTRACTED_TRADES,
  FEATURE_INHIBIT_NOMINATED_TRADES,
} from 'src/util/constants';

import TradeRuleActiveCommunity from './TradeRuleActiveCommunity';
import TradeRuleActiveContracted from './TradeRuleActiveContracted';
import TradeRuleActiveNominated from './TradeRuleActiveNominated';
import TradeRuleActiveProposed from './TradeRuleActiveProposed';
import TradeRuleActiveResidual from './TradeRuleActiveResidual';

class TradeRuleActive extends React.Component {
  renderPropertyControls = () => {
    const { intl, property } = this.props;
    const { formatMessage } = intl;

    return (
      <ButtonToolbar>
        <ButtonGroup className="ms-2">
          <Link to={`/properties/${property.uuid}/trade-rules`} className="btn btn-darken">
            {formatMessage({ id: 'trade_rule.trade_rule_active.all_trade_rules.label', defaultMessage: 'All trade rules' })}
          </Link>
        </ButtonGroup>

        <ButtonGroup className="ms-2">
          <Link to={`/properties/${property.uuid}/settings`} className="btn btn-darken">
            {formatMessage({ id: 'trade_rule.trade_rule_active.property_settings.label', defaultMessage: 'Property Settings' })}
          </Link>
        </ButtonGroup>
      </ButtonToolbar>
    );
  };

  render() {
    if (this.error) {
      return <div><FormattedMessage id="error.title" defaultMessage="Error!" /></div>;
    }
    if (!this.props) {
      return <Loading />;
    }

    const { intl, property, router } = this.props;
    const { address, uuid, title } = property;
    const { formatMessage } = intl;

    const addr = new Address(address);
    const pageTitle = formatMessage(
      { id: 'trade_rule.trade_rule_active.page.title', defaultMessage: 'Enosi - Property: {propertyTitle}' },
      { propertyTitle: title },
    );

    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{pageTitle}</title>
        </Helmet>

        <Breadcrumbs
          breadcrumbs={[
            {
              name: formatMessage({ id: 'trade_rule.trade_rule_active.properties.breadcrumb', defaultMessage: 'Properties' }), path: '/properties',
            },
            { name: title, path: `/properties/${uuid}` },
            { name: formatMessage({ id: 'trade_rule.trade_rule_active.trade_rules.breadcrumb', defaultMessage: 'Trade Rules' }) },
          ]}
        />
        <Heading
          title={title}
          subtitle={addr.simpleString()}
          context={formatMessage({ id: 'trade_rule.trade_rule_active.trade_rules.title', defaultMessage: 'Trade Rules' })}
          controls={this.renderPropertyControls()}
        />
        {!APIConfig().feature(FEATURE_INHIBIT_CONTRACTED_TRADES) && (
          <TradeRuleActiveContracted property={property} router={router} />
        )}
        {!APIConfig().feature(FEATURE_INHIBIT_NOMINATED_TRADES) && (
          <>
            <TradeRuleActiveProposed property={property} router={router} />
            <TradeRuleActiveNominated property={property} router={router} />
          </>
        )}
        {!APIConfig().feature(FEATURE_INHIBIT_COMMUNITY_TRADES) && (
          <TradeRuleActiveCommunity property={property} router={router} />
        )}
        <TradeRuleActiveResidual property={property} router={router} />
      </>
    );
  }
}

TradeRuleActive.propTypes = {
  property: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  intl: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  relay: PropTypes.shape({
    refetch: PropTypes.func,
  }).isRequired,
  router: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

TradeRuleActive.defaultProps = {
  property: null,
};

export default injectIntl(createFragmentContainer(
  TradeRuleActive,
  {
    property: graphql`
      fragment TradeRuleActive_property on Property {
        uuid
        title
        address {
          line1
          line2
          city
          state
          postcode
          gps {
            latitude
            longitude
          }
        }
        ...TradeRuleActiveCommunity_property
        ...TradeRuleActiveContracted_property
        ...TradeRuleActiveNominated_property
        ...TradeRuleActiveProposed_property
        ...TradeRuleActiveResidual_property
      }
    `,
  },
));
