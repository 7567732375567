/**
 * @generated SignedSource<<d213797f81e3b2f03a8d8c7293c6755c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AdminMeterTradeRulesTable_rules",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "TradeRuleEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "cursor",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "TradeRule",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "uuid",
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "AdminMeterTradeRulesItem_rule"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "TradeRuleConnection",
  "abstractKey": null
};

node.hash = "8402371b77358c1d62e3352ea8036869";

module.exports = node;
