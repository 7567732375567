import { DateTime } from 'luxon';

/**
 * Generates a date range message based on the active start and finish timestamps.
 * @param {object} active - The active start and finish timestamps.
 * @param {number} active.start - The active start timestamp.
 * @param {number} active.finish - The active finish timestamp.
 * @param {import('react-intl').IntlShape} intl - The intl object.
 * @returns {string} - The formatted time range message.
 */
function getTimeRangeMsg(active, intl) {
  const { start, finish } = active || {};
  const startOnly = start && !finish;
  const finishOnly = !start && finish;
  const startAndFinish = start && finish;

  if (startOnly) {
    const startDate = DateTime.fromSeconds(start).toISO();
    return intl.formatMessage({ id: 'util.time_range.start_only.text', defaultMessage: '{startDate} to the future' }, { startDate });
  }

  if (finishOnly) {
    const finishDate = DateTime.fromSeconds(finish).toISO();
    return intl.formatMessage({ id: 'util.time_range.finish_only.text', defaultMessage: 'Active until {finishDate}' }, { finishDate });
  }

  if (startAndFinish) {
    const startDate = DateTime.fromSeconds(start).toISO();
    const finishDate = DateTime.fromSeconds(finish).toISO();
    const dateRangeMessage = intl.formatMessage({ id: 'util.time_range.text', defaultMessage: '{startDate} to {finishDate}' }, { startDate, finishDate });
    return dateRangeMessage;
  }

  return intl.formatMessage({ id: 'util.time_range.active_always.text', defaultMessage: 'Active always' });
}

export default getTimeRangeMsg;
