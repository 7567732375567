import { Spinner } from 'reactstrap';
import styled from 'styled-components';

export const Step = styled.li.attrs({
  className: 'd-flex',
})`
  list-style: none;
`;

export const ActionSpinner = styled(Spinner)`
  width: 1.5rem;
  height: 1.5rem;
`;

export const ActionSpinnerWrapper = styled.div`
  opacity: 75%;
  color: #666;
`;

export const TRADE_POINT = 'trade point';
export const RETAILER_DEFAULT_TRADE_POINT = 'retailer default trade point';
export const BUYING = 'buying';
export const SELLING = 'selling';
export const CPKWH = 'cents per kilowatt hour';
export const PRICE = 'price';
export const DISCOUNT = 'discount';
export const FROM = 'from';
export const TO = 'to';
