import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { injectIntl } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import authenticateUser from 'src/auth';
import Flashes from 'src/components/Flashes';
import Loading from 'src/components/Loading';
import AdminNavigation from 'src/components/Navigation/AdminNavigation';
import Footer from 'src/enosikit/components/Footer';
import { USER_TYPE_ADMIN } from 'src/util/constants';

/**
 * Description
 * @param {any} props
 * @returns {React.ReactComponentElement} - AdminLayout component
 */
function AdminLayout(props) {
  const {
    children, router, viewer, intl,
  } = props;

  if (!authenticateUser(intl, viewer, USER_TYPE_ADMIN, router)) {
    return <Loading />;
  }

  const pageTitle = intl.formatMessage({ id: 'layout.admin_layout.page.title', defaultMessage: 'Enosi Administration' });

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{pageTitle}</title>
      </Helmet>

      <div className="flex flex-column" style={{ minHeight: '80vh' }}>
        <AdminNavigation viewer={viewer} />

        <section id="main">
          <Flashes />
          <div className="container">
            <div className="flex-auto">{children}</div>
          </div>
        </section>

        <Footer versionUI={VERSION} />
      </div>
    </>
  );
}

AdminLayout.propTypes = {
  intl: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  router: PropTypes.shape({
    replace: PropTypes.func,
  }).isRequired,
  viewer: PropTypes.shape({
    id: PropTypes.string,
    viewerUser: PropTypes.shape({
      type: PropTypes.string,
      uuid: PropTypes.string,
    }),
  }), // eslint-disable-line react/forbid-prop-types
};

AdminLayout.defaultProps = {
  viewer: null,
};

export default createFragmentContainer(
  injectIntl(AdminLayout),
  {
    viewer: graphql`
      fragment AdminLayout_viewer on Viewer @relay(mask: false) {
        id
        viewerUser {
          uuid,
          type
        }
        ...AdminNavigation_viewer
      }
    `,
  },
);
