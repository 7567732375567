import { Auth } from 'aws-amplify';
import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
  Button, Form, FormFeedback, FormGroup, FormText, Input, Label,
} from 'reactstrap';
import isEmail from 'validator/es/lib/isEmail';
import isLength from 'validator/es/lib/isLength';

import FlashesStore from 'src/stores/FlashesStore';
import { withTracingAsync } from 'src/tracing';
import { setLoggedInUserDetails } from 'src/util/intercom';

import AuthContainer, { AUTH_TYPE_LOGIN } from '../AuthContainer';

/**
 * Description
 * @param {any} username
 * @param {any} password
 * @returns {boolean} - sign in true or false
 */
async function signInRaw(username, password) {
  try {
    const user = await Auth.signIn(username, password);
    setLoggedInUserDetails(user?.attributes);
    return true;
  } catch (error) {
    FlashesStore.flash(FlashesStore.ERROR, error.message);
    console.log({ message: 'error signing in', error });
    return false;
  }
}

/**
 * Description
 * @param {any} username
 * @param {any} password
 * @returns {any} - promise
 */
async function signIn(username, password) {
  return withTracingAsync('signin', signInRaw, username, password);
}

class AuthLogin extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      emailValid: null,
      password: '',
      passwordValid: null,
      processing: false,
    };
  }

  redirect = () => {
    const { match } = this.props;
    const { location } = match;
    const { search } = location;

    const regex = /[?&]redirect(=([^&#]*)|&|#|$)/i;
    const results = regex.exec(search);

    if (!results || !results[2]) {
      return '/';
    }

    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  };

  handleEmailChange = (e) => {
    const { value: email } = e.target;
    const emailValid = isEmail(email);

    this.setState({ email, emailValid });
  };

  handlePasswordChange = (e) => {
    const { value: password } = e.target;
    const passwordValid = isLength(password, { min: 8 });

    this.setState({ password, passwordValid });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const { intl, router } = this.props;
    const { email, password, processing } = this.state;

    FlashesStore.reset();

    if (processing) {
      const msgInprogress = intl.formatMessage({ id: 'auth.auth_login.form.state.invalid_still_processing', defaultMessage: 'We are still processing your request...' });
      FlashesStore.flash(FlashesStore.INFO, msgInprogress);
      return;
    }

    if (!this.isValid()) {
      const msgInvalidData = intl.formatMessage({ id: 'auth.auth_login.form.state.invalid_data', defaultMessage: 'Form data not valid. Please see below.' });
      FlashesStore.flash(FlashesStore.ERROR, msgInvalidData);
      return;
    }

    try {
      this.setState({ processing: true });

      const login = await signIn(email, password);

      this.setState({ processing: false });

      if (login) {
        const redirect = this.redirect();
        const msgSuccess = (
          <FormattedMessage
            id="auth.auth_login.form.response_message.success"
            defaultMessage="Login succeeded, redirecting to {target}"
            values={{
              target: redirect,
            }}
          />
        );
        FlashesStore.flash(FlashesStore.SUCCESS, msgSuccess);
        router.push(redirect);
      }
    } catch (error) {
      this.setState({ processing: false });

      FlashesStore.flash(FlashesStore.ERROR, error);
      console.log({ error });
    }
  };

  isValid = () => {
    const { emailValid, passwordValid } = this.state;

    return emailValid && passwordValid;
  };

  render() {
    const {
      email, emailValid, password, passwordValid, processing,
    } = this.state;
    const { intl } = this.props;
    const pageTitle = intl.formatMessage({ id: 'auth.auth_login.page.title', defaultMessage: 'Log in to Enosi' });
    const formTitle = intl.formatMessage({ id: 'auth.auth_login.form.title', defaultMessage: 'Log in' });
    const emailLabel = intl.formatMessage({ id: 'auth.auth_login.form.email_address.label', defaultMessage: 'Email address' });
    const emailPlaceholder = intl.formatMessage({ id: 'auth.auth_login.form.email.placeholder', defaultMessage: 'Enter email address' });
    const emailFeedbackInvalid = intl.formatMessage({ id: 'auth.auth_login.form.email.form_feedback.invalid', defaultMessage: 'Invalid email address' });
    const emailHelpText = intl.formatMessage({ id: 'auth.auth_login.form.email.help_text', defaultMessage: 'Email address associated with your Enosi account' });
    const passwordLabel = intl.formatMessage({ id: 'auth.auth_login.form.password.label', defaultMessage: 'Password' });
    const passwordPlaceholder = intl.formatMessage({ id: 'auth.auth_login.form.password.placeholder', defaultMessage: 'Enter password' });
    const submitLabel = intl.formatMessage({ id: 'auth.auth_login.form.submit.label', defaultMessage: 'Log in' });

    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{pageTitle}</title>
        </Helmet>

        <AuthContainer type={AUTH_TYPE_LOGIN} title={formTitle}>
          <Form onSubmit={this.handleSubmit}>
            <FormGroup>
              <Label htmlFor="email">{emailLabel}</Label>
              <Input
                type="email"
                id="email"
                aria-describedby="email"
                placeholder={emailPlaceholder}
                onChange={this.handleEmailChange}
                value={email}
                valid={emailValid}
                invalid={emailValid !== null && !emailValid}
                disabled={processing}
              />
              <FormFeedback>{emailFeedbackInvalid}</FormFeedback>
              <FormText>{emailHelpText}</FormText>
            </FormGroup>
            <FormGroup>
              <Label htmlFor="password">{passwordLabel}</Label>
              <Input
                type="password"
                id="password"
                aria-describedby="password"
                placeholder={passwordPlaceholder}
                onChange={this.handlePasswordChange}
                value={password}
                valid={passwordValid}
                invalid={passwordValid !== null && !passwordValid}
                disabled={processing}
              />
              <FormFeedback>
                <FormattedMessage
                  id="auth.auth_login.form.password.form_feedback.invalid"
                  defaultMessage="Password must be at least 8 characters long, with uppercase, lowercase, number and one of:{specialCharacters}"
                  values={{
                    specialCharacters:
                      // eslint-disable-next-line react/jsx-indent
                      <>
                        <br />
                        <code className="code">
                          {'^ $ * . [ ] { } ( ) ? " ! @ # % & / \\ , < > \' : ; | _ ~ `'}
                        </code>
                      </>,
                  }}
                />
              </FormFeedback>
            </FormGroup>
            <Button type="submit" color="primary" disabled={processing}>
              {submitLabel}
            </Button>
          </Form>
        </AuthContainer>
      </>
    );
  }
}

AuthLogin.propTypes = {
  intl: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  match: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  router: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default injectIntl(AuthLogin);
