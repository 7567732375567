import { Link } from 'found';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, FormattedPlural, injectIntl } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';
import {
  Alert, Badge, Button, Card, CardBody, CardFooter, CardSubtitle, CardText, CardTitle, Col,
  DropdownMenu, DropdownToggle, Row, UncontrolledButtonDropdown,
} from 'reactstrap';

import Loading from 'src/components/Loading';
import { buyPriceInformation, sellPriceInformation } from 'src/components/TradeRule/TradeRuleHelpers';
import { APIConfig } from 'src/config';
import Time from 'src/enosikit/components/Time/components/Time';
import { emptyTradeRulesClassName, getTradeRuleMeterLabel, prioritizeTradesClassName } from 'src/helpers/tradeHelpers';
import CloseNominatedTradeMutation from 'src/mutations/CloseNominatedTradeMutation';
import FlashesStore from 'src/stores/FlashesStore';
import {
  NOMINATED,
  TRADE_DIRECTION_BUY, TRADE_DIRECTION_SELL,
  TRADE_RULE_CANCEL, TRADE_RULE_CLOSE,
  TRADE_RULE_STATE_ACCEPTED, TRADE_RULE_STATE_CANCELLED, TRADE_RULE_STATE_CLOSED,
  TRADE_RULE_STATE_PROPOSED, TRADE_RULE_STATE_REJECTED,
  TRADE_TYPE_NOMINATED,
} from 'src/util/constants';
import username from 'src/util/decorators/username';
import { getStringAndNumericFormattedDate } from 'src/util/i18n/helpers';
import isActive from 'src/util/isActive';
import { tradeRulePriceRange } from 'src/util/tradeRule';

class TradeRuleActiveNominated extends React.Component {
  static tradeRulesForProperty(property) {
    const { meters } = property;
    const rules = { sell: [], buy: [] };

    meters.edges.forEach((meterEdge) => {
      if (!!meterEdge && !!meterEdge.node && !!meterEdge.node.nominatedRules) {
        meterEdge.node.nominatedRules.edges.forEach((ruleEdge) => {
          const { node: ruleNode } = ruleEdge;
          if (
            ruleNode.state !== TRADE_RULE_STATE_ACCEPTED
            || ruleNode.tradeType !== TRADE_TYPE_NOMINATED
          ) {
            return;
          }

          if (ruleNode.seller.tradePoint.uuid === meterEdge.node.tradePointId) {
            rules.sell.push({ ...ruleNode, meter: meterEdge.node });
          } else {
            rules.buy.push({ ...ruleNode, meter: meterEdge.node });
          }
        });
      }
    });

    const compareRules = (ruleA, ruleB) => {
      if (ruleA.meter.title !== ruleB.meter.title) {
        return ruleA.meter.title.localeCompare(ruleB.meter.title);
      }
      if (ruleA.priority !== ruleB.priority) {
        return ruleA.priority - ruleB.priority;
      }
      return ruleA.acceptedAt - ruleB.acceptedAt;
    };
    rules.sell.sort(compareRules);
    rules.buy.sort(compareRules);

    return rules;
  }

  // eslint-disable-next-line class-methods-use-this -- consistency in handling mutations.
  handleNominatedTradeMutationFailure = (error) => {
    FlashesStore.flash(FlashesStore.ERROR, error);
  };

  // eslint-disable-next-line class-methods-use-this -- consistency in handling mutations.
  handleCloseNominatedTradeMutationSuccess = (response) => {
    const { uuid } = response.closeNominatedTrade;
    const { intl } = this.props;
    const successMsg = intl.formatMessage({ id: 'trade_rule.trade_rule_active.trade_rule_active_nominated.response_message.success', defaultMessage: 'Closed peer-to-peer trade rule with id: {uuid}.' }, { uuid });

    FlashesStore.flash(
      FlashesStore.SUCCESS,
      successMsg,
    );
  };

  tradeRuleAction = (rule, action) => {
    const { uuid } = rule;
    const { intl } = this.props;
    const errorMsg = intl.formatMessage({ id: 'trade_rule.trade_rule_active.trade_rule_active_nominated.response_message.error', defaultMessage: 'Action {action} is not possible.' }, { action });

    switch (action) {
      case TRADE_RULE_CLOSE:
        CloseNominatedTradeMutation(
          uuid,
          this.handleCloseNominatedTradeMutationSuccess,
          this.handleNominatedTradeMutationFailure,
        );
        return;
      default:
        FlashesStore.flash(
          FlashesStore.ERROR,
          errorMsg,
        );
    }
  };

  tradeRuleActionButton = (rule) => {
    const {
      state, meter, buyer, seller,
    } = rule;

    if (
      [
        TRADE_RULE_STATE_PROPOSED,
        TRADE_RULE_STATE_CANCELLED,
        TRADE_RULE_STATE_REJECTED,
        TRADE_RULE_STATE_CLOSED,
      ].includes(state)
    ) {
      return null;
    }

    if (state === TRADE_RULE_STATE_ACCEPTED) {
      return (
        <Button onClick={() => this.tradeRuleAction(rule, TRADE_RULE_CLOSE)}>
          <FormattedMessage id="trade_rule.trade_rule_active.trade_rule_active_nominated.close_agreed_trade.label" defaultMessage="Close agreed trade" />
        </Button>
      );
    }

    if (
      [buyer.tradePoint.uuid, seller.tradePoint.uuid].includes(meter.tradePointId)
    ) {
      return (
        <Button onClick={() => this.tradeRuleAction(rule, TRADE_RULE_CANCEL)}>
          <FormattedMessage id="trade_rule.trade_rule_active.trade_rule_active_nominated.cancel_agreed_trade.label" defaultMessage="Cancel agreed trade" />
        </Button>
      );
    }

    return (
      <Alert color="danger">
        <FormattedMessage
          id="trade_rule.trade_rule_active.trade_rule_active_nominated.unknown_trade_point.alert"
          defaultMessage="Unknown trade point: {meterId} when buyer is {buyerTradePointId} and seller is {sellerTradePointId}"
          values={{
            meterId: meter.uuid,
            buyerTradePointId: buyer.tradePoint.uuid,
            sellerTradePointId: seller.tradePoint.uuid,
          }}
        />

      </Alert>
    );
  };

  render() {
    if (this.error) {
      return <div><FormattedMessage id="error.title" defaultMessage="Error!" /></div>;
    }
    if (!this.props) {
      return <Loading />;
    }

    const { intl, property } = this.props;
    const { uuid: propertyId } = property;
    const { formatMessage } = intl;
    const tradeRules = TradeRuleActiveNominated.tradeRulesForProperty(property);
    const noTradeRulesClass = emptyTradeRulesClassName(tradeRules, NOMINATED);
    const prioritizeTradesClass = prioritizeTradesClassName(tradeRules, NOMINATED);
    const singleTrade = formatMessage({ id: 'trade_rule.trade_rule_active.trade_rule_active_nominated.trade.singular', defaultMessage: 'peer to peer trade rule' });
    const multipleTrade = formatMessage({ id: 'trade_rule.trade_rule_active.trade_rule_active_nominated.trade.multiple', defaultMessage: 'peer to peer trade rules' });
    const sellRulesCount = tradeRules?.sell?.length || 0;
    const buyRulesCount = tradeRules?.buy?.length || 0;

    return (
      <Card className={`mt-4 mb-4 trade-rules-nominated${noTradeRulesClass}`}>
        <CardBody>
          <h2><FormattedMessage id="trade_rule.trade_rule_active.trade_rule_active_nominated.title" defaultMessage="Peer-to-Peer Trades" /></h2>
          <p>
            <FormattedMessage
              id="trade_rule.trade_rule_active.trade_rule_active_nominated.help_text"
              defaultMessage="Enosi is best experienced when you trade energy with the people you know. We call this type of trade a peer-to-peer trade."
            />
          </p>
          <Row>
            <Col xs="12" sm="12" md="6">
              <h3><FormattedMessage id="trade_rule.trade_rule_active.trade_rule_active_nominated.sell.title" defaultMessage="Selling" /></h3>
              <FormattedMessage
                id="trade_rule.trade_rule_active.trade_rule_active_nominated.sell.count_details"
                defaultMessage="You have {tradeRulesCount} {tradeRulesCountLabel} to sell energy."
                values={{
                  tradeRulesCount: <strong>{sellRulesCount}</strong>,
                  tradeRulesCountLabel: <FormattedPlural
                    value={sellRulesCount}
                    one={singleTrade}
                    other={multipleTrade}
                  />,
                }}
              />

              {tradeRules.sell && tradeRules.sell.map((rule) => {
                const {
                  acceptedAt, acceptedBy, buyer, uuid: ruleId, meter,
                  priority, proposedAt, proposedBy, seller,
                } = rule;
                const { title } = meter;
                const priceRange = tradeRulePriceRange(rule);
                const { maximum, minimum } = priceRange;

                return (
                  <Card className="mt-4 mb-4" key={`trade-rules-${ruleId}`}>
                    <CardBody>
                      <CardTitle tag="h4">
                        {`Priority ${priority} for ${title}`}
                      </CardTitle>
                      <CardSubtitle tag="h5" className="mb-2">
                        <FormattedMessage id="trade_rule.trade_rule_active.trade_rule_active_nominated.sell.approved.title" defaultMessage="Approved" />
                      </CardSubtitle>
                      <CardText>
                        <FormattedMessage
                          id="trade_rule.trade_rule_active.trade_rule_active_nominated.sell.rule.meter"
                          defaultMessage="Selling from {meterLabel}"
                          values={{
                            meterLabel: getTradeRuleMeterLabel(
                              seller,
                              buyer,
                              TRADE_DIRECTION_SELL,
                            ),
                          }}
                        />
                        <br />
                        <FormattedMessage
                          id="trade_rule.trade_rule_active.trade_rule_active_nominated.sell.rule.proposed"
                          defaultMessage="Proposed by {actor} on {timestamp}"
                          values={{
                            actor: <strong>{username(proposedBy)}</strong>,
                            timestamp: <Time
                              child={getStringAndNumericFormattedDate(intl, proposedAt)}
                              unixTimestamp={proposedAt}
                            />,
                          }}
                        />
                        <br />
                        {acceptedAt && (
                          <FormattedMessage
                            id="trade_rule.trade_rule_active.trade_rule_active_nominated.sell.rule.accepted"
                            defaultMessage="Accepted by {actor} on {timestamp}"
                            values={{
                              actor: <strong>{username(acceptedBy)}</strong>,
                              timestamp: <Time
                                child={getStringAndNumericFormattedDate(intl, acceptedAt)}
                                unixTimestamp={acceptedAt}
                              />,
                            }}
                          />
                        )}
                        <br />
                        {
                          sellPriceInformation({
                            mode: APIConfig().MODE,
                            minimum,
                            maximum,
                            detailsLink: <Link to={`/properties/${propertyId}/trade-rules/${ruleId}`}><FormattedMessage id="trade_rule.trade_rule_active.trade_rule_active_nominated.buy.further_details" defaultMessage="further details" /></Link>,
                            units: <abbr title={formatMessage({ id: 'trade_rule.trade_rule_active.trade_rule_active_nominated.sell.energy_cost.abbr.title', defaultMessage: 'cents per kilowatt hour' })}><FormattedMessage id="trade_rule.trade_rule_active.trade_rule_active_nominated.sell.energy_cost.abbr.label" defaultMessage="c/kWh" /></abbr>,
                          })
                        }
                        <br />
                        <Link
                          to={`/properties/${propertyId}/trade-rules/${ruleId}`}
                        >
                          <Badge color="mid">{ruleId}</Badge>
                        </Link>
                      </CardText>
                    </CardBody>
                    <CardFooter>{this.tradeRuleActionButton(rule)}</CardFooter>
                  </Card>
                );
              })}
            </Col>

            <Col xs="12" sm="12" md="6">
              <h3><FormattedMessage id="trade_rule.trade_rule_active.trade_rule_active_nominated.buying.title" defaultMessage="Buying" /></h3>
              <FormattedMessage
                id="trade_rule.trade_rule_active.trade_rule_active_nominated.buy.count_details"
                defaultMessage="You have {tradeRulesCount} {tradeRulesCountLabel} to buy energy."
                values={{
                  tradeRulesCount: <strong>{tradeRules.buy.length}</strong>,
                  tradeRulesCountLabel: <FormattedPlural
                    value={buyRulesCount}
                    one={singleTrade}
                    other={multipleTrade}
                  />,
                }}
              />

              {tradeRules.buy && tradeRules.buy.map((rule) => {
                const {
                  acceptedAt, acceptedBy, buyer, uuid: ruleId, meter,
                  priority, proposedAt, proposedBy, seller,
                } = rule;
                const { title } = meter;
                const priceRange = tradeRulePriceRange(rule);
                const { maximum, minimum } = priceRange;

                return (
                  <Card className="mt-4 mb-4" key={`trade-rules-${ruleId}`}>
                    <CardBody>
                      <CardTitle tag="h4">
                        {`Priority ${priority} for ${title}`}
                      </CardTitle>
                      <CardSubtitle tag="h5" className="mb-2">
                        <FormattedMessage id="trade_rule.trade_rule_active.trade_rule_active_nominated.buy.approved.title" defaultMessage="Approved" />
                      </CardSubtitle>
                      <CardText>
                        <FormattedMessage
                          id="trade_rule.trade_rule_active.trade_rule_active_nominated.buy.rule.meter"
                          defaultMessage="Buying for {meterLabel}"
                          values={{
                            meterLabel: getTradeRuleMeterLabel(
                              buyer,
                              seller,
                              TRADE_DIRECTION_BUY,
                            ),
                          }}
                        />
                        <br />
                        <FormattedMessage
                          id="trade_rule.trade_rule_active.trade_rule_active_nominated.buy.rule.proposed"
                          defaultMessage="Proposed by {actor} on {timestamp}"
                          values={{
                            actor: <strong>{username(proposedBy)}</strong>,
                            timestamp: <Time
                              child={getStringAndNumericFormattedDate(intl, proposedAt)}
                              unixTimestamp={proposedAt}
                            />,

                          }}
                        />
                        <br />
                        {acceptedAt && (
                          <FormattedMessage
                            id="trade_rule.trade_rule_active.trade_rule_active_nominated.buy.rule.accepted"
                            defaultMessage="Accepted by {actor} on {timestamp}"
                            values={{
                              actor: <strong>{username(acceptedBy)}</strong>,
                              timestamp: <Time
                                child={getStringAndNumericFormattedDate(intl, acceptedAt)}
                                unixTimestamp={acceptedAt}
                              />,
                            }}
                          />
                        )}
                        <br />
                        {
                          buyPriceInformation({
                            mode: APIConfig().MODE,
                            minimum,
                            maximum,
                            detailsLink: <Link to={`/properties/${propertyId}/trade-rules/${ruleId}`}><FormattedMessage id="trade_rule.trade_rule_active.trade_rule_active_nominated.buy.further_details" defaultMessage="further details" /></Link>,
                            units: <abbr title={formatMessage({ id: 'trade_rule.trade_rule_active.trade_rule_active_nominated.buy.energy_cost.abbr.title', defaultMessage: 'cents per kilowatt hour' })}><FormattedMessage id="trade_rule.trade_rule_active.trade_rule_active_nominated.buy.energy_cost.abbr.label" defaultMessage="c/kWh" /></abbr>,
                          })
                        }
                        <br />
                        <Link
                          to={`/properties/${propertyId}/trade-rules/${ruleId}`}
                        >
                          <Badge color="mid">{ruleId}</Badge>
                        </Link>
                      </CardText>
                    </CardBody>
                    <CardFooter>{this.tradeRuleActionButton(rule)}</CardFooter>
                  </Card>
                );
              })}
            </Col>
          </Row>
        </CardBody>
        <CardFooter>
          <Link to={`/properties/${property.uuid}/trade-rules`}>
            <Button color="primary" className="me-2">
              <FormattedMessage id="trade_rule.trade_rule_active.trade_rule_active_nominated.view_all_trades.link.label" defaultMessage="View all trade rules" />
            </Button>
          </Link>
          <Link
            to={`/properties/${property.uuid}/trade-rules/propose/nominated`}
          >
            <Button color="primary" className="me-2">
              <FormattedMessage id="trade_rule.trade_rule_active.trade_rule_active_nominated.propose_new_trade.label" defaultMessage="Propose new trade" />
            </Button>
          </Link>
          <UncontrolledButtonDropdown className="me-2">
            <DropdownToggle className={prioritizeTradesClass} caret color="primary">
              <FormattedMessage id="trade_rule.trade_rule_active.trade_rule_active_nominated.manage_priority_order.label" defaultMessage="Manage priority order" />
            </DropdownToggle>
            <DropdownMenu>
              {property.meters.edges.map((edge) => edge && edge.node && (
                <Link
                  to={`/properties/${property.uuid}/meters/${edge.node.uuid}/trade-rules/nominated/prioritize`}
                  className={`dropdown-item ${!isActive(edge.node.active) ? 'disabled' : ''}`}
                  role="menuitem"
                  key={edge.node.uuid}
                >
                  {edge.node.title}
                </Link>
              ))}
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        </CardFooter>
      </Card>
    );
  }
}

TradeRuleActiveNominated.propTypes = {
  property: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  intl: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  relay: PropTypes.shape({
    refetch: PropTypes.func,
  }).isRequired,
  router: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

TradeRuleActiveNominated.defaultProps = {
  property: null,
};

export default injectIntl(createFragmentContainer(
  TradeRuleActiveNominated,
  {
    property: graphql`
      fragment TradeRuleActiveNominated_property on Property {
        uuid
        meters {
          edges {
            node {
              uuid
              identifier
              title
              tradePointId
              active { start finish }
              nominatedRules: rules(first: 500, type: TRADE_TYPE_NOMINATED, state: TRADE_RULE_STATE_ACCEPTED) {
                edges {
                  node {
                    uuid
                    priority
                    tradeType
                    state
                    buyer {
                      userId
                      communityId
                      residualId
                      tradePoint {
                        uuid
                        meter {
                          uuid
                          identifier
                          title
                          property {
                            uuid
                            title
                          }
                        }
                      }
                    }
                    seller {
                      userId
                      communityId
                      residualId
                      tradePoint {
                        uuid
                        meter {
                          uuid
                          identifier
                          title
                          property {
                            uuid
                            title
                          }
                        }
                      }
                    }
                    clauses {
                      edges {
                        node {
                          price
                        }
                      }
                    }
                    start
                    finish
                    proposedAt
                    proposedBy {
                      uuid
                      email
                      givenName
                      familyName
                    }
                    acceptedAt
                    acceptedBy {
                      uuid
                      email
                      givenName
                      familyName
                    }
                  }
                }
              }
            }
          }
        }
      }
    `,
  },
));
