import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import authenticateUser from 'src/auth';
import Flashes from 'src/components/Flashes';
import HTTP404 from 'src/components/HTTP404';
import Loading from 'src/components/Loading';
import CustomerPropertyNavigation from 'src/components/Navigation/CustomerPropertyNavigation';
import Footer from 'src/enosikit/components/Footer';
import { withTracingHOC } from 'src/tracing';

/**
 * Description
 * @param {any} props
 * @returns {React.ReactComponentElement} - CustomerPropertyLayout component
 */
function CustomerPropertyLayout(props) {
  const {
    children, property, router, viewer,
  } = props;

  if (!property) {
    return <HTTP404 />;
  }
  const intl = useIntl();

  if (!authenticateUser(intl, viewer, null, router)) {
    return <Loading />;
  }

  const { title: propertyTitle } = property;
  const { properties } = viewer;
  const pageTitle = intl.formatMessage({ id: 'layout.customer_layout.customer_property_layout.page.title', defaultMessage: 'Enosi - Property: {propertyTitle}' }, { propertyTitle });

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{pageTitle}</title>
      </Helmet>

      <div className="flex flex-column" style={{ minHeight: '80vh' }}>
        <CustomerPropertyNavigation viewer={viewer} properties={properties} property={property} />
        <section id="main">
          <Flashes />
          <div className="container">
            <div className="flex-auto">{children}</div>
          </div>
        </section>
      </div>

      <Footer versionUI={VERSION} />
    </>
  );
}

CustomerPropertyLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  properties: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  property: PropTypes.shape({
    uuid: PropTypes.string,
    title: PropTypes.string,
  }),
  router: PropTypes.shape({
    replace: PropTypes.func,
  }).isRequired,
  viewer: PropTypes.shape({
    id: PropTypes.string,
    properties: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  }),
};

CustomerPropertyLayout.defaultProps = {
  property: null,
  properties: null,
  viewer: null,
};

export default withTracingHOC('CustomerPropertyLayout', createFragmentContainer(CustomerPropertyLayout, {
  viewer: graphql`
    fragment CustomerPropertyLayout_viewer on Viewer {
      id
      viewerUser {
        uuid
        type
      }
      ...CustomerPropertyNavigation_viewer
      properties {
        ...CustomerPropertyNavigation_properties
      }
    }
  `,
  property: graphql`
    fragment CustomerPropertyLayout_property on Property {
      title
      ...CustomerPropertyNavigation_property
    }
  `,
}));
