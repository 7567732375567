import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const ImageSize = styled.img`
  max-width: ${(props) => props.size};
  max-height: ${(props) => props.size};
  object-fit: contain;
`;

const ImageHeight = styled.img`
  max-height: ${(props) => props.height};
  object-fit: contain;
`;

const ImageWidth = styled.img`
  max-width: ${(props) => props.width};
  object-fit: contain;
`;

const ImageHeightWidth = styled.img`
  max-height: ${(props) => props.height};
  max-width: ${(props) => props.width};
  object-fit: contain;
`;

const DEFAULT_SIZE = '96px';

/**
 * Description
 * @param {object} props
 * @returns {React.ReactComponentElement} - logo component
 */
function Logo(props) {
  const {
    image, size, height, width, name,
  } = props;

  if (height !== '' && width !== '') {
    return (
      <ImageHeightWidth src={image} height={height} width={width} alt={name} />
    );
  }

  if (height !== '') {
    return (
      <ImageHeight src={image} height={height} alt={name} />
    );
  }

  if (width !== '') {
    return (
      <ImageWidth src={image} width={width} alt={name} />
    );
  }

  if (size !== '') {
    return (
      <ImageSize src={image} size={size} alt={name} />
    );
  }

  return <ImageSize src={image} size={DEFAULT_SIZE} alt={name} />;
}

Logo.propTypes = {
  image: PropTypes.string.isRequired,
  height: PropTypes.string,
  name: PropTypes.string,
  size: PropTypes.string,
  width: PropTypes.string,
};

Logo.defaultProps = {
  height: '',
  name: 'Enosi',
  size: '',
  width: '',
};

export default Logo;
