import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';
import { Badge } from 'reactstrap';

import {
  PORTFOLIO_USER_ROLE_ADMIN, PORTFOLIO_USER_ROLE_OWNER,
  PORTFOLIO_USER_ROLE_STANDARD, PORTFOLIO_USER_ROLE_VIEWER,
} from 'src/util/constants';
import { getPortfolioUserRoleLabel } from 'src/util/i18n/helpers';

const colorMapping = {
  PORTFOLIO_USER_ROLE_OWNER: 'secondary',
  PORTFOLIO_USER_ROLE_ADMIN: 'secondary',
  PORTFOLIO_USER_ROLE_STANDARD: 'primary',
  PORTFOLIO_USER_ROLE_VIEWER: 'mid',
};

/**
 * Portfolio user role badge
 * @param {object} props
 * @param {PORTFOLIO_USER_ROLE_OWNER | PORTFOLIO_USER_ROLE_ADMIN
 * | PORTFOLIO_USER_ROLE_STANDARD| PORTFOLIO_USER_ROLE_VIEWER} props.userRole
 * @returns {React.ReactComponentElement} - BadgeUserType component
 */
function BadgePortfolioUserRole({ userRole }) {
  if (!userRole) { return (null); }
  const intl = useIntl();

  const color = colorMapping[userRole] || 'mid';
  const label = getPortfolioUserRoleLabel(intl, userRole);

  return (
    <Badge color={color} className="badge-outline">{label?.toUpperCase()}</Badge>
  );
}

BadgePortfolioUserRole.propTypes = {
  userRole: PropTypes.string.isRequired,
};

export default BadgePortfolioUserRole;
