import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const Primary = styled.div.attrs({
  className: 'fw-bold',
})``;

const Secondary = styled.div.attrs({
  className: 'fw-normal',
})`
  font-size: .75em;
`;

/**
 * Renders a list card title with a primary title and secondary address.
 * @param {object} props - The component props.
 * @param {string} props.title - The primary title to display.
 * @param {string | object} props.subTitle - The secondary address to display.
 * @returns {React.ReactComponentElement} The list card title component.
 */
function ListCardTitle(props) {
  const { title, subTitle } = props;

  return (
    <div>
      <Primary>{title}</Primary>
      <Secondary>{subTitle}</Secondary>
    </div>
  );
}

ListCardTitle.propTypes = {
  subTitle: PropTypes.oneOfType([
    PropTypes.object, // eslint-disable-line react/forbid-prop-types
    PropTypes.string,
  ]).isRequired,
  title: PropTypes.string.isRequired,
};

export default ListCardTitle;
