import Link from 'found/Link';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Card, CardHeader, Table,
} from 'reactstrap';

import BadgeActive from 'src/components/Badge/BadgeActive';
import UUID from 'src/components/UUID';

/**
 * @param {object} props
 * @returns {React.ReactElement} - AdminPortfolioDetailsCard component
 */
function AdminPortfolioDetailsCard(props) {
  if (!props) {
    return null;
  }
  const { portfolio } = props || {};
  const {
    active, uuid, createdAt, externalIdentifier, title, updatedAt,
  } = portfolio || {};
  const { start, finish } = active || {};

  return (
    <Card>
      <CardHeader>
        <FormattedMessage id="admin.admin_portfolio.admin_portfolio_show.portfolio_details.card_header.portfolio_details.label" defaultMessage="Portfolio details" />
      </CardHeader>
      <Table responsive>
        <tbody>
          <tr>
            <th scope="row" className="text-right">
              <FormattedMessage id="admin.admin_portfolio.admin_portfolio_show.portfolio_details.table.head.links.label" defaultMessage="Links" />
            </th>
            <td>
              <Link to={`/portfolios/${uuid}`}>
                <FormattedMessage id="admin.admin_portfolio.admin_portfolio_show.portfolio_details.table.customer_page.label" defaultMessage="Customer Page" />
              </Link>
            </td>
          </tr>
          <tr>
            <th scope="row" className="text-right">
              <FormattedMessage id="admin.admin_portfolio.admin_portfolio_show.portfolio_details.table.head.id.label" defaultMessage="ID" />
            </th>
            <td>
              <UUID uuid={uuid} />
            </td>
          </tr>
          <tr>
            <th scope="row" className="text-right">
              <FormattedMessage id="admin.admin_portfolio.admin_portfolio_show.portfolio_details.table.head.external_identifier.label" defaultMessage="External identifier" />
            </th>
            <td>{externalIdentifier}</td>
          </tr>
          <tr>
            <th scope="row" className="text-right">
              <FormattedMessage id="admin.admin_portfolio.admin_portfolio_show.portfolio_details.table.head.active_status.label" defaultMessage="Active status" />
            </th>
            <td>
              <BadgeActive start={start} finish={finish} />
            </td>
          </tr>
          <tr>
            <th scope="row" className="text-right">
              <FormattedMessage id="admin.admin_portfolio.admin_portfolio_show.portfolio_details.table.head.title.label" defaultMessage="Title" />
            </th>
            <td>{title}</td>
          </tr>
          <tr>
            <th scope="row" className="text-right">
              <FormattedMessage id="admin.admin_portfolio.admin_portfolio_show.portfolio_details.table.head.start.label" defaultMessage="Start" />
            </th>
            <td>
              {start && DateTime.fromSeconds(start).toLocaleString(DateTime.DATETIME_FULL)}
            </td>
          </tr>
          <tr>
            <th scope="row" className="text-right">
              <FormattedMessage id="admin.admin_portfolio.admin_portfolio_show.portfolio_details.table.head.finish.label" defaultMessage="Finish" />
            </th>
            <td>
              {finish && DateTime.fromSeconds(finish).toLocaleString(DateTime.DATETIME_FULL)}
            </td>
          </tr>
          <tr>
            <th scope="row" className="text-right">
              <FormattedMessage id="admin.admin_portfolio.admin_portfolio_show.portfolio_details.table.head.created_at.label" defaultMessage="Created at" />
            </th>
            <td>
              {createdAt && DateTime.fromSeconds(createdAt).toLocaleString(DateTime.DATETIME_FULL)}
            </td>
          </tr>
          <tr>
            <th scope="row" className="text-right">
              <FormattedMessage id="admin.admin_portfolio.admin_portfolio_show.portfolio_details.table.head.updated_at.label" defaultMessage="Updated at" />
            </th>
            <td>
              {updatedAt && DateTime.fromSeconds(updatedAt).toLocaleString(DateTime.DATETIME_FULL)}
            </td>
          </tr>
        </tbody>
      </Table>
    </Card>
  );
}

AdminPortfolioDetailsCard.propTypes = {
  portfolio: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    externalIdentifier: PropTypes.string.isRequired,
    active: PropTypes.shape({
      start: PropTypes.number.isRequired,
      finish: PropTypes.number,
    }),
    createdAt: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    updatedAt: PropTypes.number.isRequired,
  }).isRequired,
};

export default AdminPortfolioDetailsCard;
