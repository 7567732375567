import {
  COMMUNITY, CONTRACTED, NOMINATED, RESIDUAL, TRADE_TYPE_COMMUNITY,
  TRADE_TYPE_CONTRACTED, TRADE_TYPE_NOMINATED, TRADE_TYPE_RESIDUAL,
  TRADE_TYPE_UNSPECIFIED,
} from './constants';

/**
 * Returns the trade type based on the given type.
 * @param {TRADE_TYPE_CONTRACTED | TRADE_TYPE_NOMINATED
 * | TRADE_TYPE_COMMUNITY | TRADE_TYPE_RESIDUAL | string} type - The type of trade.
 * @returns {TRADE_TYPE_UNSPECIFIED | CONTRACTED
 * | NOMINATED | COMMUNITY |  RESIDUAL} - The corresponding trade type.
 */
const getTradeType = (type) => {
  if (!type) {
    return TRADE_TYPE_UNSPECIFIED;
  }
  switch (type) {
    case TRADE_TYPE_CONTRACTED:
      return CONTRACTED;
    case TRADE_TYPE_NOMINATED:
      return NOMINATED;
    case TRADE_TYPE_COMMUNITY:
      return COMMUNITY;
    default: // TRADE_TYPE_RESIDUAL
      return RESIDUAL;
  }
};

export default getTradeType;
