import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'reactstrap';

/**
 * Description
 * @param {object} props
 * @param {string} props.copyLoadMore - button's copy when there are more items to load
 * @param {string} props.copyNoMore - button's copy when there are no more items to load
 * @param {boolean} props.hasNext - whether there are more items to load
 * @param {Function} props.onClick - the function to be called when the button is clicked
 * @returns {React.ReactComponentElement} - LoadMoreButton
 */
function LoadMoreButton({
  copyLoadMore, copyNoMore, hasNext, onClick,
}) {
  if (hasNext === null || onClick === null || copyLoadMore === null || copyNoMore === null) {
    return null;
  }

  const copy = hasNext ? copyLoadMore : copyNoMore;

  return (
    <Button onClick={onClick} disabled={!hasNext}>
      {copy}
    </Button>
  );
}

export default LoadMoreButton;

LoadMoreButton.propTypes = {
  copyLoadMore: PropTypes.string.isRequired,
  copyNoMore: PropTypes.string.isRequired,
  hasNext: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};
