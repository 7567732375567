import { DateTime } from 'luxon';

/**
 * Checks if the given time range is currently active.
 * @param {object} options - The options object.
 * @param {number} [options.start] - The start time in seconds since the Unix epoch.
 * @param {number} [options.finish] - The end time in seconds since the Unix epoch.
 * @returns {boolean} - True if the current time is within the given time range, false otherwise.
 */
const isActive = ({ start, finish }) => {
  const now = DateTime.local();
  const s = start ? DateTime.fromSeconds(start) : null;
  const f = finish ? DateTime.fromSeconds(finish) : null;

  return !((s && s > now) || (f && f < now));
};

export default isActive;
