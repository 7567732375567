import {
  faBell, faCaretDown, faCity, faCog, faHome, faSignOutAlt, faToolbox,
  faUserCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, withRouter } from 'found';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';
import {
  Collapse, Container, Dropdown, DropdownItem, DropdownMenu, DropdownToggle,
  Nav, NavItem, Navbar, NavbarToggler,
} from 'reactstrap';
import styled from 'styled-components';

import AnonymousNavigation from 'src/components/Navigation/AnonymousNavigation';
import NavigationLogo from 'src/components/Navigation/NavigationLogo';
import { APIConfig } from 'src/config';
import { AvatarItem } from 'src/enosikit/components/Avatar/index';
import { INHIBIT_PORTFOLIO_VIEWS, USER_TYPE_ADMIN, USER_TYPE_CUSTOMER } from 'src/util/constants';

const RightNav = styled(Nav).attrs({
  className: 'ms-auto',
})``;

const LeftNav = styled(Nav).attrs({
  className: 'me-auto',
})``;

class CustomerNavigation extends React.Component {
  static proposalsCount(ruleConn) {
    if (!ruleConn) { return '0'; }
    const { edges } = ruleConn;
    if (!edges) { return '0'; }
    const count = edges.length;
    if (count > 9) {
      return '9+';
    }
    return `${count}`;
  }

  static renderRootNavigation() {
    const inhibitPortfolioViews = APIConfig().feature(INHIBIT_PORTFOLIO_VIEWS);
    return (
      <>
        <NavItem>
          <Link to="/properties" className="nav-link">
            <FontAwesomeIcon icon={faHome} size="1x" className="me-2" />
            <FormattedMessage id="navigation.customer_navigation.properties.link.label" defaultMessage="Properties" />
          </Link>
        </NavItem>
        {!inhibitPortfolioViews && (
        <NavItem>
          <Link to="/portfolios" className="nav-link">
            <FontAwesomeIcon icon={faCity} size="1x" className="me-2" />
            <FormattedMessage id="navigation.customer_navigation.portfolios.link.label" defaultMessage="Portfolios" />
          </Link>
        </NavItem>
        )}
      </>
    );
  }

  static renderAdminNavigation(type) {
    if (type !== USER_TYPE_ADMIN) {
      return null;
    }
    return (
      <NavItem>
        <Link to="/admin" className="nav-link">
          <FontAwesomeIcon icon={faToolbox} size="1x" className="me-2" />
          <FormattedMessage id="navigation.customer_navigation.admin.link.label" defaultMessage="Admin" />
        </Link>
      </NavItem>
    );
  }

  constructor(props) {
    super(props);

    this.state = {
      navbarOpen: false,
      ProfileDropdownOpen: false,
      PropertyDropdownOpen: false,
    };
  }

  toggleNavbar = () => {
    this.setState((prevState) => ({ navbarOpen: !prevState.navbarOpen }));
  };

  toggleProfile = () => {
    this.setState((prevState) => ({
      ProfileDropdownOpen: !prevState.ProfileDropdownOpen,
    }));
  };

  // TODO: verify use.
  // eslint-disable-next-line react/no-unused-class-component-methods
  toggleProperty = () => {
    this.setState((prevState) => ({
      PropertyDropdownOpen: !prevState.PropertyDropdownOpen,
    }));
  };

  render() {
    const { viewer } = this.props;
    const { navbarOpen, ProfileDropdownOpen } = this.state;

    const { proposals, viewerUser } = viewer;
    const {
      givenName, familyName, email, type,
    } = viewerUser;
    let name = [givenName, familyName].filter(Boolean).join(' ');
    if (name === '') {
      name = email;
    }

    if (!viewer) {
      return <AnonymousNavigation />;
    }

    const cobrand = APIConfig().cobrand();
    const primaryBrand = APIConfig().primaryBrand();

    return (
      <Navbar light expand="lg">
        <Container className="d-flex flex-wrap justify-content-between px-0 px-sm-3">
          <Link to="/" className="navbar-brand">
            <NavigationLogo cobrand={cobrand} primaryBrand={primaryBrand} />
          </Link>
          <NavbarToggler onClick={this.toggleNavbar} />
          <Collapse isOpen={navbarOpen} navbar>
            <LeftNav navbar>
              {CustomerNavigation.renderRootNavigation()}
              {CustomerNavigation.renderAdminNavigation(type)}
            </LeftNav>
            <RightNav navbar>
              {type === USER_TYPE_CUSTOMER && (
                <NavItem>
                  <Link to="/trade-rules" className="nav-link">
                    <span className="fa-layers fa-fw" style={{ fontSize: '1.5rem', marginTop: '0.6rem' }}>
                      <FontAwesomeIcon icon={faBell} />
                      <span className="fa-layers-counter danger fw-bold" style={{ fontSize: '2.5rem', paddingTop: '.8rem' }}>{CustomerNavigation.proposalsCount(proposals)}</span>
                    </span>
                  </Link>
                </NavItem>
              )}
              <Dropdown
                nav
                inNavbar
                isOpen={ProfileDropdownOpen}
                toggle={this.toggleProfile}
              >
                <DropdownToggle nav>
                  <AvatarItem
                    primaryText={name}
                    secondaryText={email}
                    avatarImage={
                      <FontAwesomeIcon icon={faUserCircle} size="3x" />
                    }
                    className="d-inline-flex"
                  />
                  <FontAwesomeIcon
                    icon={faCaretDown}
                    size="1x"
                    className="ms-2"
                  />
                </DropdownToggle>
                <DropdownMenu end>
                  <Link
                    to="/profile/settings"
                    activeClassName="active"
                    className="dropdown-item"
                    role="menuitem"
                  >
                    <FontAwesomeIcon icon={faCog} className="me-2" />
                    <FormattedMessage id="navigation.customer_navigation.current_user_settings.link.label" defaultMessage="Profile settings" />
                  </Link>
                  <DropdownItem divider />
                  <Link
                    to="/logout"
                    activeClassName="active"
                    className="dropdown-item"
                    role="menuitem"
                  >
                    <FontAwesomeIcon icon={faSignOutAlt} className="me-2" />
                    <FormattedMessage id="navigation.customer_navigation.log_out.link.label" defaultMessage="Log out" />
                  </Link>
                </DropdownMenu>
              </Dropdown>
            </RightNav>
          </Collapse>
        </Container>
      </Navbar>
    );
  }
}

CustomerNavigation.propTypes = {
  viewer: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

CustomerNavigation.defaultProps = {
  viewer: null,
};

export default withRouter(
  createFragmentContainer(CustomerNavigation, {
    viewer: graphql`
      fragment CustomerNavigation_viewer on Viewer {
        id
        viewerUser {
          email
          givenName
          familyName
          type
        }
        proposals: rules(first:10, type: TRADE_TYPE_NOMINATED, state: TRADE_RULE_STATE_PROPOSED) {
          edges {
            node {
              uuid
            }
          }
        }
      }
    `,
    properties: graphql`
      fragment CustomerNavigation_properties on PropertyConnection {
        edges {
          node {
            uuid
            title
          }
        }
      }
    `,
  }),
);
