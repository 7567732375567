import Big from 'big.js';
import { DateTime } from 'luxon';

import { APIConfig } from 'src/config';
import {
  BUY, CARBON, DIRECTIONS, PLATFORM_MODE_REBATE,
  SELL, TIME_ZONE_SYSTEM, TRADE_DIRECTION_BUY,
  TRADE_DIRECTION_SELL, TRADE_DIRECTION_UNSPECIFIED,
  TRADE_TYPE_COMMUNITY, TRADE_TYPE_CONTRACTED,
  TRADE_TYPE_NOMINATED, TRADE_TYPE_RESIDUAL,
  TradeTypes, VALUE, VOLUME,
} from 'src/util/constants';
import isNumber from 'src/util/math';
import getTradeType from 'src/util/trade';

/**
 * Sums specific element in an object
 * @param {Array} data - tradeSetSummaries, untradedDataAggregates
 * @param {string} key  - value, volume, carbon
 * @returns {Big | NaN} Big number - sum of the specific element
 */
export const sumValuesByObjKeys = (data, key) => {
  if (!data) return NaN;

  return (data.reduce(
    (acc, element) => (element[key] ? acc.plus(element[key]) : acc),
    Big(0),
  ));
};

/**
 * Transforms the main data into the format required for the
 * trade summary component on the property dashboard.
 * @param {object} mainData
 * @returns {object} - trade summary
 */
export const getTradeSummary = (mainData) => {
  const finalResp = {
    buy: {},
    sell: {},
  };

  if (!mainData) return finalResp;

  const IS_MODE_REBATE = APIConfig().MODE === PLATFORM_MODE_REBATE;

  DIRECTIONS.forEach((dir) => {
    if (!mainData[dir]?.data) {
      return;
    }
    Object.keys(mainData[dir].data)?.forEach((timestamp) => {
      const {
        meterDataAggregates: meters,
        tradeSetSummaries: trades,
        untradedDataAggregates: untradedData,
      } = mainData[dir].data[timestamp] || {};
      if (!meters) {
        return;
      }
      const meterValue = sumValuesByObjKeys(Object.values(meters), VALUE);
      const untradedVolume = sumValuesByObjKeys(Object.values(untradedData), VOLUME);
      const untradedValue = sumValuesByObjKeys(Object.values(untradedData), VALUE);
      const energy = Number(meterValue) ? Big(meterValue) : Big(0);

      let tradedEnergy = Big(0);
      let tradedValue = Big(0);
      let totalResidual = Big(0);
      let rebatedEnergy = Big(0);
      let netDiffValue = Big(0);

      if (trades) {
        Object.keys(trades)?.forEach((tradeId) => {
          const {
            counterfactual, type, value = 0, volume = 0,
          } = trades[tradeId] || {};
          tradedEnergy = tradedEnergy.plus(volume);
          tradedValue = tradedValue.plus(value);
          const isResidual = type === TRADE_TYPE_RESIDUAL;
          if (isResidual) {
            netDiffValue = netDiffValue.plus(value);
          } else if (counterfactual && Number(counterfactual)) {
            netDiffValue = netDiffValue.plus(counterfactual);
          }
          if (IS_MODE_REBATE && isResidual) {
            totalResidual = totalResidual.plus(volume);
          }
        });
      }

      if (IS_MODE_REBATE) {
        rebatedEnergy = tradedEnergy.minus(totalResidual);
      }

      finalResp[dir][timestamp] = {
        energy: Number(energy),
        tradedEnergy: Number(tradedEnergy),
        tradedValue: Number(tradedValue),
        rebatedEnergy: Number(rebatedEnergy),
        netDiffValue: Number(netDiffValue),
        untradedVolume,
        untradedValue,
      };
    });
  });
  return finalResp;
};

/**
 * Transforms a main data set into the data set used in the Chart Summary component.
 * The key parts of the main data set is that it needs to have trade summaries.
 * @param {object} mainData
 * @returns {object} chart summary data, split into buy and sell directions.
 */
export const getChartSummaryData = (mainData) => {
  const finalResp = { buy: {}, sell: {} };

  if (!mainData) return finalResp;

  const tradeSummary = getTradeSummary(mainData);

  Object.keys(tradeSummary)?.forEach((dir) => {
    const dirSummary = {
      energy: Big(0),
      netDiffValue: Big(0),
      rebatedEnergy: Big(0),
      tradedEnergy: Big(0),
      tradedValue: Big(0),
      untradedCount: 0,
      untradedValue: Big(0),
      untradedVolume: Big(0),
    };

    Object.values(tradeSummary[dir])?.forEach((datum) => {
      const {
        energy, netDiffValue, rebatedEnergy,
        tradedEnergy, tradedValue, untradedValue, untradedVolume,
      } = datum;

      dirSummary.energy = dirSummary.energy.plus(energy);
      dirSummary.netDiffValue = dirSummary.netDiffValue.plus(netDiffValue);
      dirSummary.rebatedEnergy = dirSummary.rebatedEnergy.plus(rebatedEnergy);
      dirSummary.tradedEnergy = dirSummary.tradedEnergy.plus(tradedEnergy);
      dirSummary.tradedValue = dirSummary.tradedValue.plus(tradedValue);
      dirSummary.untradedValue = dirSummary.untradedValue.plus(untradedValue);
      dirSummary.untradedVolume = dirSummary.untradedVolume.plus(untradedVolume);

      if (Number(untradedVolume) > 0) {
        dirSummary.untradedCount += 1;
      }
    });

    finalResp[dir] = {
      energy: Number(dirSummary.energy),
      netDiffValue: Number(dirSummary.netDiffValue),
      rebatedEnergy: Number(dirSummary.rebatedEnergy),
      tradedEnergy: Number(dirSummary.tradedEnergy),
      tradedValue: Number(dirSummary.tradedValue),
      untradedCount: Number(dirSummary.untradedCount),
      untradedValue: Number(dirSummary.untradedValue),
      untradedVolume: Number(dirSummary.untradedVolume),
    };
  });

  return finalResp;
};

/**
 * Converts a timestamp to an ISO string representation in UTC.
 * @param {string} timestamp - The timestamp to convert, in milliseconds.
 * @returns {string} - The timestamp as an ISO string in UTC.
 */
export const timestampToIsoString = (timestamp) => {
  const ts = DateTime.fromMillis(parseFloat(timestamp));
  return ts.toUTC().toString();
};

/**
 * Returns the correct counter party based on the trade type and direction
 * @param {object} rule - trade rule
 * @param {TRADE_TYPE_CONTRACTED | TRADE_TYPE_NOMINATED|
 * TRADE_TYPE_COMMUNITY| TRADE_TYPE_RESIDUAL} type - type of trade
 * @param {TRADE_DIRECTION_BUY | TRADE_DIRECTION_SELL} direction - direction of trade
 * @returns {object | null} - buyer, seller, null
 */
export const getCounterParty = (rule, type, direction) => {
  if (!rule || !type || !direction) return null;

  if (type !== TRADE_TYPE_CONTRACTED && type !== TRADE_TYPE_NOMINATED) return null;

  return direction === TRADE_DIRECTION_BUY ? rule.seller : rule.buyer;
};

/**
 * Returns the trade direction based on the given direction.
 * @param {BUY | SELL} direction - The direction of the trade.
 * @returns {TRADE_DIRECTION_UNSPECIFIED |TRADE_DIRECTION_BUY |
 *  TRADE_DIRECTION_SELL } - The trade direction.
 */
export const getDirection = (direction) => {
  switch (direction) {
    case BUY:
      return TRADE_DIRECTION_BUY;
    case SELL:
      return TRADE_DIRECTION_SELL;
    default:
      return TRADE_DIRECTION_UNSPECIFIED;
  }
};

/**
 * Retrieves the property associated with a trader.
 * @param {object} trader - The trader object.
 * @returns {object | null} - The property object if found, otherwise null.
 */
export const getTraderProperty = (trader) => (trader?.tradePoint?.meter?.property || null);

/**
 * Get the entity id, falling to the default value in its absence.
 * @param {object} entity
 * @param {string} defaultIdValue
 * @returns {string} - entity id.
 */
export const getEntityId = (entity, defaultIdValue) => (entity
  ? entity.uuid : defaultIdValue) || null;

/**
 * Builds chart meter data for aggregated view.
 * @param {object} mainData - chart primary data.
 * @returns {object} - meter data (timestamp based).
 */
export const buildChartMeterDataAggregated = (mainData) => {
  const finalResp = {};
  if (!mainData) return finalResp;
  let value = Big(0);
  let carbon = Big(0);

  const timezone = mainData.property?.timezone || TIME_ZONE_SYSTEM;

  DIRECTIONS.forEach((dir) => {
    finalResp[dir] = {};
    let flags = [];
    if (mainData[dir]) {
      Object.keys(mainData[dir].data).forEach((timestamp) => {
        const { meterDataAggregates } = mainData[dir].data[timestamp];
        if (Object.keys(meterDataAggregates).length === 1) {
          const meterId = Object.keys(meterDataAggregates)[0];
          carbon = Big(meterDataAggregates[meterId].carbon);
          value = meterDataAggregates[meterId].value;
          flags = meterDataAggregates[meterId].flags;
        }
        if (Object.keys(meterDataAggregates).length > 1) {
          value = sumValuesByObjKeys(Object.values(meterDataAggregates), VALUE);
          carbon = sumValuesByObjKeys(Object.values(meterDataAggregates), CARBON);
          Object.keys(meterDataAggregates).forEach((meterId) => {
            meterDataAggregates[meterId].flags.forEach((flag) => {
              if (flags.map((f) => f.identifier).indexOf(flag.identifier) === -1) {
                flags.push(flag);
              }
            });
          });
        }
        const formattedTimestamp = timestampToIsoString(timestamp);
        const meterDataSeries = {
          timestamp: DateTime.fromISO(formattedTimestamp, { zone: timezone }),
          value: Number(value),
          flags,
          carbon,
        };
        finalResp[dir][formattedTimestamp] = meterDataSeries;
      });
    }
  });

  return finalResp;
};

/**
 * Returns the buy/sell aggregated values and volumes of the untraded energy from main data.
 * @param {object} mainData
 * @returns {{
 * buy: {volume: number, value: number},
 * sell: {volume: number, value: number}
 * } | object} The untraded object.
 */
export const getUntradedTotals = (mainData) => {
  const tradeSummary = getTradeSummary(mainData);
  const finalResp = { buy: {}, sell: {} };

  Object.keys(tradeSummary).forEach((dir) => {
    finalResp[dir].value = Big(0);
    finalResp[dir].volume = Big(0);
    const finalIndex = Object.keys(tradeSummary[dir]).length - 1;

    Object.keys(tradeSummary[dir]).forEach((timestamp, index) => {
      const { untradedValue: value, untradedVolume: volume } = tradeSummary[dir][timestamp];
      const aggregatedValue = finalResp[dir].value.plus(value);
      const aggregatedVolume = finalResp[dir].volume.plus(volume);
      finalResp[dir].value = (index === finalIndex) ? Number(aggregatedValue) : aggregatedValue;
      finalResp[dir].volume = (index === finalIndex) ? Number(aggregatedVolume) : aggregatedVolume;
    });
  });

  return finalResp;
};

/**
 * Prepares the trade summary data, from a given input (mainData).
 * @param {object} mainData
 * @returns {object} an opinionated data structure for use in the trade summary on
 * the property show page.
 */
export const buildTradeSummaryData = (mainData) => {
  const resp = {
    buy: {
      contracted: [],
      nominated: [],
      community: [],
      residual: [],
      untraded: [],
    },
    sell: {
      contracted: [],
      nominated: [],
      community: [],
      residual: [],
      untraded: [],
    },
  };

  if (!mainData) {
    return resp;
  }

  const trades = {
    buy: {}, sell: {},
  };

  DIRECTIONS.forEach((dir) => {
    const { data, rules } = mainData[dir];
    if (data) {
      const tradeSetSummaries = Object.keys(data)?.map(
        (timestamp) => data[timestamp].tradeSetSummaries,
      ).filter((item) => item);
      if (tradeSetSummaries?.length === 0) {
        return;
      }
      tradeSetSummaries?.forEach((tradeSet) => {
        Object.keys(tradeSet)?.forEach((ruleId) => {
          const {
            counterfactual, value, volume,
          } = tradeSet[ruleId];
          const finalCounterfactual = isNumber(counterfactual) ? counterfactual : 0;
          if (trades[dir][ruleId]) {
            const {
              value: tradeValue, volume: tradeVolume,
              counterfactual: tradeCounterfactual,
            } = trades[dir][ruleId];
            trades[dir][ruleId] = {
              ...trades[dir][ruleId],
              value: Number(Big(tradeValue).plus(value)),
              volume: Number(Big(tradeVolume).plus(volume)),
              counterfactual: Number(Big(tradeCounterfactual).plus(finalCounterfactual)),
            };
          } else {
            const rule = rules[ruleId];
            trades[dir][ruleId] = {
              value, volume, counterfactual: finalCounterfactual, rule,
            };
          }
        });
      });
    }
    TradeTypes.forEach((tradeType) => {
      const typeKey = getTradeType(tradeType);
      Object.keys(trades[dir])?.forEach((tradeRuleId) => {
        const type = trades[dir][tradeRuleId]?.rule?.tradeType;
        if (type === tradeType) {
          resp[dir][typeKey].push(trades[dir][tradeRuleId]);
        }
      });
    });
  });

  const untraded = getUntradedTotals(mainData);
  if (untraded?.buy?.volume > 0) {
    resp.buy.untraded.push(untraded.buy);
  }

  if (untraded?.sell?.volume > 0) {
    resp.sell.untraded.push(untraded.sell);
  }
  return resp;
};
