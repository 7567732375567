/**
 * @generated SignedSource<<51edad89e40ce7b91e6709895929608f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "meterId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "uuid"
},
v2 = [
  {
    "kind": "Variable",
    "name": "uuid",
    "variableName": "uuid"
  }
],
v3 = [
  {
    "kind": "Variable",
    "name": "uuid",
    "variableName": "meterId"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uuid",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "state",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v8 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "userId",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "communityId",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "residualId",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "TradePoint",
    "kind": "LinkedField",
    "name": "tradePoint",
    "plural": false,
    "selections": [
      (v4/*: any*/),
      (v7/*: any*/)
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "routes_TradeRuleSetCommunityFlat_Query",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Property",
        "kind": "LinkedField",
        "name": "property",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "TradeRuleSetCommunityFlat_property"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "Meter",
        "kind": "LinkedField",
        "name": "meter",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "TradeRuleSetCommunityFlat_meter"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "routes_TradeRuleSetCommunityFlat_Query",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Property",
        "kind": "LinkedField",
        "name": "property",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Address",
            "kind": "LinkedField",
            "name": "address",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "line1",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "line2",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "city",
                "storageKey": null
              },
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "postcode",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Gps",
                "kind": "LinkedField",
                "name": "gps",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "latitude",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "longitude",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v7/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "timezone",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "publicHolidayRegion",
            "storageKey": null
          },
          (v7/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "Meter",
        "kind": "LinkedField",
        "name": "meter",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "identifier",
            "storageKey": null
          },
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "tradePointId",
            "storageKey": null
          },
          {
            "alias": "communityRules",
            "args": [
              {
                "kind": "Literal",
                "name": "first",
                "value": 500
              },
              {
                "kind": "Literal",
                "name": "state",
                "value": "TRADE_RULE_STATE_ACCEPTED"
              },
              {
                "kind": "Literal",
                "name": "type",
                "value": "TRADE_TYPE_COMMUNITY"
              }
            ],
            "concreteType": "TradeRuleConnection",
            "kind": "LinkedField",
            "name": "rules",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "TradeRuleEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TradeRule",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "priority",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "tradeType",
                        "storageKey": null
                      },
                      (v6/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Trader",
                        "kind": "LinkedField",
                        "name": "buyer",
                        "plural": false,
                        "selections": (v8/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Trader",
                        "kind": "LinkedField",
                        "name": "seller",
                        "plural": false,
                        "selections": (v8/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ClauseConnection",
                        "kind": "LinkedField",
                        "name": "clauses",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ClauseEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Clause",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "price",
                                    "storageKey": null
                                  },
                                  (v7/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v7/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "rules(first:500,state:\"TRADE_RULE_STATE_ACCEPTED\",type:\"TRADE_TYPE_COMMUNITY\")"
          },
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e28e719f320bfb582590d085fa01cf46",
    "id": null,
    "metadata": {},
    "name": "routes_TradeRuleSetCommunityFlat_Query",
    "operationKind": "query",
    "text": "query routes_TradeRuleSetCommunityFlat_Query(\n  $uuid: ID!\n  $meterId: ID!\n) {\n  property(uuid: $uuid) {\n    ...TradeRuleSetCommunityFlat_property\n    id\n  }\n  meter(uuid: $meterId) {\n    ...TradeRuleSetCommunityFlat_meter\n    id\n  }\n}\n\nfragment TradeRuleSetCommunityFlatForm_meter on Meter {\n  uuid\n  identifier\n  title\n  tradePointId\n  communityRules: rules(first: 500, type: TRADE_TYPE_COMMUNITY, state: TRADE_RULE_STATE_ACCEPTED) {\n    edges {\n      node {\n        uuid\n        priority\n        tradeType\n        state\n        buyer {\n          userId\n          communityId\n          residualId\n          tradePoint {\n            uuid\n            id\n          }\n        }\n        seller {\n          userId\n          communityId\n          residualId\n          tradePoint {\n            uuid\n            id\n          }\n        }\n        clauses {\n          edges {\n            node {\n              price\n              id\n            }\n          }\n        }\n        id\n      }\n    }\n  }\n}\n\nfragment TradeRuleSetCommunityFlatForm_property on Property {\n  uuid\n  timezone\n  publicHolidayRegion\n}\n\nfragment TradeRuleSetCommunityFlat_meter on Meter {\n  uuid\n  identifier\n  title\n  tradePointId\n  ...TradeRuleSetCommunityFlatForm_meter\n}\n\nfragment TradeRuleSetCommunityFlat_property on Property {\n  uuid\n  title\n  address {\n    line1\n    line2\n    city\n    state\n    postcode\n    gps {\n      latitude\n      longitude\n    }\n    id\n  }\n  ...TradeRuleSetCommunityFlatForm_property\n}\n"
  }
};
})();

node.hash = "34ccb3a2ac4301719a7f728ee546c097";

module.exports = node;
