/**
 * @generated SignedSource<<bbe47c80d2d19484c4487c083272d263>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "rules"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "tradePointId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "rules",
    "variableName": "rules"
  },
  {
    "kind": "Variable",
    "name": "tradePointId",
    "variableName": "tradePointId"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uuid",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "PrioritizeNominatedTradesMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "TradeRule",
        "kind": "LinkedField",
        "name": "prioritizeNominatedTrades",
        "plural": true,
        "selections": [
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "PrioritizeNominatedTradesMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "TradeRule",
        "kind": "LinkedField",
        "name": "prioritizeNominatedTrades",
        "plural": true,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "eacd3e82bb62cfd6b02d772fc1ad6a4f",
    "id": null,
    "metadata": {},
    "name": "PrioritizeNominatedTradesMutation",
    "operationKind": "mutation",
    "text": "mutation PrioritizeNominatedTradesMutation(\n  $tradePointId: ID!\n  $rules: [RuleWithPriority!]!\n) {\n  prioritizeNominatedTrades(tradePointId: $tradePointId, rules: $rules) {\n    uuid\n    id\n  }\n}\n"
  }
};
})();

node.hash = "6315288398d8930dd0acacf5df124564";

module.exports = node;
