import { commitMutation, graphql } from 'react-relay';

import environment from 'src/util/environment';

const mutation = graphql`
  mutation PropertySettingsUpdateMutation($uuid: ID!, $input: PropertySettingsUpdateInput!) {
    propertySettingsUpdate(uuid: $uuid, input: $input) {
      id
      uuid
      externalIdentifier
      title
      active { start finish }
      createdAt
      updatedAt
    }
  }
`;

const PropertySettingsUpdateMutation = (uuid, input, onCompleted, onError) => {
  const variables = { uuid, input };

  commitMutation(
    environment,
    {
      mutation,
      variables,
      onCompleted,
      onError,
    },
  );
};

export default PropertySettingsUpdateMutation;
