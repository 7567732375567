import { config } from '@fortawesome/fontawesome-svg-core';
import Link from 'found/Link';
import PropTypes from 'prop-types';
import React from 'react';
import { NavItem } from 'reactstrap';

// Fontawesome CSS needs to be loaded?
config.autoAddCss = true;

/**
 * Description
 * @param {object} props
 * @returns {React.ReactComponentElement} - NavigationItem component
 */
function NavigationItem(props) {
  const {
    text, path, icon,
  } = props;

  return (
    <NavItem>
      <Link to={path} className="nav-link" activeClassName="active">
        {icon}
        &nbsp;
        <span className="text">
          {text}
        </span>
      </Link>
    </NavItem>
  );
}

NavigationItem.propTypes = {
  text: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  icon: PropTypes.node,
};

NavigationItem.defaultProps = {
  icon: null,
};

export default NavigationItem;
