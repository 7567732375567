import PropTypes from 'prop-types';
import React, { Fragment, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';
import { graphql, useFragment } from 'react-relay';

import Loading from 'src/components/Loading';
import Breadcrumbs from 'src/enosikit/components/Breadcrumbs';
import Heading from 'src/enosikit/components/Heading';
import UserDetailsUpdateMutation from 'src/mutations/UserDetailsUpdateMutation';
import FlashesStore from 'src/stores/FlashesStore';

import ProfileSettingsForm from './ProfileSettingsForm';

const graphqlViewer = graphql`
  fragment ProfileSettings_viewer on Viewer {
    id
    viewerUser {
      uuid
      email
      givenName
      familyName
      active { start finish }
      updatedAt
      ...ProfileSettingsForm_viewerUser
    }
  }
`;

/**
 * Profile settings.
 * @param {object} props
 * @param {object} props.viewer
 * @returns {Fragment} react fragment.
 */
export default function ProfileSettings({ viewer }) {
  if (!viewer) {
    return <Loading />;
  }

  // Data.
  const data = useFragment(graphqlViewer, viewer);

  const intl = useIntl();

  // State management.
  const [processing, setProcessing] = useState(false);

  /**
   * Update handler when there is a success response
   * @param {object} response
   * @param {object} errors
   */
  const handleUpdateSuccess = (response, errors) => {
    setProcessing(false);
    const msgSuccess = intl.formatMessage({ id: 'profile.profile_settings.form.response_message.success', defaultMessage: 'Updated your settings' });

    if (errors && errors.length > 0) {
      errors.forEach((err) => FlashesStore.flash(FlashesStore.ERROR, {
        message: FlashesStore.ERROR,
        source: { errors: [err] },
      }));
      return;
    }
    FlashesStore.flash(FlashesStore.SUCCESS, msgSuccess);
  };

  /**
   * Update handler when there is a failure response
   * @param {object} err
   */
  const handleUpdateFailure = (err) => {
    setProcessing(false);
    FlashesStore.flash(FlashesStore.ERROR, err);
  };

  /**
   * Show notification when there is no update required
   */
  const handleUpdateNotRequired = () => {
    const msgNoUpdateRequired = intl.formatMessage({ id: 'profile.profile_settings.profile_settings_form.state.no_update_required', defaultMessage: 'No update required.' });
    FlashesStore.flash(FlashesStore.INFO, msgNoUpdateRequired);
  };

  /**
   * Call update mutuation with the user input details
   * @param {object} input
   */
  const handleUpdate = (input) => {
    setProcessing(true);
    FlashesStore.reset();
    UserDetailsUpdateMutation(input, handleUpdateSuccess, handleUpdateFailure);
  };

  // Return.
  const { viewerUser } = data;
  const { email } = viewerUser;

  const pageTitle = intl.formatMessage({ id: 'profile.profile_settings.page.title', defaultMessage: 'Enosi - manage my details' });
  const breadcrumbsProfile = intl.formatMessage({ id: 'profile.profile_settings.breadcrumbs.profile', defaultMessage: 'Profile' });
  const breadcrumbsSettings = intl.formatMessage({ id: 'profile.profile_settings.breadcrumbs.settings', defaultMessage: 'Settings' });
  const headingProfile = intl.formatMessage({ id: 'profile.profile_settings.heading.profile', defaultMessage: 'Profile' });
  const headingSettings = intl.formatMessage({ id: 'profile.profile_settings.heading.settings', defaultMessage: 'Settings' });

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{pageTitle}</title>
      </Helmet>

      <Breadcrumbs
        breadcrumbs={[
          { name: breadcrumbsProfile, path: '/profile/settings' },
          { name: breadcrumbsSettings },
        ]}
      />
      <Heading
        title={headingProfile}
        context={headingSettings}
        subtitle={email}
      />

      <ProfileSettingsForm
        viewerUser={viewerUser}
        handleUpdate={handleUpdate}
        processing={processing}
        handleUpdateNotRequired={handleUpdateNotRequired}
      />
    </>
  );
}

ProfileSettings.propTypes = {
  viewer: PropTypes.shape({
    id: PropTypes.string,
    viewerUser: PropTypes.shape({
      uuid: PropTypes.string,
      email: PropTypes.string,
      givenName: PropTypes.string,
      familyName: PropTypes.string,
      active: PropTypes.shape({
        start: PropTypes.number,
        finish: PropTypes.number,
      }),
      updatedAt: PropTypes.number,
    }),
  }),
};

ProfileSettings.defaultProps = {
  viewer: null,
};
