import { commitMutation, graphql } from 'react-relay';

import environment from 'src/util/environment';

const mutation = graphql`
  mutation AcceptNominatedTradeMutation($uuid: ID!, $tradePointId: String!) {
    acceptNominatedTrade(uuid: $uuid, tradePointId: $tradePointId) {
      id
      uuid
    }
  }
`;

const AcceptNominatedTradeMutation = (uuid, tradePointId, onCompleted, onError, formatMessage) => {
  const variables = { uuid, tradePointId };

  commitMutation(
    environment,
    {
      mutation,
      variables,
      onCompleted: (response) => { onCompleted(response, formatMessage); },
      onError,
      updater: (store) => {
        // Get the current user record from the store
        const ruleProxy = store.get(uuid);
        ruleProxy.setValue('TRADE_RULE_STATE_ACCEPTED', 'state');
      },
    },
  );
};

export default AcceptNominatedTradeMutation;
