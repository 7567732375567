import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';
import { Badge } from 'reactstrap';

import {
  TRADE_TYPE_COMMUNITY, TRADE_TYPE_CONTRACTED, TRADE_TYPE_NOMINATED, TRADE_TYPE_RESIDUAL,
} from 'src/util/constants';

/**
 * Trade type badge
 * @param {object} props
 * @param { TRADE_TYPE_COMMUNITY |
 * TRADE_TYPE_CONTRACTED|
 * TRADE_TYPE_NOMINATED|
 * TRADE_TYPE_RESIDUAL} props.type
 * @returns {React.ReactComponentElement} - BadgeTradeType component
 */
function BadgeTradeType({ type }) {
  if (!type) { return (null); }

  const intl = useIntl();

  const labelContracted = intl.formatMessage({ id: 'badge.badge_trade_type.contracted.label', defaultMessage: 'contracted' });
  const labelNominated = intl.formatMessage({ id: 'badge.badge_trade_type.nominated.label', defaultMessage: 'peer-to-peer' });
  const labelCommunity = intl.formatMessage({ id: 'badge.badge_trade_type.community.label', defaultMessage: 'community' });
  const labelResidual = intl.formatMessage({ id: 'badge.badge_trade_type.residual.label', defaultMessage: 'retailer default' });

  let label;

  switch (type) {
    case TRADE_TYPE_CONTRACTED:
      label = labelContracted;
      break;
    case TRADE_TYPE_NOMINATED:
      label = labelNominated;
      break;
    case TRADE_TYPE_COMMUNITY:
      label = labelCommunity;
      break;
    case TRADE_TYPE_RESIDUAL:
      label = labelResidual;
      break;
    default:
      label = '';
  }

  return (
    <Badge color="mid" className="badge-outline">{label?.toUpperCase()}</Badge>
  );
}

BadgeTradeType.propTypes = {
  type: PropTypes.string.isRequired,
};

export default BadgeTradeType;
