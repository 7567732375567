import Link from 'found/Link';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import React from 'react';

import BadgeActive from 'src/components/Badge/BadgeActive';
import Loading from 'src/components/Loading';
import UUID from 'src/components/UUID';

/**
 * Description
 * @param {object} props
 * @returns {React.ReactComponentElement} - AdminPropertyShowPrimaryBillingPoint component
 */
function AdminPropertyShowPrimaryBillingPoint(props) {
  if (!props) {
    return <Loading />;
  }

  const { primaryBillingPoint } = props;
  const { uuid, identifier, active } = primaryBillingPoint;
  const { start, finish } = active;

  return (
    <tr>
      <th scope="row" colSpan={2}>
        <Link to={`/admin/primary-billing-points/${uuid}`}>
          {identifier}
        </Link>
        <br />
        <UUID uuid={uuid} />
      </th>
      <td>
        <BadgeActive start={start} finish={finish} />
        <br />
        {start && DateTime.fromSeconds(start).toLocaleString(DateTime.DATETIME_FULL)}
        -
        {finish && DateTime.fromSeconds(finish).toLocaleString(DateTime.DATETIME_FULL)}
      </td>
    </tr>
  );
}

AdminPropertyShowPrimaryBillingPoint.propTypes = {
  primaryBillingPoint: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

AdminPropertyShowPrimaryBillingPoint.defaultProps = {
  primaryBillingPoint: null,
};

export default AdminPropertyShowPrimaryBillingPoint;
