import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import authenticateUser from 'src/auth';
import Flashes from 'src/components/Flashes';
import Loading from 'src/components/Loading';
import CustomerNavigation from 'src/components/Navigation/CustomerNavigation';
import Footer from 'src/enosikit/components/Footer';
import { withTracingHOC } from 'src/tracing';

/**
 * Description
 * @param {any} props
 * @returns {React.ReactComponentElement} - CustomerLayout component
 */
function CustomerLayout(props) {
  const {
    children, properties, router, viewer,
  } = props;

  const intl = useIntl();

  if (!authenticateUser(intl, viewer, null, router)) {
    return <Loading />;
  }

  const pageTitle = intl.formatMessage({ id: 'layout.customer_layout.page.title', defaultMessage: 'Enosi' });
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{pageTitle}</title>
      </Helmet>

      <div className="flex flex-column" style={{ minHeight: '80vh' }}>
        <CustomerNavigation viewer={viewer} properties={properties} />
        <section id="main">
          <Flashes />
          <div className="container">
            <div className="flex-auto">{children}</div>
          </div>
        </section>
      </div>

      <Footer versionUI={VERSION} />
    </>
  );
}

CustomerLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  properties: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  router: PropTypes.shape({
    replace: PropTypes.func,
  }).isRequired,
  viewer: PropTypes.shape({
    id: PropTypes.string,
    properties: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  }),
};

CustomerLayout.defaultProps = {
  properties: null,
  viewer: null,
};

export default withTracingHOC('CustomerLayout', createFragmentContainer(CustomerLayout, {
  viewer: graphql`
    fragment CustomerLayout_viewer on Viewer {
      id
      viewerUser {
        type
      }
      ...CustomerNavigation_viewer
      properties {
        ...CustomerNavigation_properties
      }
    }
  `,
}));
