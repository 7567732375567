import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import React from 'react';

import {
  BUY, DATA_AGGREGATE_BY_METER, DATA_AGGREGATE_BY_PORTFOLIO,
  DATA_AGGREGATE_BY_PROPERTY, DATA_GROUP_BY_COUNTERPARTY,
  DATA_GROUP_BY_TRADE_TYPE, SELL,
} from 'src/util/constants';

import StackedBarChart from './StackedBarChart';
import {
  METER, UNIT_CARBON, UNIT_CURRENCY, UNIT_ENERGY,
} from './chartConstants';
import { getUnitProps, showNoDataMsg } from '../helpers/common';

/**
 * Builds the summary ( for a selected time timestamp) for the tooltip
 * @param {object} data - meter data
 * @param {object} tooltipTimestamp
 * @param {string} unit - energy or carbon
 * @returns {object} - tooltip summary.
 */
const buildTooltipSummary = (data, tooltipTimestamp, unit) => {
  let finalData = null;
  if (data && Object.keys(data).length > 0) {
    Object.keys(data).forEach((timestamp) => {
      const { timestamp: meterTimestamp } = data[timestamp] || {};
      if (meterTimestamp?.ts === tooltipTimestamp?.ts) {
        const {
          value, carbon, flags: flagsData,
        } = data[timestamp];
        const y = unit === UNIT_ENERGY ? value : carbon;
        const flags = unit === UNIT_ENERGY ? flagsData : [];
        finalData = { x: meterTimestamp, value: y, flags };
      }
    });
  }
  return finalData;
};

/**
 * Build and returns the tooltip data
 * @param {object} data - meterData
 * @param {object} tooltipTimestamp
 * @param {any} unit - energy or carbon
 * @param {boolean} isByMeter - returns against meter level if true otherwise property level
 * @param {string} dir - sell or buy
 * @returns {object} - tooltip data.
 */
export const getTooltipData = (data, tooltipTimestamp, unit, isByMeter, dir) => {
  let finalData = null;
  if (tooltipTimestamp) {
    if (!isByMeter) {
      const tooltipData = data[dir];
      finalData = buildTooltipSummary(tooltipData, tooltipTimestamp, unit);
      return finalData;
    }
    finalData = {};
    Object.keys(data).forEach((key) => {
      const { title, identifier } = data[key];
      const tooltipData = data[key][dir];
      const resp = buildTooltipSummary(tooltipData, tooltipTimestamp, unit);
      if (resp && Object.keys(resp).length > 0) {
        finalData[key] = { ...resp, title, identifier };
      }
    });
  }
  return finalData;
};

/**
 * Build time based meter data
 * @param {object} data - meter data
 * @param {string} unit - energy or carbon
 * @returns {object} - chart meter data.
 */
const buildChartData = (data, unit) => {
  const finalResp = {};
  if (data && Object.keys(data).length > 0) {
    Object.keys(data).forEach((timestamp) => {
      const {
        timestamp: x, value: energy, carbon,
      } = data[timestamp];
      const finalValue = unit === UNIT_ENERGY ? energy : carbon;
      if (x) {
        finalResp[timestamp] = { x, y: Number(finalValue) };
      }
    });
  }

  return finalResp;
};

/**
 * Build and returns the data for meter chart
 * @param {object} data - meter data
 * @param {string} unit - energy or carbon
 * @param {boolean} isByMeter - returns against meter level if true otherwise property level
 * @returns {object} - chart data.
 */
export const getChartData = (data, unit, isByMeter) => {
  let dataConsumed = {};
  let dataGenerated = {};
  if (isByMeter) {
    Object.keys(data)?.forEach((key) => {
      const { buy, sell } = data[key];
      const buyData = buildChartData(buy, unit);
      const sellData = buildChartData(sell, unit);
      if (buyData && Object.keys(buyData).length > 0) {
        dataConsumed = { ...dataConsumed, [key]: buyData };
      }
      if (sellData && Object.keys(sellData).length > 0) {
        dataGenerated = { ...dataGenerated, [key]: sellData };
      }
    });
  } else {
    const { buy, sell } = data;
    dataConsumed = { aggregated: buildChartData(buy, unit) };
    dataGenerated = { aggregated: buildChartData(sell, unit) };
  }
  const offset = {};
  Object.keys(dataGenerated)?.forEach((key) => {
    const dataGeneratedByMeter = dataGenerated[key];
    if (Object.keys(dataGeneratedByMeter).length > 0) {
      Object.keys(dataGeneratedByMeter).forEach((timestamp) => {
        const { x, y } = dataGenerated[key][timestamp];
        offset[x] = { x, y: -y, y0: -y };
        if (dataConsumed[key] && !(dataConsumed[key][timestamp])) {
          dataConsumed[key][timestamp] = { x, y: 0 };
        }
      });
    }
  });

  Object.keys(dataConsumed).forEach((key) => {
    const dataConsumedByMeter = dataConsumed[key];
    if (Object.keys(dataConsumedByMeter).length > 0) {
      Object.keys(dataConsumedByMeter).forEach((timestamp) => {
        const { x, y } = dataConsumed[key][timestamp];
        offset[x] = { x, y: -y, y0: -y };
        if (dataGenerated[key] && !(dataGenerated[key][timestamp])) {
          dataGenerated[key][timestamp] = { x, y: 0 };
        }
      });
    }
  });
  return { dataConsumed, dataGenerated };
};

/**
 * Checks if an object has non-empty data for a given key.
 * @param {object} data - The object to check.
 * @param {string} key - The key to check for non-empty data.
 * @returns {boolean} - True if the object has non-empty data for the given key, false otherwise.
 */
export const hasNonEmptyData = (data, key) => {
  if (!data || !key) {
    return false;
  }
  // Check if the object has the key and it's not null or an empty object
  if (Object.prototype.hasOwnProperty.call(data, key)
    && data[key] && Object.keys(data[key]).length > 0) {
    return true;
  }

  // check nested objects
  const hasNestedNonEmptyData = Object.keys(data).some((k) => data[k] && typeof data[k] === 'object' && hasNonEmptyData(data[k], key));

  if (hasNestedNonEmptyData) {
    return true;
  }
  return false;
};

/**
 * Description
 * @param {any} props
 * @returns {React.ReactComponentElement} - ChartMeter component
 */
export default function ChartMeter(props) {
  const {
    aggregation, chartView, data, handleChartClick,
    hoverKeys, isByMeter, selectedKeys, tooltipLabelFunc,
    tooltipTimestamp, tooltipUpdate, unit, width,
  } = props;

  if (!data || Object.keys(data)?.length === 0
  || (!hasNonEmptyData(data, BUY) && !hasNonEmptyData(data, SELL))) {
    return showNoDataMsg(METER);
  }

  const {
    label: unitLabel, format: yAxisFormat,
    tooltipFormat,
  } = getUnitProps(unit);

  const { dataConsumed, dataGenerated } = getChartData(data, unit, isByMeter);

  const compareX = (a, b) => (a.x - b.x);

  const tooltipConsumed = getTooltipData(data, tooltipTimestamp, unit, isByMeter, 'buy');
  const tooltipGenerated = getTooltipData(data, tooltipTimestamp, unit, isByMeter, 'sell');

  const tooltipDateFormat = aggregation.match(/^PT\d+[HMS]/) ? DateTime.DATETIME_SHORT : DateTime.DATE_SHORT;

  // Logic to frame the chart data :  End

  const toolTipProps = {
    unitLabel,
    tooltipDateFormat,
    tooltipLabelFunc,
    tooltipData: { tooltipConsumed, tooltipGenerated },
    tooltipTimestamp,
    tooltipFormat,
    isCarbon: unit === UNIT_CARBON,
  };

  const stackBarProps = {
    data: { sell: dataGenerated, buy: dataConsumed },
    compareX,
    tooltipUpdate,
    handleChartClick,
    chartType: METER,
    hoverKeys,
    selectedKeys,
    isByMeter,
  };

  return (
    <StackedBarChart
      chartView={chartView}
      stackBarProps={stackBarProps}
      toolTipProps={toolTipProps}
      width={width}
      yAxisFormat={yAxisFormat}
      stepSize={aggregation}
    />
  );
}

ChartMeter.propTypes = {
  aggregation: PropTypes.string.isRequired,
  chartView: PropTypes.shape({
    groupBy: PropTypes.oneOf([
      DATA_GROUP_BY_COUNTERPARTY,
      DATA_GROUP_BY_TRADE_TYPE,
    ]).isRequired,
    aggregateBy: PropTypes.oneOf([
      DATA_AGGREGATE_BY_METER,
      DATA_AGGREGATE_BY_PORTFOLIO,
      DATA_AGGREGATE_BY_PROPERTY,
    ]).isRequired,
  }).isRequired,
  data: PropTypes.oneOfType([PropTypes.object]),
  handleChartClick: PropTypes.func.isRequired,
  hoverKeys: PropTypes.instanceOf(Array).isRequired,
  isByMeter: PropTypes.bool.isRequired,
  selectedKeys: PropTypes.instanceOf(Array).isRequired,
  tooltipLabelFunc: PropTypes.func.isRequired,
  tooltipTimestamp: PropTypes.instanceOf(DateTime),
  tooltipUpdate: PropTypes.func.isRequired,
  unit: PropTypes.oneOf([
    UNIT_CURRENCY,
    UNIT_ENERGY,
    UNIT_CARBON,
  ]).isRequired,
  width: PropTypes.number.isRequired,
};

ChartMeter.defaultProps = {
  tooltipTimestamp: null,
  data: undefined,
};
