/**
 * @generated SignedSource<<74d8b7421770e2480610cf002c08e663>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TradeRuleSetCommunityFlatForm_property",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "uuid",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timezone",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "publicHolidayRegion",
      "storageKey": null
    }
  ],
  "type": "Property",
  "abstractKey": null
};

node.hash = "80396b99cbbc61c5005e7e74137b1c72";

module.exports = node;
