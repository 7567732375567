import Link from 'found/Link';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { createFragmentContainer, graphql } from 'react-relay';
import {
  Card, CardHeader, Col, Row, Table,
} from 'reactstrap';

import BadgeActive from 'src/components/Badge/BadgeActive';
import Loading from 'src/components/Loading';
import UUID from 'src/components/UUID';
import Breadcrumbs from 'src/enosikit/components/Breadcrumbs';
import tariffTimeUnit from 'src/util/decorators/units';

/**
 * Description
 * @param {object} props
 * @returns {React.ReactElement} - AdminStandingChargeShow component
 */
function AdminStandingChargeShow(props) {
  if (!props) {
    return <Loading />;
  }

  const { standingCharge } = props;
  const {
    uuid, identifier, externalIdentifier, title, description, rate, timeUnit, createdAt, updatedAt,
    tariff,
  } = standingCharge;

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`Enosi Admin - Standing Charge - ${title}`}</title>
      </Helmet>

      <Breadcrumbs
        breadcrumbs={[
          { name: 'Admin', path: '/admin' },
          { name: 'Standing Charges', path: '/admin/standing-charges' },
          { name: title },
        ]}
      />

      <h1>
        {title}
      </h1>

      <Row className="clear-both">
        <Col xs={12} sm={6} md={4}>
          <Card className="mb-4">
            <CardHeader>
              Standing Charge details
            </CardHeader>
            <Table responsive>
              <tbody>
                <tr>
                  <th scope="row" className="text-right">ID</th>
                  <td>
                    <UUID uuid={uuid} />
                  </td>
                </tr>
                <tr>
                  <th scope="row" className="text-right">
                    <abbr title="External identifier">Ext ID</abbr>
                  </th>
                  <td>{externalIdentifier}</td>
                </tr>
                <tr>
                  <th scope="row" className="text-right">Title</th>
                  <td>{title}</td>
                </tr>
                <tr>
                  <th scope="row" className="text-right">Identifier</th>
                  <td>{identifier}</td>
                </tr>
                <tr>
                  <th scope="row" className="text-right">Description</th>
                  <td>{description}</td>
                </tr>
                <tr>
                  <th scope="row" className="text-right">Rate</th>
                  <td>
                    {`$${rate}`}
                    <abbr title={`per ${tariffTimeUnit(timeUnit)}`}>
                      {`/${tariffTimeUnit(timeUnit)}`}
                    </abbr>
                  </td>
                </tr>
                <tr>
                  <th scope="row" className="text-right">Standing unit</th>
                  <td>{timeUnit}</td>
                </tr>
                <tr>
                  <th scope="row" className="text-right">Tariff</th>
                  <td>
                    {tariff && (
                      <>
                        <Link to={`/admin/tariffs/${tariff.uuid}`}>
                          <strong>{tariff.title}</strong>
                        </Link>
                        <br />
                        <UUID uuid={tariff.uuid} />
                        <br />
                        <BadgeActive start={tariff.active.start} finish={tariff.active.finish} />
                      </>
                    )}
                  </td>
                </tr>
                <tr>
                  <th scope="row" className="text-right">Created at</th>
                  <td>
                    {createdAt
                      && DateTime.fromSeconds(createdAt).toLocaleString(DateTime.DATETIME_FULL)}
                  </td>
                </tr>
                <tr>
                  <th scope="row" className="text-right">Updated at</th>
                  <td>
                    {updatedAt
                      && DateTime.fromSeconds(updatedAt).toLocaleString(DateTime.DATETIME_FULL)}
                  </td>
                </tr>
              </tbody>
            </Table>
          </Card>
        </Col>
      </Row>
    </>
  );
}

AdminStandingChargeShow.propTypes = {
  relay: PropTypes.shape({
    hasMore: PropTypes.func,
    isLoading: PropTypes.func,
    loadMore: PropTypes.func,
  }).isRequired,
  standingCharge: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

AdminStandingChargeShow.defaultProps = {
  standingCharge: null,
};

export default createFragmentContainer(
  AdminStandingChargeShow,
  {
    standingCharge: graphql`
      fragment AdminStandingChargeShow_standingCharge on StandingCharge {
        uuid
        identifier
        externalIdentifier
        title
        description
        rate
        timeUnit
        createdAt
        updatedAt
        tariff {
          uuid
          title
          active { start finish }
        }
      }
    `,
  },
);
