/**
 * @generated SignedSource<<0f41e9b2e659638668601d802a401f97>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TradeRulePrioritizeNominatedList_property",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "uuid",
      "storageKey": null
    }
  ],
  "type": "Property",
  "abstractKey": null
};

node.hash = "108b9868e420b218790133c6df7ed9b8";

module.exports = node;
