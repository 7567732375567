import React from 'react';

import { TradeSummary } from 'src/enosikit/components';
import {
  BUY, DIRECTIONS, EXPORTS, IMPORTS, SELL,
} from 'src/util/constants';

/**
 * Converts a trade direction to the corresponding trade category – that is, import or export.
 * @param {BUY | SELL} direction
 * @returns {IMPORTS | EXPORTS} - The corresponding trade category, either 'imports' or 'exports'.
 * @throws {Error} - If the provided direction is not valid.
 */
export const tradeDirectionToCategory = (direction) => {
  switch (direction) {
    case BUY:
      return IMPORTS;
    case SELL:
      return EXPORTS;
    default:
      throw new Error(`direction '${direction}' is not valid`);
  }
};
/**
 * Renders a summary card component with the provided data.
 * @param {object} summaryData - an opinionated data structure for use in the trade summary on
 * the property show page.
 * @param {string} title - The title to be displayed in in the popover for untraded
 *  energy either property title of protfolio external identifier.
 * @param {boolean} hasCounterfactual - Whether the property or portfolio has a counterfactual.
 * @returns {React.ReactElement | null} - The cards for the TradeSummary component.
 */
export const renderSummaryCards = (summaryData, title, hasCounterfactual) => {
  if (!summaryData || !title) {
    return null;
  }
  return DIRECTIONS.map((type) => {
    const category = tradeDirectionToCategory(type);
    return (
      <TradeSummary
        key={type}
        category={category}
        data={summaryData[type]}
        hasCounterfactual={hasCounterfactual}
        title={title}
      />
    );
  });
};
