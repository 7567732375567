import { ACTIVE_STATE_ACTIVE, ACTIVE_STATE_INACTIVE, LIST_FILTER_ALL } from 'src/util/constants';
import isActive from 'src/util/isActive';

/**
 * Filters a portfolio item based on its active status.
 * @param {object} portfolio - The portfolio item to filter.
 * @param {ACTIVE_STATE_ACTIVE | ACTIVE_STATE_INACTIVE |LIST_FILTER_ALL} activeState
 * Whether portfolio is active or not {start, finish}
 * @returns {boolean} - Whether the portfolio item should be included in the filtered list.
 */
export const filterPortfolio = (portfolio, activeState) => {
  if (!portfolio?.active) return false;
  const isPortfolioActive = isActive(portfolio.active);

  switch (activeState) {
    case ACTIVE_STATE_ACTIVE:
      return isPortfolioActive;
    case ACTIVE_STATE_INACTIVE:
      return !isPortfolioActive;
    default: // LIST_FILTER_ALL
      return true;
  }
};

/**
 * Checks if the provided `portfolios` object has any edges.
 * @param {object} portfolios - The portfolios object to check.
 * @returns {boolean} `true` if the `portfolios` object has any edges, `false` otherwise.
 */
export const hasPortfolio = (portfolios) => !!(portfolios?.edges?.length > 0);

/**
 * Filters the portfolios based on the active filter.
 * @param {object} portfolios - The portfolios object containing the edges.
 * @param {ACTIVE_STATE_ACTIVE | ACTIVE_STATE_INACTIVE |LIST_FILTER_ALL} activeState
 * The active filter to apply.
 * @returns {Array<object>} - All portfolios or active portfolios.
 */
export const portfoliosWithActiveState = (portfolios, activeState) => portfolios?.edges?.filter(
  (edge) => filterPortfolio(edge.node, activeState),
);
