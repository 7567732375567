/**
 * @generated SignedSource<<be4de3a0850bc2669626551ce7ee7a26>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CustomerPortfolioNavigation_portfolio",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "uuid",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    }
  ],
  "type": "Portfolio",
  "abstractKey": null
};

node.hash = "1c342a1acad8de5955dc881fb2d8de77";

module.exports = node;
