import { format } from 'd3-format';
import Link from 'found/Link';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { createFragmentContainer, graphql } from 'react-relay';
import {
  Card, CardHeader, Col, Row, Table,
} from 'reactstrap';

import BadgeActive from 'src/components/Badge/BadgeActive';
import Loading from 'src/components/Loading';
import UUID from 'src/components/UUID';
import Breadcrumbs from 'src/enosikit/components/Breadcrumbs';
import convertEnergyPrice from 'src/util/conversions';

/**
 * Description
 * @param {object} props
 * @returns {React.ReactElement} - AdminVolumeChargeShow component
 */
function AdminVolumeChargeShow(props) {
  const timeOfDayTypeToString = (timeOfDay) => {
    const hours = format('02d')(timeOfDay.hours);
    const minutes = format('02d')(timeOfDay.minutes);
    const seconds = timeOfDay.seconds > 0 ? format('02d')(timeOfDay.seconds) : null;
    const nanos = timeOfDay.nanos > 0 ? format('09d')(timeOfDay.nanos) : null;

    let timeOfDayString = [hours, minutes].join(':');
    if (seconds === null) {
      return timeOfDayString;
    }
    timeOfDayString = [timeOfDayString, seconds].join(':');
    if (nanos === null) {
      return timeOfDayString;
    }
    return [timeOfDayString, nanos].join('.');
  };

  if (!props) {
    return <Loading />;
  }

  const { volumeCharge } = props;
  const {
    uuid, identifier, externalIdentifier, title, rate, description, volumeUnit, importFlag,
    exportFlag, ignorePublicHolidays, timezone, monthsOfYear, daysOfWeek, timesOfDay, createdAt,
    updatedAt, tariff,
  } = volumeCharge;

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`Enosi Admin - Volume Charge - ${title}`}</title>
      </Helmet>

      <Breadcrumbs
        breadcrumbs={[
          { name: 'Admin', path: '/admin' },
          { name: 'Volume Charges', path: '/admin/volume-charges' },
          { name: title },
        ]}
      />

      <h1>
        {title}
      </h1>

      <Row className="clear-both">
        <Col xs={12} sm={6} md={4}>
          <Card className="mb-4">
            <CardHeader>
              Volume Charge details
            </CardHeader>
            <Table responsive>
              <tbody>
                <tr>
                  <th scope="row" className="text-right">ID</th>
                  <td>
                    <UUID uuid={uuid} />
                  </td>
                </tr>
                <tr>
                  <th scope="row" className="text-right">
                    <abbr title="External identifier">Ext ID</abbr>
                  </th>
                  <td>{externalIdentifier}</td>
                </tr>
                <tr>
                  <th scope="row" className="text-right">Title</th>
                  <td>{title}</td>
                </tr>
                <tr>
                  <th scope="row" className="text-right">Identifier</th>
                  <td>{identifier}</td>
                </tr>
                <tr>
                  <th scope="row" className="text-right">Description</th>
                  <td>{description}</td>
                </tr>
                <tr>
                  <th scope="row" className="text-right">Rate</th>
                  <td>
                    {convertEnergyPrice(rate).toFixed(3)}
                    {' '}
                    <abbr title="cents per kilowatt hour">c/kWh</abbr>
                  </td>
                </tr>
                <tr>
                  <th scope="row" className="text-right">Volume unit</th>
                  <td>{volumeUnit}</td>
                </tr>
                <tr>
                  <th scope="row" className="text-right">Import flag</th>
                  <td>{importFlag}</td>
                </tr>
                <tr>
                  <th scope="row" className="text-right">Export flag</th>
                  <td>{exportFlag}</td>
                </tr>
                <tr>
                  <th scope="row" className="text-right">Ignore public holidays</th>
                  <td>{ignorePublicHolidays}</td>
                </tr>
                <tr>
                  <th scope="row" className="text-right">Timezone</th>
                  <td>{timezone}</td>
                </tr>
                <tr>
                  <th scope="row" className="text-right">Tariff</th>
                  <td>
                    {tariff && (
                      <>
                        <Link to={`/admin/tariffs/${tariff.uuid}`}>
                          <strong>{tariff.title}</strong>
                        </Link>
                        <br />
                        <UUID uuid={tariff.uuid} />
                        <br />
                        <BadgeActive start={tariff.active.start} finish={tariff.active.finish} />
                      </>
                    )}
                  </td>
                </tr>
                <tr>
                  <th scope="row" className="text-right">Created at</th>
                  <td>
                    {createdAt
                      && DateTime.fromSeconds(createdAt).toLocaleString(DateTime.DATETIME_FULL)}
                  </td>
                </tr>
                <tr>
                  <th scope="row" className="text-right">Updated at</th>
                  <td>
                    {updatedAt
                      && DateTime.fromSeconds(updatedAt).toLocaleString(DateTime.DATETIME_FULL)}
                  </td>
                </tr>
              </tbody>
            </Table>
          </Card>
        </Col>

        <Col xs={12} sm={6} md={8}>
          <Card className="mb-4">
            <CardHeader>
              Months of Year
            </CardHeader>
            <Table responsive>
              <thead>
                <tr>
                  <th scope="col" colSpan={monthsOfYear.length}>Month active</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  {monthsOfYear.map((el) => (
                    <td key={`month-of-year-${el}`}>
                      {el}
                    </td>
                  ))}
                </tr>
              </tbody>
            </Table>
          </Card>

          <Card className="mb-4">
            <CardHeader>
              Days of Week
            </CardHeader>
            <Table responsive>
              <thead>
                <tr>
                  <th scope="col" colSpan={daysOfWeek.length}>Days active</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  {daysOfWeek.map((el) => (
                    <td key={`day-of-week-${el}`}>
                      {el}
                    </td>
                  ))}
                </tr>
              </tbody>
            </Table>
          </Card>

          <Card className="mb-4">
            <CardHeader>
              Times of Day
            </CardHeader>
            <Table responsive>
              <thead>
                <tr>
                  <th scope="col" colSpan={timesOfDay.length}>Times of day active</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  {timesOfDay.map(({ start, finish }) => (
                    <td key={`time-of-day-${timeOfDayTypeToString(start)}-${timeOfDayTypeToString(finish)}`}>
                      {[timeOfDayTypeToString(start), timeOfDayTypeToString(finish)].join(' to ')}
                    </td>
                  ))}
                </tr>
              </tbody>
            </Table>
          </Card>

        </Col>
      </Row>
    </>
  );
}

AdminVolumeChargeShow.propTypes = {
  relay: PropTypes.shape({
    hasMore: PropTypes.func,
    isLoading: PropTypes.func,
    loadMore: PropTypes.func,
  }).isRequired,
  volumeCharge: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

AdminVolumeChargeShow.defaultProps = {
  volumeCharge: null,
};

export default createFragmentContainer(
  AdminVolumeChargeShow,
  {
    volumeCharge: graphql`
      fragment AdminVolumeChargeShow_volumeCharge on VolumeCharge {
        uuid
        identifier
        externalIdentifier
        title
        rate
        description
        volumeUnit
        importFlag
        exportFlag
        ignorePublicHolidays
        timezone
        monthsOfYear
        daysOfWeek
        timesOfDay {
          start  { hours minutes seconds nanos }
          finish { hours minutes seconds nanos }
        }
        createdAt
        updatedAt
        tariff {
          uuid
          title
          active { start finish }
        }
      }
    `,
  },
);
