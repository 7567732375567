import { commitMutation, graphql } from 'react-relay';

import environment from 'src/util/environment';

const mutation = graphql`
  mutation UserDetailsUpdateMutation($input: UserDetailsUpdateInput!) {
    userDetailsUpdate(input: $input) {
      id
      uuid
      externalIdentifier
      type
      email
      givenName
      familyName
      active { start finish }
      createdAt
      updatedAt
    }
  }
`;

const UserDetailsUpdateMutation = (input, onCompleted, onError) => {
  const variables = { input };

  commitMutation(
    environment,
    {
      mutation,
      variables,
      onCompleted,
      onError,
    },
  );
};

export default UserDetailsUpdateMutation;
