import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';
import { graphql, useFragment } from 'react-relay';
import { Col, Row } from 'reactstrap';

import Loading from 'src/components/Loading';
import Breadcrumbs from 'src/enosikit/components/Breadcrumbs';

import AdminPortfolioDetailsCard from './AdminPortfolioDetailsCard';
import AdminPortfolioProperyMembers from './AdminPortfolioPropertyMembers';
import AdminPortfolioUserMembers from './AdminPortfolioUserMembers';

const AdminPortfolioShowFragment = graphql`
  fragment AdminPortfolioShow_portfolio on Portfolio {
    uuid
    externalIdentifier
    active { start finish }
    title
    createdAt
    updatedAt

    ...AdminPortfolioUserMembers_portfolio
    ...AdminPortfolioPropertyMembers_portfolio
  }
`;
/**
 * Returns AdminPortfolioShow component
 * @param {object} props
 * @returns {React.ReactComponentElement} - AdminPortfolioShow component
 */
function AdminPortfolioShow(props) {
  if (!props) {
    return <Loading />;
  }

  let { portfolio } = props;
  portfolio = useFragment(AdminPortfolioShowFragment, portfolio);
  const { title } = portfolio || {};

  const intl = useIntl();
  const pageTitle = intl.formatMessage({ id: 'admin.admin_portfolio.admin_portfolio_show.page.title', defaultMessage: 'Enosi Admin - Portfolio: {portfolioTitle}' }, { portfolioTitle: title });
  const breadcrumbAdmin = intl.formatMessage({ id: 'admin.admin_portfolio.admin_portfolio_show.breadcrumbs.admin.label', defaultMessage: 'Admin' });
  const breadcrumbPortfolios = intl.formatMessage({ id: 'admin.admin_portfolio.admin_portfolio_show..breadcrumbs.portfolio.label', defaultMessage: 'Portfolios' });

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{pageTitle}</title>
      </Helmet>
      <Breadcrumbs
        breadcrumbs={[
          { name: breadcrumbAdmin, path: '/admin' },
          { name: breadcrumbPortfolios, path: '/admin/portfolios' },
          { name: title },
        ]}
      />
      <h1>{title}</h1>
      <Row className="clear-both">
        <Col xs={12} sm={6} md={4}>
          <AdminPortfolioDetailsCard portfolio={portfolio} />
        </Col>
        <Col xs={12} sm={6} md={8}>
          <AdminPortfolioProperyMembers portfolio={portfolio} />
          <AdminPortfolioUserMembers portfolio={portfolio} />
        </Col>
      </Row>
    </>
  );
}

export default AdminPortfolioShow;

AdminPortfolioShow.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  portfolio: PropTypes.object.isRequired,
};
