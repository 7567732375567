import { PLATFORM_MODE_TRADE, PlatformModes } from 'src/util/constants';
import window from 'src/window';

// Extract the enosi environment from the window. Locally copied for mock support.
const { __enosiEnv: runtime } = window;

// Default timezone is Australia/Sydney. This can be overridden on a per-partner
// basis via config. See <https://github.com/enosi/terraform-frontend> for details.
const DEFAULT_TIMEZONE = 'Australia/Sydney';

/**
 * Description
 * @returns {object} - AmplifyConfig
 */
export function AmplifyConfig() {
  if (runtime === undefined) {
    return undefined;
  }

  return {
    Auth: {
      // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
      // identityPoolId: 'XX-XXXX-X:XXXXXXXX-XXXX-1234-abcd-1234567890ab',

      // REQUIRED - Amazon Cognito Region
      region: runtime.AWS_REGION,

      // OPTIONAL - Amazon Cognito Federated Identity Pool Region
      // Required only if it's different from Amazon Cognito Region
      // identityPoolRegion: 'XX-XXXX-X',

      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: runtime.USER_POOL_ID,

      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolWebClientId: runtime.USER_POOL_WEB_CLIENT_ID,

      // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
      // mandatorySignIn: false,

      // OPTIONAL - Configuration for cookie storage
      // Note: if the secure flag is set to true, then
      // the cookie transmission requires a secure protocol
      // cookieStorage: {
      // REQUIRED - Cookie domain (only required if cookieStorage is provided)
      // domain:
      // ".your.domain",
      // OPTIONAL - Cookie path
      // path: '/',
      // OPTIONAL - Cookie expiration in days
      // expires: 365,
      // OPTIONAL - Cookie secure flag
      // Either true or false, indicating if
      // the cookie transmission requires a secure protocol (https).
      // secure: true,
      // },

      // OPTIONAL - customized storage object
      // storage: MyStorage,

      // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
      authenticationFlowType: 'USER_PASSWORD_AUTH',
      // OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
      // clientMetadata: { myCustomKey: 'myCustomValue' },
    },
  };
}

/**
 * Description
 * @returns {object} - APIConfig
 */
export function APIConfig() {
  return {
    /**
     * Check feature status.
     * @function
     * @param {string} flag - the feature flag to check.
     * @returns {boolean} True or false.
     */
    feature: (flag) => {
      if (typeof runtime === 'undefined') { return false; }
      if (typeof runtime.FEATURES === 'undefined') { return false; }

      const value = `${runtime.FEATURES[flag]}`;
      switch (value.toUpperCase()) {
        case '1':
        case 'T':
        case 'TRUE':
          return true;
        default:
          return false;
      }
    },
    /**
     * Provides cobranding information. Where non string values provided, revers to the partner
     * cobranding details.
     *
     * Basic cobranding data structure for use in local `/public/config.js` below. Note, the UUIDs
     * would match that of a community held in the syndesi services that you are viewing.
     *
     * ```javascript
     * window.__enosiEnv = {
     * ...
     * COBRANDS: {
     * partner: '/public/path-to-partner-image.png',
     * 'c169f334-a2d6-4e16-852e-471b8e4790d0': '/public/path-to-example-community-image.png',
     * },
     * PARTNER_TITLE: 'Example Partner'.
     * ...
     * }
     * ```
     *
     * See also: <https://github.com/enosi/terraform-frontend/blob/main/templates/config.js.tftpl#L29-L34>.
     * @function
     * @param {string} key - key is the uuid of the community. If null, uses 'partner' if set.
     *                       Otherwise returns empty.
     * @param {string} title - title to use for the alt attribute of a community cobrand. Ignored
     *                         for partner cobrand.
     * @returns {object} null if not set, otherwise an object with a type ('COBRAND_PARTNER'
     *                         or 'COBRAND_COMMUNITY'), title and the image path).
     */
    cobrand: (key, title) => {
      if (typeof runtime === 'undefined') { return null; }
      if (typeof runtime.COBRANDS === 'undefined') { return null; }
      if (typeof runtime.COBRANDS !== 'object') { return null; }

      if (typeof key === 'string') {
        const cobrand = runtime.COBRANDS[key];
        if (typeof cobrand === 'string') { return { imagePath: cobrand, type: 'COBRAND_COMMUNITY', title }; }

        console.warn(`cobrand: value for cobrand at key '${key}' is '${typeof cobrand}' not 'string'. Defaulting to non-community brand`);
      } else {
        console.warn(`cobrand: value for key '${key}' is '${typeof key}' not 'string'. Defaulting to non-community brand`);
      }

      const { partner: cobrand } = runtime.COBRANDS;
      if (typeof cobrand === 'string') {
        return { imagePath: cobrand, type: 'COBRAND_PARTNER', title: runtime.PARTNER_TITLE };
      }

      console.warn(`cobrand: value for key 'partner' is '${typeof key}' not 'string'. Defaulting to primary brand only`);

      return null;
    },
    /**
     * Provides primary branding information. To override the default primary branding,
     * add a `primary` keyed image file path to the cobrands collection. We return `undefined` here
     * in preference to `null` due to the handling by React & defaultProps (ref: <https://github.com/facebook/react/issues/2166#issuecomment-55040147>).
     *
     * ```javascript
     * window.__enosiEnv = {
     *   ...
     *   COBRANDS: {
     *     primary: '/public/path-to-alternative-primary-brand-image.png',
     *     ...
     *   },
     *   ...
     * }
     * ```
     * @function
     * @returns {object} undefined if not set, otherwise an object with a type
     *                  ('BRAND_PRIMARY'), title and the image path.
     */
    primaryBrand: () => {
      if (typeof runtime === 'undefined') { return undefined; }
      if (typeof runtime.COBRANDS === 'undefined') { return undefined; }
      if (typeof runtime.COBRANDS !== 'object') { return undefined; }

      const { primary: brand } = runtime.COBRANDS;
      if (typeof brand !== 'string') { return undefined; }

      return { imagePath: brand, type: 'BRAND_PRIMARY', title: 'Enosi Powertracer' };
    },
    /**
     * Constant values extracted from the runtime.
     */
    DEPLOYMENT_ENV: runtime?.DEPLOYMENT_ENV,
    GRAPH_URL: runtime?.GRAPH_API_URL,
    INTERCOM_APP_ID: runtime?.INTERCOM_APP_ID,
    DEFAULT_TIMEZONE: runtime?.DEFAULT_TIMEZONE || DEFAULT_TIMEZONE,
    LOCALE: runtime?.LOCALE,
    LOCAL: runtime?.LOCAL,
    MAPBOX_TOKEN: runtime?.MAPBOX_TOKEN,
    COMMUNITY_BUY_PRICE_MAX: runtime?.COMMUNITY_BUY_PRICE_MAX,
    COMMUNITY_BUY_PRICE_MIN: runtime?.COMMUNITY_BUY_PRICE_MIN,
    COMMUNITY_SELL_PRICE_MAX: runtime?.COMMUNITY_SELL_PRICE_MAX,
    COMMUNITY_SELL_PRICE_MIN: runtime?.COMMUNITY_SELL_PRICE_MIN,
    MODE: (
      runtime !== undefined && runtime.PLATFORM_MODE !== undefined
        && PlatformModes.indexOf(runtime.PLATFORM_MODE) >= 0
        ? runtime.PLATFORM_MODE : PLATFORM_MODE_TRADE
    ),
    OLTP_TRACES_URL: runtime?.OLTP_TRACES_URL,
    PARTNER_TITLE: runtime?.PARTNER_TITLE,
    REST_BASE_URL: runtime?.REST_API_BASE_URL,
    SUPPORT_URL: runtime?.SUPPORT_URL,
    TIME_OF_DAY_STEP_SIZE: runtime?.TIME_OF_DAY_STEP_SIZE,
  };
}
