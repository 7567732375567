import Link from 'found/Link';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import React from 'react';

import BadgeActive from 'src/components/Badge/BadgeActive';
import Loading from 'src/components/Loading';
import UUID from 'src/components/UUID';

/**
 * Description
 * @param {object} props
 * @returns {any} - AdminPrimaryBillingPointShowMeter component
 */
function AdminPrimaryBillingPointShowMeter(props) {
  if (!props) {
    return <Loading />;
  }

  const { meter } = props;
  const {
    uuid, identifier, active, primaryBillingPoint,
  } = meter;
  const { start, finish } = active;
  const { uuid: pbpId, identifier: primaryBillingPointIdentifier } = primaryBillingPoint;

  return (
    <tr>
      <th scope="row" colSpan={2}>
        <Link to={`/admin/meters/${uuid}`}>
          {identifier}
        </Link>
        <br />
        <UUID uuid={uuid} />
      </th>
      <td>
        <BadgeActive start={start} finish={finish} />
        <br />
        {start && DateTime.fromSeconds(start).toLocaleString(DateTime.DATETIME_FULL)}
        -
        {finish && DateTime.fromSeconds(finish).toLocaleString(DateTime.DATETIME_FULL)}
      </td>
      <td>
        <Link to={`/admin/primary-billing-points/${pbpId}`}>
          {primaryBillingPointIdentifier}
        </Link>
        <br />
        <UUID uuid={pbpId} />
      </td>
    </tr>
  );
}

AdminPrimaryBillingPointShowMeter.propTypes = {
  meter: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

AdminPrimaryBillingPointShowMeter.defaultProps = {
  meter: null,
};

export default AdminPrimaryBillingPointShowMeter;
