import { commitMutation, graphql } from 'react-relay';

import environment from 'src/util/environment';

const mutation = graphql`
  mutation PropertyAddUserMutation($propertyId: ID!, $userId: ID!) {
    propertyAddUser(propertyId: $propertyId, userId: $userId) {
      id
      uuid
      externalIdentifier
      active { start finish }
      createdAt
      updatedAt
      user { uuid }
      property { uuid }
    }
  }
`;

const PropertyAddUserMutation = (propertyId, userId, onCompleted, onError) => {
  const variables = { propertyId, userId };

  commitMutation(
    environment,
    {
      mutation,
      variables,
      onCompleted,
      onError,
    },
  );
};

export default PropertyAddUserMutation;
