import PropTypes from 'prop-types';
import React from 'react';
import { Button, ButtonGroup, ButtonToolbar } from 'reactstrap';

/**
 * Description
 * @param {any} props
 * @returns {React.ReactComponentElement} - FilterBar component
 */
function FilterBar(props) {
  const { buttons, onButtonClick } = props;

  return (
    <ButtonToolbar className="me-2">
      {buttons.map(({ group, items }) => (
        <ButtonGroup className="me-2 mb-2" key={group}>
          {items.map(({
            value, label, active,
          }) => (
            <Button
              size="sm"
              color="darken"
              key={value}
              active={active}
              onClick={() => onButtonClick({ group, value })}
            >
              {label}
            </Button>
          ))}
        </ButtonGroup>
      ))}
    </ButtonToolbar>
  );
}

FilterBar.propTypes = {
  buttons: PropTypes.arrayOf(PropTypes.shape({
    group: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.element.isRequired,
      active: PropTypes.bool.isRequired,
    })),
  })).isRequired,
  onButtonClick: PropTypes.func.isRequired,
};

FilterBar.defaultProps = {};

export default FilterBar;
