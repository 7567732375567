import { Link } from 'found';
import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { FormattedMessage, injectIntl } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import Address from 'src/components/Address';
import Loading from 'src/components/Loading';
import Breadcrumbs from 'src/enosikit/components/Breadcrumbs';
import Heading from 'src/enosikit/components/Heading';
import PropertySettingsUpdateMutation from 'src/mutations/PropertySettingsUpdateMutation';
import FlashesStore from 'src/stores/FlashesStore';

import PropertySettingsForm from './PropertySettingsForm';
import PropertySettingsMeterTariff from './PropertySettingsMeterTariff';
import PropertySettingsUsers from './PropertySettingsUsers';

class PropertySettings extends React.Component {
  constructor(props) {
    super(props);

    this.state = { processing: false };
  }

  handlePropertySettingsUpdate = (uuid, input) => {
    this.setState({ processing: true });
    FlashesStore.reset();

    PropertySettingsUpdateMutation(
      uuid,
      input,
      this.handlePropertySettingsUpdateSuccess,
      this.handlePropertySettingsUpdateFailure,
    );
  };

  handlePropertySettingsUpdateSuccess = (response, errors) => {
    this.setState({ processing: false });

    if (errors && errors.length > 0) {
      errors.forEach((error) => FlashesStore.flash(FlashesStore.ERROR, {
        message: FlashesStore.ERROR,
        source: { errors: [error] },
      }));
      return;
    }

    FlashesStore.flash(
      FlashesStore.SUCCESS,
      <FormattedMessage id="property.settings.updated_success" defaultMessage="Updated property settings." />
      ,
    );

    const { router, property } = this.props;
    router.push(`/properties/${property.uuid}`);
  };

  handlePropertySettingsUpdateFailure = (error) => {
    this.setState({ processing: false });

    FlashesStore.flash(FlashesStore.ERROR, error);
  };

  render() {
    if (this.error) {
      return <div><FormattedMessage id="error.title" defaultMessage="Error!" /></div>;
    }
    if (!this.props) {
      return <Loading />;
    }

    const { intl, property } = this.props;
    const { address, uuid, title } = property;
    const { formatMessage } = intl;
    const { processing } = this.state;

    const addr = new Address(address);
    const pageTitle = formatMessage(
      { id: 'property.property_settings.page.title', defaultMessage: 'Enosi - Property: {propertyTitle}' },
      { propertyTitle: title },
    );

    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{pageTitle}</title>
        </Helmet>

        <Breadcrumbs
          breadcrumbs={[
            {
              name: <FormattedMessage id="property.property_settings.breadcrumbs.properties.label" defaultMessage=" Properties" />, path: '/properties',
            },
            { name: title, path: `/properties/${uuid}` },
            { name: <FormattedMessage id="property.property_settings.breadcrumbs.settings.label" defaultMessage="Settings" /> },
          ]}
        />
        <Heading
          title={title}
          subtitle={addr.simpleString()}
          context={<FormattedMessage id="property.property_settings.title" defaultMessage="Property Settings" />}
        />

        <h2><FormattedMessage id="property.property_settings.general_settings_title" defaultMessage="General Settings" /></h2>
        <p><FormattedMessage id="property.property_settings.general_settings_description" defaultMessage="Here you can add an alias to your property." /></p>

        <PropertySettingsForm
          property={property}
          handlePropertySettingsUpdate={this.handlePropertySettingsUpdate}
          processing={processing}
        />

        <div className="d-flex flex-wrap">
          <h2 id="tariffs"><FormattedMessage id="property.property_settings.tariffs.active_tariffs.title" defaultMessage="Active Tariffs" /></h2>
          <div className="ms-auto">
            <Link to={`/properties/${property.uuid}/tariffs`} className="btn btn-darken property-all-tariffs">
              <FormattedMessage id="property.property_settings.tariffs.all_tariffs.title" defaultMessage="All tariffs" />
            </Link>
          </div>
        </div>
        <p><FormattedMessage id="property.property_settings.tariffs.disclaimer" defaultMessage="Your electricity tariffs are listed here and are GST exclusive." /></p>

        <PropertySettingsMeterTariff property={property} />

        <h2>
          <FormattedMessage id="property.property_settings.users.title" defaultMessage="Users" />
        </h2>
        <p>
          <FormattedMessage id="property.property_settings.users.description" defaultMessage="These are the users who can manage this property." />
        </p>

        <PropertySettingsUsers property={property} />
      </>
    );
  }
}

PropertySettings.propTypes = {
  property: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  intl: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  relay: PropTypes.shape({
    refetch: PropTypes.func,
  }).isRequired,
  router: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types

};

PropertySettings.defaultProps = {
  property: null,
};

export default injectIntl(createFragmentContainer(
  PropertySettings,
  {
    property: graphql`
      fragment PropertySettings_property on Property {
        uuid
        title
        address {
          line1
          line2
          city
          state
          country
          postcode
        }
        ...PropertySettingsForm_property
        ...PropertySettingsMeterTariff_property
        ...PropertySettingsUsers_property
      }
    `,
  },
));
