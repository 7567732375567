import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import React from 'react';

import {
  BUY, DATA_AGGREGATE_BY_METER, DATA_AGGREGATE_BY_PORTFOLIO, DATA_AGGREGATE_BY_PROPERTY,
  DATA_GROUP_BY_COUNTERPARTY, DATA_GROUP_BY_TRADE_TYPE, DIRECTIONS, SELL, UNTRADED_ENERGY_KEY,
} from 'src/util/constants';

import StackedBarChart from './StackedBarChart';
import {
  TRADE, UNIT_CARBON, UNIT_CURRENCY, UNIT_ENERGY,
} from './chartConstants';
import { getUnitProps, showNoDataMsg } from '../helpers/common';

/**
 * Description
 * @param {any} props
 * @returns {React.ReactComponentElement} - ChartTrade component
 */
export default function ChartTrade(props) {
  const {
    aggregation, chartView, handleChartClick, hoverKeys,
    multipleMeter, selectedKeys, timezone, tooltipTimestamp, tooltipUpdate,
    tooltipLabelFunc, tradeDataBuy, tradeDataSell, unit, width,
  } = props;

  const dataNotAvailable = (!tradeDataBuy || tradeDataBuy.length === 0)
    && (!tradeDataSell || tradeDataSell.length === 0);

  if (dataNotAvailable) {
    return showNoDataMsg(TRADE);
  }

  // Logic to frame the chart data : Start
  const {
    key: unitKey, label: unitLabel, format: yAxisFormat, tooltipFormat,
  } = getUnitProps(unit);
  const tooltipDateFormat = aggregation.match(/^PT\d+[HMS]/) ? DateTime.DATETIME_SHORT : DateTime.DATE_SHORT;

  const data = { buy: {}, sell: {} };
  const offset = {};
  const allXAxisValues = {};
  const tooltipData = {
    buy: {},
    sell: {},
    all: {},
  };

  const compareX = (a, b) => (a.x - b.x);

  DIRECTIONS.forEach((direction) => {
    const tradeData = direction === BUY ? tradeDataBuy : tradeDataSell;
    if (tradeData === null || tradeData.length === 0) {
      return;
    }

    tradeData.forEach((series) => {
      const {
        key, label, subLabel, data: seriesData, meter, property,
      } = series;
      if (Object.entries(seriesData).length === 0) {
        return;
      }
      const seriesDataWithoutNaN = Object.entries(seriesData)
        .filter(([_, { [unitKey]: value }]) => !Number.isNaN(value));

      seriesDataWithoutNaN.forEach(([_, d]) => {
        const { interval, [unitKey]: value } = d;
        const { timestamp } = interval;

        allXAxisValues[timestamp] = true;

        if (!(key in data[direction])) { data[direction][key] = {}; }
        const dataKey = data[direction][key];

        if (!(timestamp in dataKey)) { dataKey[timestamp] = { x: timestamp, y: 0 }; }
        const dataKeyTS = dataKey[timestamp];

        dataKeyTS.y += value;

        if (direction === SELL) {
          if (!(timestamp in offset)) {
            offset[timestamp] = {
              x: timestamp, y: 0, y0: 0,
            };
          }
          offset[timestamp].y -= value;
          offset[timestamp].y0 -= value;
        }

        if (tooltipTimestamp && timestamp.equals(tooltipTimestamp)) {
          if (!(key in tooltipData[direction])) {
            tooltipData[direction][key] = {
              key, label, subLabel, value: 0, meter, property,
            };
          }
          tooltipData[direction][key].value += value;
        }
      });
    });
  });

  // Fill in missing values
  Object.keys(allXAxisValues).forEach((x) => {
    Object.keys(data).forEach((direction) => {
      Object.keys(data[direction]).forEach((key) => {
        if (!(x in data[direction][key])) {
          data[direction][key][x] = { x: DateTime.fromISO(x, { zone: timezone }), y: 0 };
        }
      });
    });
    if (!(x in offset)) { offset[x] = { x: DateTime.fromISO(x, { zone: timezone }), y: 0, y0: 0 }; }
  });

  tooltipData.all = Object.values(data).map(
    (dataByKey) => Object.values(dataByKey).map(
      (dataByTimestamp) => dataByTimestamp[tooltipTimestamp],
    ),
  ).flat();

  // dont show untraded energy if the value is 0
  tooltipData.buy = Object.values(tooltipData.buy).filter((tradeType) => !(tradeType.value === 0
    && tradeType.key === UNTRADED_ENERGY_KEY));
  tooltipData.sell = Object.values(tooltipData.sell).filter((tradeType) => !(tradeType.value === 0
    && tradeType.key === UNTRADED_ENERGY_KEY));

  const toolTipProps = {
    unitLabel,
    tooltipDateFormat,
    tooltipData,
    tooltipLabelFunc,
    tooltipTimestamp,
    tooltipFormat,
    isCarbon: unit === UNIT_CARBON,
    multipleMeter,
  };

  const stackBarProps = {
    data,
    hoverKeys,
    selectedKeys,
    compareX,
    tooltipUpdate,
    handleChartClick,
    chartType: TRADE,
  };

  return (
    <StackedBarChart
      chartView={chartView}
      stackBarProps={stackBarProps}
      stepSize={aggregation}
      toolTipProps={toolTipProps}
      width={width}
      yAxisFormat={yAxisFormat}
    />
  );
}

const tradeDataShape = PropTypes.shape({
  aggregation: PropTypes.string,
  key: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  data: PropTypes.objectOf(PropTypes.shape({
    interval: PropTypes.shape({
      timestamp: PropTypes.instanceOf(DateTime).isRequired,
    }).isRequired,
    volume: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  })),
});

ChartTrade.propTypes = {
  aggregation: PropTypes.string.isRequired,
  chartView: PropTypes.shape({
    groupBy: PropTypes.oneOf([
      DATA_GROUP_BY_COUNTERPARTY,
      DATA_GROUP_BY_TRADE_TYPE,
    ]).isRequired,
    aggregateBy: PropTypes.oneOf([
      DATA_AGGREGATE_BY_METER,
      DATA_AGGREGATE_BY_PORTFOLIO,
      DATA_AGGREGATE_BY_PROPERTY,
    ]).isRequired,
  }).isRequired,
  handleChartClick: PropTypes.func.isRequired,
  hoverKeys: PropTypes.arrayOf(PropTypes.string),
  multipleMeter: PropTypes.bool,
  selectedKeys: PropTypes.arrayOf(PropTypes.string),
  timezone: PropTypes.string.isRequired,
  tradeDataBuy: PropTypes.arrayOf(tradeDataShape),
  tradeDataSell: PropTypes.arrayOf(tradeDataShape),
  tooltipLabelFunc: PropTypes.func.isRequired,
  tooltipTimestamp: PropTypes.instanceOf(DateTime),
  tooltipUpdate: PropTypes.func.isRequired,
  unit: PropTypes.oneOf([
    UNIT_CURRENCY,
    UNIT_ENERGY,
    UNIT_CARBON,
  ]).isRequired,
  width: PropTypes.number.isRequired,
};

ChartTrade.defaultProps = {
  hoverKeys: [],
  multipleMeter: false,
  selectedKeys: [],
  tradeDataBuy: [],
  tradeDataSell: [],
  tooltipTimestamp: null,
};
