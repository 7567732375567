import path from 'path';

import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { FormattedMessage, injectIntl } from 'react-intl';
import { createPaginationContainer, graphql } from 'react-relay';

import Address from 'src/components/Address';
import { FilterBar } from 'src/components/Filter';
import LoadMoreButton from 'src/components/LoadMoreButton';
import Loading from 'src/components/Loading';
import Breadcrumbs from 'src/enosikit/components/Breadcrumbs';
import ListCard from 'src/enosikit/components/List/ListCard';
import {
  ACTIVE_STATE_ACTIVE, ACTIVE_STATE_INACTIVE, LIST_FILTER_ALL, USER_TYPE_CUSTOMER,
} from 'src/util/constants';
import isActive from 'src/util/isActive';

import PropertyListMap from './PropertyListMap';

class PropertyList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      filter: {
        active: ACTIVE_STATE_ACTIVE,
      },
    };
  }

  loadMore = () => {
    const { relay } = this.props;

    if (!relay.hasMore() || relay.isLoading()) {
      return;
    }

    relay.loadMore(
      50,
      (error) => { console.log({ error }); },
    );
  };

  filterBarButtons = () => {
    const { filter } = this.state;

    return [
      {
        group: LIST_FILTER_ALL,
        items: [
          { value: LIST_FILTER_ALL, label: <FormattedMessage id="property.property_list.filter.all.label" defaultMessage="All" />, active: filter.active === LIST_FILTER_ALL },
        ],
      },
      {
        group: 'active',
        items: [
          { value: ACTIVE_STATE_ACTIVE, label: <FormattedMessage id="property.property_list.filter.active_status.active.label" defaultMessage="Active" />, active: filter.active === ACTIVE_STATE_ACTIVE },
          { value: ACTIVE_STATE_INACTIVE, label: <FormattedMessage id="property.property_list.filter.active_status.inactive.label" defaultMessage="Inactive" />, active: filter.active === ACTIVE_STATE_INACTIVE },
        ],
      },
    ];
  };

  updateFilter = ({ group, value }) => {
    const { filter } = this.state;

    const newFilter = { ...filter };

    if (value === LIST_FILTER_ALL) {
      newFilter.active = LIST_FILTER_ALL;
    }
    if (group === 'active') {
      newFilter.active = value;
    }

    this.setState({ filter: newFilter });
  };

  filterProperty = (property) => {
    const { filter } = this.state;

    const propertyIsActive = isActive(property.active);

    switch (filter.active) {
      case ACTIVE_STATE_ACTIVE:
        return propertyIsActive;
      case ACTIVE_STATE_INACTIVE:
        return !propertyIsActive;
      default: // LIST_FILTER_ALL
        return true;
    }
  };

  render() {
    if (this.error) {
      return <div><FormattedMessage id="error.title" defaultMessage="Error!" /></div>;
    }
    if (!this.props) {
      return <Loading />;
    }

    const { intl, relay, viewer } = this.props;
    const { viewerUser, properties } = viewer || {};
    const { formatMessage } = intl;
    const pageTitle = formatMessage({ id: 'property.property_list.page.title', defaultMessage: 'Enosi - your properties' });
    const label = formatMessage({ id: 'list.card.manage_property', defaultMessage: 'Manage Property' });

    const copyLoadMore = intl.formatMessage({ id: 'property.property_list.load_more_button.state_load_more.label', defaultMessage: 'Load more...' });
    const copyNoMore = intl.formatMessage({ id: 'property.property_list.load_more_button.state_no_more.label', defaultMessage: 'No more properties to load' });

    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{pageTitle}</title>
        </Helmet>
        <Breadcrumbs breadcrumbs={[{ name: <FormattedMessage id="breadcrumbs.properties.title" defaultMessage="Properties" /> }]} />

        <FilterBar buttons={this.filterBarButtons()} onButtonClick={this.updateFilter} />

        <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 mt-2">
          {
            properties && properties.edges && properties.edges.filter(
              (edge) => this.filterProperty(edge.node),
            ).map((edge) => {
              const { node } = edge;
              const { address, uuid, title } = node;

              const addr = new Address(address);
              return (
                <div className="col mb-4" key={uuid}>
                  <ListCard
                    alias={title}
                    label={label}
                    map={viewerUser?.type === USER_TYPE_CUSTOMER && address ? <PropertyListMap address={address} height="128" /> : null}
                    path={path.join('/', 'properties', uuid)}
                    subheading={addr.simpleString()}
                  />
                </div>
              );
            })
          }
        </div>
        <div>
          <LoadMoreButton
            copyLoadMore={copyLoadMore}
            copyNoMore={copyNoMore}
            hasNext={relay.hasMore()}
            onClick={this.loadMore}
          />
        </div>
      </>
    );
  }
}

PropertyList.propTypes = {
  intl: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  relay: PropTypes.shape({
    hasMore: PropTypes.func,
    isLoading: PropTypes.func,
    loadMore: PropTypes.func,
  }).isRequired,
  viewer: PropTypes.shape({
    id: PropTypes.string,
    viewerUser: PropTypes.shape({
      type: PropTypes.string,
      email: PropTypes.string,
      givenName: PropTypes.string,
      familyName: PropTypes.string,
    }),
    properties: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  }), // eslint-disable-line react/forbid-prop-types
};

PropertyList.defaultProps = {
  viewer: null,
};

export default injectIntl(createPaginationContainer(
  PropertyList,
  {
    viewer: graphql`
      fragment PropertyList_viewer on Viewer {
        id
        viewerUser {
          type
          email
          givenName
          familyName
        }
        properties(
          first: $count
          after: $cursor
        ) @connection(key: "PropertyList_properties") {
          edges {
            cursor
            node {
              uuid
              title
              active {
                start
                finish
              }
              address {
                line1
                line2
                city
                state
                country
                postcode
                gps {
                  latitude
                  longitude
                }
              }
            }
          }
          count
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          }
        }
      }
    `,
  },
  {
    direction: 'forward',
    getConnectionFromProps(props) {
      return props.viewer.properties;
    },
    // This is also the default implementation of `getFragmentVariables` if it isn't provided.
    getFragmentVariables(prevVars, totalCount) {
      return {
        ...prevVars,
        count: totalCount,
      };
    },
    getVariables(props, { count, cursor }) {
      return {
        count,
        cursor,
      };
    },
    query: graphql`
    # Pagination query to be fetched upon calling 'loadMore'.
    # Notice that we re-use our fragment, and the shape of this query matches our fragment spec.
    query PropertyList_Pagination_Query(
      $count: Int!
      $cursor: String
    ) {
      viewer {
        ...PropertyList_viewer
      }
    }
  `,
  },
));
