/**
 * @generated SignedSource<<ceb2d1c5a59be1426e770267cc3b3afc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "count"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cursor"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "finish"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "start"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "uuid"
},
v5 = [
  {
    "kind": "Variable",
    "name": "uuid",
    "variableName": "uuid"
  }
],
v6 = {
  "kind": "Variable",
  "name": "finish",
  "variableName": "finish"
},
v7 = {
  "kind": "Variable",
  "name": "start",
  "variableName": "start"
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uuid",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "externalIdentifier",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "TimeRange",
  "kind": "LinkedField",
  "name": "active",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "start",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "finish",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v11 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "count"
},
v12 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  (v6/*: any*/),
  (v11/*: any*/),
  (v7/*: any*/)
],
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "count",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "routes_PortfolioUserMembersList_Query",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "Portfolio",
        "kind": "LinkedField",
        "name": "portfolio",
        "plural": false,
        "selections": [
          {
            "args": [
              {
                "kind": "Variable",
                "name": "count",
                "variableName": "count"
              },
              {
                "kind": "Variable",
                "name": "cursor",
                "variableName": "cursor"
              },
              (v6/*: any*/),
              (v7/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "PortfolioUserMemberList_portfolio"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v4/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "routes_PortfolioUserMembersList_Query",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "Portfolio",
        "kind": "LinkedField",
        "name": "portfolio",
        "plural": false,
        "selections": [
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v12/*: any*/),
            "concreteType": "PortfolioUserMemberConnection",
            "kind": "LinkedField",
            "name": "userMembers",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PortfolioUserMemberEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PortfolioUserMember",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v8/*: any*/),
                      (v9/*: any*/),
                      (v10/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "User",
                        "kind": "LinkedField",
                        "name": "user",
                        "plural": false,
                        "selections": [
                          (v8/*: any*/),
                          (v9/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "givenName",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "familyName",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "email",
                            "storageKey": null
                          },
                          (v13/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "role",
                        "storageKey": null
                      },
                      (v13/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v14/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "startCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasPreviousPage",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v12/*: any*/),
            "filters": [
              "start",
              "finish"
            ],
            "handle": "connection",
            "key": "PortfolioUserMembersList_userMembers",
            "kind": "LinkedHandle",
            "name": "userMembers"
          },
          {
            "alias": null,
            "args": [
              (v11/*: any*/)
            ],
            "concreteType": "PortfolioPropertyMemberConnection",
            "kind": "LinkedField",
            "name": "propertyMembers",
            "plural": false,
            "selections": [
              (v14/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "PortfolioPropertyMemberEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PortfolioPropertyMember",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v8/*: any*/),
                      (v13/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v13/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "00dfa8fc90659ca437dd7cc6f1822c0b",
    "id": null,
    "metadata": {},
    "name": "routes_PortfolioUserMembersList_Query",
    "operationKind": "query",
    "text": "query routes_PortfolioUserMembersList_Query(\n  $uuid: ID!\n  $count: Int\n  $cursor: String\n  $start: Timestamp!\n  $finish: Timestamp!\n) {\n  portfolio(uuid: $uuid) {\n    ...PortfolioUserMemberList_portfolio_KSaEo\n    id\n  }\n}\n\nfragment PortfolioUserMemberList_portfolio_KSaEo on Portfolio {\n  uuid\n  externalIdentifier\n  active {\n    start\n    finish\n  }\n  title\n  userMembers(first: $count, after: $cursor, start: $start, finish: $finish) {\n    edges {\n      cursor\n      node {\n        uuid\n        externalIdentifier\n        active {\n          start\n          finish\n        }\n        user {\n          uuid\n          externalIdentifier\n          givenName\n          familyName\n          email\n          id\n        }\n        role\n        id\n        __typename\n      }\n    }\n    count\n    pageInfo {\n      startCursor\n      endCursor\n      hasNextPage\n      hasPreviousPage\n    }\n  }\n  propertyMembers(first: $count) {\n    count\n    edges {\n      node {\n        uuid\n        id\n      }\n    }\n  }\n  id\n}\n"
  }
};
})();

node.hash = "91dd66050b6fd9510f4ef36cdd33885e";

module.exports = node;
