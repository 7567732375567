import { ACTIVE_STATE_ACTIVE, ACTIVE_STATE_INACTIVE, LIST_FILTER_ALL } from 'src/util/constants';
import isActive from 'src/util/isActive';

/**
 * Filters a list of elements to only include those that are active.
 * @param {Array<object>} elements - The list of elements to filter.
 * @returns {Array<object>} The filtered list of active elements.
 */
const getActiveElements = (elements) => elements.filter((
  element,
) => isActive(element.active));

/**
 * Filters a list of elements to only include those that are inactive.
 * @param {Array<object>} elements - The list of elements to filter.
 * @returns {Array<object>} The filtered list of inactive elements.
 */
const getInactiveElements = (elements) => elements.filter((
  element,
) => !isActive(element.active));

/**
 * Filters a list of elements based on their active state.
 * @param {Array<object>} elements - The list of elements to filter.
 * @param {ACTIVE_STATE_ACTIVE | ACTIVE_STATE_INACTIVE | LIST_FILTER_ALL} state - The active
 * state to filter by.
 * @returns {Array<object>} The filtered list of elements.
 */
const getElementsByState = (elements, state) => {
  switch (state) {
    case ACTIVE_STATE_ACTIVE:
      return getActiveElements(elements);
    case ACTIVE_STATE_INACTIVE:
      return getInactiveElements(elements);
    default: // LIST_FILTER_ALL
      return elements;
  }
};

export default getElementsByState;
