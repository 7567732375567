import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { injectIntl } from 'react-intl';
import {
  Button, Form, FormGroup, Input, Label,
} from 'reactstrap';

import EmailConfirmMutation from 'src/mutations/EmailConfirmMutation';
import FlashesStore from 'src/stores/FlashesStore';

import AuthContainer, { AUTH_TYPE_EMAIL_CONFIRM } from '../AuthContainer';

class AuthConfirmEmail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      newEmail: '',
    };
  }

  handleEmailChange = (e) => {
    this.setState({ email: e.target.value });
  };

  handleNewEmailChange = (e) => {
    this.setState({ newEmail: e.target.value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { email, newEmail } = this.state;

    // eslint-disable-next-line react/destructuring-assignment
    const { params } = this.props.match;
    const { token } = params;

    EmailConfirmMutation(
      {
        email,
        newEmail,
        verificationCode: token,
      },
      this.handleMutationSuccess,
      this.handleMutationFailure,
    );
  };

  // eslint-disable-next-line no-unused-vars
  handleMutationSuccess = (_response) => {
    const { intl } = this.props;
    const msgSuccess = intl.formatMessage({
      id: 'auth.auth_confirm_email.form.response_message.success',
      defaultMessage: 'Email confirmation was successful. Please confirm by logging in with your updated email and password.',
    });
    FlashesStore.flash(
      FlashesStore.SUCCESS,
      msgSuccess,
    );
    const { router } = this.props;
    router.push('/login');
  };

  // eslint-disable-next-line class-methods-use-this -- consistency in handling mutations.
  handleMutationFailure = (error) => {
    FlashesStore.flash(FlashesStore.ERROR, error.message);
  };

  render() {
    const { intl } = this.props;
    const title = intl.formatMessage({ id: 'auth.auth_confirm_email.form.title', defaultMessage: 'Confirm your email address' });
    const pageTitle = intl.formatMessage({ id: 'auth.auth_confirm_email.page.title', defaultMessage: 'Enosi - Confirm your email address' });
    const prevEmailLabel = intl.formatMessage({ id: 'auth.auth_confirm_email.form.previous_email.label', defaultMessage: 'Your account’s previous email' });
    const prevEmailPlaceholder = intl.formatMessage({ id: 'auth.auth_confirm_email.form.previous_email.placeholder', defaultMessage: 'Your account’s email address' });
    const newEmailLabel = intl.formatMessage({ id: 'auth.auth_confirm_email.form.new_email.label', defaultMessage: 'Your new email' });
    const newEmailPlaceholder = intl.formatMessage({ id: 'auth.auth_confirm_email.form.new_email.placeholder', defaultMessage: 'Your new email' });
    const submitLabel = intl.formatMessage({ id: 'auth.auth_confirm_email.form.submit.label', defaultMessage: 'Confirm' });

    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{pageTitle}</title>
        </Helmet>

        <AuthContainer type={AUTH_TYPE_EMAIL_CONFIRM} title={title}>
          <Form onSubmit={this.handleSubmit}>
            <FormGroup>
              <Label htmlFor="email">{prevEmailLabel}</Label>
              <Input
                type="email"
                id="email"
                aria-describedby="email"
                placeholder={prevEmailPlaceholder}
                onChange={this.handleEmailChange}
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="newEmail">{newEmailLabel}</Label>
              <Input
                type="email"
                id="newEmail"
                aria-describedby="newEmail"
                placeholder={newEmailPlaceholder}
                onChange={this.handleNewEmailChange}
              />
            </FormGroup>
            <Button type="submit" color="primary">
              {submitLabel}
            </Button>
          </Form>
        </AuthContainer>
      </>
    );
  }
}

AuthConfirmEmail.propTypes = {
  intl: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  match: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  router: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default injectIntl(AuthConfirmEmail);
