import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';

import { TRADE_RULE_STATE_REJECTED } from 'src/util/constants';

import TradeRuleTimelineStep from './TradeRuleTimelineStep';

/**
 * Description
 * @param {any} props
 * @returns {any} - TradeRuleTimelineStepRejected component
 */
function TradeRuleTimelineStepRejected(props) {
  const {
    ruleId, tradeType, timestamp, user, isCurrentStep, hasNextStep,
    propertyTimeZone,
  } = props;

  return (
    <TradeRuleTimelineStep
      propertyTimeZone={propertyTimeZone}
      ruleId={ruleId}
      tradeType={tradeType}
      state={TRADE_RULE_STATE_REJECTED}
      timestamp={timestamp}
      user={user}
      icon={<FontAwesomeIcon icon={faTimesCircle} />}
      isCurrentStep={isCurrentStep}
      hasNextStep={hasNextStep}
    />
  );
}

TradeRuleTimelineStepRejected.propTypes = {
  propertyTimeZone: PropTypes.string.isRequired,
  ruleId: PropTypes.string.isRequired,
  tradeType: PropTypes.string.isRequired,
  timestamp: PropTypes.number.isRequired,
  user: PropTypes.shape({
    uuid: PropTypes.string,
    email: PropTypes.string,
    givenName: PropTypes.string,
    familyName: PropTypes.string,
  }).isRequired,
  isCurrentStep: PropTypes.bool.isRequired,
  hasNextStep: PropTypes.bool.isRequired,
};

TradeRuleTimelineStepRejected.defaultProps = {
};

export default TradeRuleTimelineStepRejected;
