import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';
import { Badge } from 'reactstrap';

import { TRADE_DIRECTION_BUY, TRADE_DIRECTION_SELL } from 'src/util/constants';

const colorMapping = {
  TRADE_DIRECTION_BUY: 'secondary',
  TRADE_DIRECTION_SELL: 'primary',
};

/**
 * Trade direction badge
 * @param {TRADE_DIRECTION_BUY | TRADE_DIRECTION_SELL} direction
 * @returns {React.ReactComponentElement} - BadgeTradeDirection component
 */
function BadgeTradeDirection({ direction }) {
  if (!direction) { return (null); }
  const intl = useIntl();
  const badgeColor = colorMapping[direction] || 'mid';

  const labelSell = intl.formatMessage({ id: 'badge.badge_trade_direction.sell.label', defaultMessage: 'Sell' });
  const labelBuy = intl.formatMessage({ id: 'badge.badge_trade_direction.buy.label', defaultMessage: 'Buy' });
  let label;

  switch (direction) {
    case TRADE_DIRECTION_SELL:
      label = labelSell;
      break;
    case TRADE_DIRECTION_BUY:
      label = labelBuy;
      break;
    default:
      label = '';
  }

  return (
    <Badge color={badgeColor} className="badge-outline">{label?.toUpperCase()}</Badge>
  );
}

BadgeTradeDirection.propTypes = {
  direction: PropTypes.string.isRequired,
};

export default BadgeTradeDirection;
