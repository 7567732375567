import { Link } from 'found';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import BadgeTradeDirection from 'src/components/Badge/BadgeTradeDirection';
import BadgeTradeRuleState from 'src/components/Badge/BadgeTradeRuleState';
import BadgeTradeType from 'src/components/Badge/BadgeTradeType';
import Loading from 'src/components/Loading';
import UUID from 'src/components/UUID';
import Time from 'src/enosikit/components/Time';
import {
  getTradePointTradeRuleTableLabel,
} from 'src/helpers/tradeHelpers';
import {
  TRADE_DIRECTION_BUY, TRADE_DIRECTION_SELL, TRADE_DIRECTION_UNSPECIFIED,
} from 'src/util/constants';
import username from 'src/util/decorators/username';
import { getLocale } from 'src/util/i18n/handler';
import { tradeRuleClauseConditions } from 'src/util/tradeRule';

import TradeRuleListTableItemClause from './TradeRuleListTableItemClause';

class TradeRuleListTableItem extends React.Component {
  static tradeRuleClauseConditions(intl, rule) {
    const conditions = tradeRuleClauseConditions(rule, intl);
    if (conditions.length === 0) {
      return { element: null, display: false };
    }

    return {
      element: (
        <li>{intl.formatList(conditions, { type: 'unit' })}</li>
      ),
      display: true,
    };
  }

  static counterPartyTrader(tradePointId, rule) {
    const direction = this.direction(tradePointId, rule);

    let trader;
    if (direction === TRADE_DIRECTION_BUY) {
      trader = rule.seller;
    } else if (direction === TRADE_DIRECTION_SELL) {
      trader = rule.buyer;
    } else {
      return null;
    }

    if (trader.user) {
      return trader.user;
    }
    if (trader.community) {
      return trader.community;
    }
    if (trader.residual) {
      return trader.residual;
    }

    return null;
  }

  static counterPartyTradePoint(tradePointId, rule) {
    const direction = this.direction(tradePointId, rule);

    let tradePoint;
    if (direction === TRADE_DIRECTION_BUY) {
      tradePoint = rule.seller.tradePoint;
    } else if (direction === TRADE_DIRECTION_SELL) {
      tradePoint = rule.buyer.tradePoint;
    } else {
      return null;
    }

    return getTradePointTradeRuleTableLabel(tradePoint);
  }

  static direction(tradePointId, rule) {
    const { buyer, seller } = rule;

    let buyerTradePointId = null;
    let sellerTradePointId = null;

    if (buyer.tradePoint && buyer.tradePoint.uuid) {
      buyerTradePointId = buyer.tradePoint.uuid;
    }
    if (seller.tradePoint && seller.tradePoint.uuid) {
      sellerTradePointId = seller.tradePoint.uuid;
    }

    if (buyerTradePointId && buyerTradePointId === tradePointId) {
      return TRADE_DIRECTION_BUY;
    }
    if (sellerTradePointId && sellerTradePointId === tradePointId) {
      return TRADE_DIRECTION_SELL;
    }
    return TRADE_DIRECTION_UNSPECIFIED;
  }

  render() {
    if (this.error) {
      return <div><FormattedMessage id="error.title" defaultMessage="Error!" /></div>;
    }
    if (!this.props) {
      return <Loading />;
    }

    const {
      propertyId, rule, tradePointId, intl,
    } = this.props;

    const {
      uuid, tradeType, state, start, finish,
    } = rule;

    const clauses = (!rule.clauses || !rule.clauses.edges)
      ? [] : rule.clauses.edges.map((el) => (el.node)).sort((a, b) => (b.price - a.price));

    const clauseConditions = TradeRuleListTableItem.tradeRuleClauseConditions(intl, rule);

    // eslint-disable-next-line max-len -- conflict between max-len and function-paren-newline.
    const counterPartyTradePoint = TradeRuleListTableItem.counterPartyTradePoint(tradePointId, rule);
    const counterPartyTrader = TradeRuleListTableItem.counterPartyTrader(tradePointId, rule);
    const direction = TradeRuleListTableItem.direction(tradePointId, rule);
    const locale = getLocale();

    const validityStartDate = start ? <Time child={DateTime.fromSeconds(start).setLocale(locale).toFormat('HH:mm DD')} unixTimestamp={start} /> : null;
    const validityFinishDate = finish ? <Time child={DateTime.fromSeconds(finish).setLocale(locale).toFormat('HH:mm DD')} unixTimestamp={finish} /> : null;

    let validityPeriodText;

    if (validityStartDate !== null && validityFinishDate !== null) {
      validityPeriodText = intl.formatMessage({
        id: 'trade_rule.trade_rule_list.trade_rule_list_table_item.validity_with_start_and_finish.text',
        defaultMessage: '{validityStartDate} to {validityFinishDate}',
      }, { validityStartDate, validityFinishDate });
    } else if (validityStartDate !== null) {
      validityPeriodText = intl.formatMessage({
        id: 'trade_rule.trade_rule_list.trade_rule_list_table_item.validity_with_start_only.text',
        defaultMessage: '{validityStartDate} onwards',
      }, { validityStartDate });
    } else if (validityFinishDate !== null) {
      validityPeriodText = intl.formatMessage({
        id: 'trade_rule.trade_rule_list.trade_rule_list_table_item.validity_with_finish_only.text',
        defaultMessage: 'up to {validityFinishDate}',
      }, { validityFinishDate });
    }

    return (
      <tr key={`trade-rule-${uuid}`}>
        <th>
          <Link to={`/properties/${propertyId}/trade-rules/${uuid}`}>
            <UUID uuid={uuid} />
          </Link>
        </th>
        <td>
          <BadgeTradeType type={tradeType} />
        </td>
        <td>
          <BadgeTradeRuleState state={state} />
        </td>
        <td>
          <BadgeTradeDirection direction={direction} />
        </td>
        <td>
          {counterPartyTrader && (
            <>
              <strong>{username(counterPartyTrader)}</strong>
              <br />
              <UUID uuid={counterPartyTrader.uuid} />
            </>
          )}
        </td>
        <td>
          {counterPartyTradePoint}
        </td>
        <td>
          <ul className="ps-2">
            {clauses && clauses.map((clause) => (
              <TradeRuleListTableItemClause
                clause={clause}
                key={clause.uuid}
                showConditions={false}
              />
            ))}
            {clauseConditions.display && clauseConditions.element}
          </ul>
        </td>
        <td data-testid="validity">
          {validityPeriodText}
        </td>
      </tr>
    );
  }
}

TradeRuleListTableItem.propTypes = {
  propertyId: PropTypes.string.isRequired,
  rule: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  tradePointId: PropTypes.string.isRequired,
  intl: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default injectIntl(createFragmentContainer(
  TradeRuleListTableItem,
  {
    rule: graphql`
      fragment TradeRuleListTableItem_rule on TradeRule {
        uuid
        buyer {
          user { uuid email givenName familyName }
          community { uuid title }
          residualId
          tradePoint {
            uuid
            type
            meter {
              uuid
              identifier
              title
              property {
                uuid
                title
              }
            }
          }
        }
        seller {
          user { uuid email givenName familyName }
          community { uuid title }
          residualId
          tradePoint {
            uuid
            type
            meter {
              uuid
              identifier
              title
              property {
                uuid
                title
              }
            }
          }
        }
        clauses {
          edges {
            node {
              uuid
              price
              ignoreDaylightSavings
              ignorePublicHolidays
              timezone
              ...TradeRuleListTableItemClause_clause
            }
          }
        }
        tradeType
        state
        start
        finish
        proposedAt
        proposedBy {uuid email givenName familyName }
        acceptedAt
        acceptedBy {uuid email givenName familyName }
        rejectedAt
        rejectedBy {uuid email givenName familyName }
        cancelledAt
        cancelledBy {uuid email givenName familyName }
        closedAt
        closedBy {uuid email givenName familyName }
      }
    `,
  },
));
